import React, { useEffect, useRef, useState } from 'react';
import './Home.css';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import { Helmet } from 'react-helmet';

import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the AOS styles


import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

// import PureModal from 'react-pure-modal';
// import 'react-pure-modal/dist/react-pure-modal.min.css';

// ----------------Swiper slider import------------------
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade, Keyboard } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/css/effect-fade";

import Popup from 'reactjs-popup';
import PopupForm from './PopupForm'; // Adjust the path as per your project structure

function Bootcamp_pillar() {


  const scrollToSection = () => {
    const section = document.getElementById('programs_s');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };



  const [shownIframe, setShownIframe] = useState(null);

  // Function to handle button clicks
  const handleVideoClick = (event) => {
    const iframeId = event.target.getAttribute('data-iframe-id');
    setShownIframe(iframeId);

    // Reset and set src to play the video
    const iframes = document.querySelectorAll('.video-iframe');
    iframes.forEach(iframe => {
      if (iframe.getAttribute('data-iframe-id') === iframeId) {
        const src = iframe.src;
        iframe.src = ''; // Reset the src
        iframe.src = src; // Set it again to start playback
      }
    });
  };

  const [shownIframes, setShownIframes] = useState({});



  const [showIframeLeft, setShowIframeLeft] = useState(false);
  const [showIframeRight, setShowIframeRight] = useState(false);

  const handleIconClickLeft = (e) => {
    e.preventDefault();
    setShowIframeLeft(true);
  };

  const handleIconClickRight = (e) => {
    e.preventDefault();
    setShowIframeRight(true);
  };

  const handleIframeCloseLeft = () => {
    setShowIframeLeft(false);
  };

  const handleIframeCloseRight = () => {
    setShowIframeRight(false);
  };








  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  // const handleMouseEnter = (event) => {
  //     event.target.play();
  //   };

  //   const handleMouseLeave = (event) => {
  //     event.target.pause();
  //   };


  const [isMuted, setIsMuted] = useState(true);
  const [isActive, setActive] = useState("false");

  const ToggleClass = () => {
    setActive(!isActive);
  };
  //Video on Hover Start


  const [showIframe, setShowIframe] = useState(false);
  const [playingVideos, setPlayingVideos] = useState(new Set());

  // Replace with actual logic to determine if the device is mobile or touch
  // Use window.matchMedia to determine if the viewport is mobile-sized
  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  useEffect(() => {


    AOS.init({
      duration: 1000, // Duration of the animation (in milliseconds)
    });

    if (isMobile) {
      const videos = document.querySelectorAll('.video');
      const observerOptions = {
        root: null, // Use the viewport as the container
        rootMargin: '0px',
        threshold: 0.5, // Adjust this as needed
      };

      const handleIntersection = (entries) => {
        entries.forEach(entry => {
          const video = entry.target;
          if (entry.isIntersecting) {
            if (video.paused) {
              // Attempt to play the video and handle cases where play() might not return a Promise
              const playPromise = video.play();
              if (playPromise !== undefined) {
                playPromise.catch(error => console.error('Play interrupted:', error));
              }
              setPlayingVideos(prev => new Set(prev.add(video)));
            }
          } else {
            if (!video.paused) {
              // Attempt to pause the video and handle cases where pause() might not return a Promise
              const pausePromise = video.pause();
              if (pausePromise !== undefined) {
                pausePromise.catch(error => console.error('Pause interrupted:', error));
              }
              setPlayingVideos(prev => {
                const updated = new Set(prev);
                updated.delete(video);
                return updated;
              });
            }
          }
        });
      };

      const observer = new IntersectionObserver(handleIntersection, observerOptions);
      videos.forEach(video => {
        observer.observe(video);
      });

      return () => {
        videos.forEach(video => {
          observer.unobserve(video);
        });
      };
    }
  }, [isMobile, playingVideos]);


  const handleMouseEnter = (e) => {
    if (!isMobile) {
      const video = e.target;
      video.muted = true; // Keep the video muted to avoid autoplay restrictions
      if (video.paused) {
        // Attempt to play the video and handle cases where play() might not return a Promise
        const playPromise = video.play();
        if (playPromise !== undefined) {
          playPromise.catch(error => console.error('Play interrupted:', error));
        }
      }
    }
  };

  const handleMouseLeave = (e) => {
    if (!isMobile) {
      const video = e.target;
      if (!video.paused) {
        // Attempt to pause the video and handle cases where pause() might not return a Promise
        const pausePromise = video.pause();
        if (pausePromise !== undefined) {
          pausePromise.catch(error => console.error('Pause interrupted:', error));
        }
      }
    }
  };


  //Video on Hover End
  return (
    <>
      <Helmet>
        <title>Bootcamps - MU20 School of Opportunity</title>
        <meta name="title" content="" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
      <Header />
      <section className="oxfordBanner pillars_banner">
      
        <div className='banner_video'>
        
          <video className="sliderBgImage" autoPlay loop playsInline width="100%" muted={isMuted ? true : false}>
            {/* <source src="assets/videos/OxfordVideo-Com.mp4" type="video/mp4" /> */}
            <source src="https://musite.b-cdn.net/videos/bootcamps_main_page_02_1920x900_(1).mp4" type="video/mp4" />
          </video>
        </div>


        <div className="banner-content">
          <div className='page_Heading'  >
            <h1  data-aos="fade-up " >Bootcamps</h1>
            <p className='heading_cont'  data-aos="fade-up">Dive into our intensive bootcamps for a fast-tracked learning adventure. Master new skills through hands-on projects and expert guidance in a focused, dynamic setting.
            </p>
          </div>
          <div className={isActive ? "mute" : "unmute"}>
            <a onClick={() => { setIsMuted(!isMuted); ToggleClass(); }} className="muteBtn widthAuto" id="btnUnMute"><img className="unmuteIcon" src="/assets/images/mute.svg" alt="unMuteIcon" />
              <img className="muteIcon" src="/assets/images/unmute.svg" alt="MuteIcon" /></a>
          </div>
        </div>

      </section>

      <section className="boderGradient bgBlack p-80-20 pillarBoxSec">
        <div className="container pillarBox-outer">
          <div className="pillarBox-innerLeft">

            <div className="pillarBox " data-aos="fade-up">
              <div className='w-50'>
                <img className="leftImg" src="assets/images/pillarimage/enrich.jpg" alt="pillarImg" />
              </div>

              <div className="pillarBoxContent w-50">
                <h4 className="fs-34 fw-600 pb-20 textOrg ">Enrich
                </h4>
                <p className="fs-16 fw-400 textWhite">Our bootcamps offer immersive learning through interactive activities. Students and educators dive deep into diverse subjects, gaining hands-on experience. This curriculum enriches perspectives, fostering critical thinking and a passion for knowledge. Participants leave with a broadened understanding, equipped to explore new ideas and pursue lifelong learning.
                </p>
              </div>
            </div>
            <div className="pillarBox Engage m-0" data-aos="fade-up">
              <div className="pillarBoxContent w-50">



                <h4 className="fs-34 fw-600 pb-20 textOrg ">Engage
                </h4>
                <p className="fs-16 fw-400 textWhite">Active engagement drives our bootcamps. Through hands-on projects, team-building activities, and dynamic discussions, participants stay fully involved and motivated. This collaborative environment encourages the exchange of unique insights, fostering a sense of community. We inspire everyone to contribute ideas, enriching the learning journey for all participants.</p>


              </div>

              <div className='w-50'>
                <img className="rightImg" src="assets/images/pillarimage/engage.jpg" alt="pillarImg" />
              </div>


            </div>
          </div>
          <div className="pillarBox-innerRight" data-aos="fade-up">
            <div className="pillarBox empower m-0">
              <img className="topImg" src="assets/images/pillarimage/empower.jpg" alt="pillarImg" />
              <div className="pillarBoxContent">

                <h4 className="fs-34 fw-600 pb-20 textOrg ">Empower
                </h4>
                <p className="fs-16 fw-400 textWhite">Empowerment is the core of our bootcamps. We provide tools, resources, and support to guide students and educators on their learning paths. Our programs focus on building confidence, leadership skills, and resilience. Participants gain the ability to tackle challenges independently, fostering personal growth and preparing them to make meaningful contributions to their communities and beyond.</p>


              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="programsSec bglightBlack  p-80-20" id='programs_s'>
        <div className="container">
          <h2 className="fs-80 fw-600 textWhite" data-aos="fade-up">Programs</h2>
          <div className="program-outer" data-aos="fade-up">
            <div className="program-innerLeft">
              <video className="sliderBgImage" width="600" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                <source src="https://musite.b-cdn.net/labmainpage.mp4" type="video/mp4" />
              </video>

            </div>
            <div className="program-innerRight">
              <h3 className="fs-34 fw-600 pb-20 textOrg " > Leadership and Adventure Bootcamp (LAB)</h3>
              <p className="fs-16 fw-400 textWhite pb-20">Welcome to the Leadership and Adventure Bootcamp (LAB), where intensive leadership training meets thrilling adventure. LAB is designed to enhance your leadership skills, build resilience, and foster teamwork through challenging tasks and outdoor activities. Join us for a transformative experience that prepares you to face real-world challenges with confidence and determination. </p>
              <h4 className="fs-18 fw-400 textWhite"><span className="textOrg">Venue :</span>House of Lalpur</h4>
              <ul className="progarmDetailList">
                <li>
                  <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                    src="assets/images/pillarPage/Location.svg" alt="Location" />
                    Location:<span className="fs-18 fw-400">Lalpur, Punjab</span> </h6>
                </li>
                <li>
                  <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                    src="assets/images/pillarPage/Certification.svg" alt="Certification" />
                    Certification :<span className="fs-18 fw-400">Yes</span></h6>
                </li>
                <li>
                  <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                    src="assets/images/pillarPage/Duration.svg" alt="Duration" />
                    Duration :<span className="fs-18 fw-400">5 days</span>
                  </h6>
                </li>
                <li>
                  <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                    src="assets/images/pillarPage/Reidential.svg" alt="Reidential" />
                    Cohort size :<span className="fs-18 fw-400">Upto 60 students from grades 9th to 12th
                    </span></h6>
                </li>
              </ul>
              <a className="registerBtn" href='/LeadershipandAdventureBootcamp'>
                Explore <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
              </a>
              <button className="registerBtn joinbtn" onClick={togglePopup} >
                Enquire Now
              </button>

              {showPopup && (
                <Popup open={showPopup} closeOnDocumentClick onClose={togglePopup} modal nested>
                  <PopupForm togglePopup={togglePopup} /> {/* Render the PopupForm component */}
                </Popup>
              )}

              {/* <a href="https://bit.ly/OxfordMUNRegistration" className="registerBtn">Join now<img className="widthAuto"
                                src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
              {/* <a href="/LeadershipandAdventureBootcamp" className="readMoreBtn">Read More</a> */}
            </div>
          </div>
        </div>






        <div className="container">

          <div className="program-outer" data-aos="fade-up">
            <div className="program-innerLeft">
              <video className="sliderBgImage" width="600" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                <source src="https://musite.b-cdn.net/bootcampsmainpage021920X900(1).mp4" type="video/mp4" />
              </video>
            </div>
            <div className="program-innerRight">
              <h3 className="fs-34 fw-600 pb-20 textOrg ">Young Leader's Bootcamp (YLB)</h3>
              <p className="fs-16 fw-400 textWhite pb-20">
                The Young Leader's Bootcamp at MU20 School of Opportunity is designed to empower and inspire the next generation of change-makers. Through a blend of rigorous training, comprehensive learning projects, and mentorship from industry leaders, participants are equipped with the skills and confidence needed to lead in an ever-changing world. This program fosters personal growth, ethical leadership, and a strong commitment to making a positive impact in their communities and beyond.
              </p>
              <h4 className="fs-18 fw-400 textWhite"><span className="textOrg">Venue :</span>House of Lalpur</h4>
              <ul className="progarmDetailList">
                <li>
                  <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                    src="assets/images/pillarPage/Location.svg" alt="Location" />
                    Location:<span className="fs-18 fw-400">Lalpur, Punjab</span></h6>
                </li>
                <li>
                  <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                    src="assets/images/pillarPage/Certification.svg" alt="Certification" />
                    Certification:<span className="fs-18 fw-400">Yes</span></h6>
                </li>
                <li>
                  <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                    src="assets/images/pillarPage/Duration.svg" alt="Duration" />
                    Duration:<span className="fs-18 fw-400">5 days </span></h6>
                </li>
                <li>
                  <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                    src="assets/images/pillarPage/Reidential.svg" alt="Reidential" />
                    Cohort size:<span className="fs-18 fw-400">Upto 60 students from grades 6th to 8th</span></h6>
                </li>
              </ul>
              <a className="registerBtn" href='/Young_Leaders_Bootcamp'>
                Explore <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
              </a>
              <button className="registerBtn joinbtn" onClick={togglePopup} >
                Enquire Now
              </button>

              {showPopup && (
                <Popup open={showPopup} closeOnDocumentClick onClose={togglePopup} modal nested>
                  <PopupForm togglePopup={togglePopup} /> {/* Render the PopupForm component */}
                </Popup>
              )}

              {/* <a href="https://bit.ly/OxfordMUNRegistration" className="registerBtn">Join now<img className="widthAuto"
                                src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
              {/* <a href="/Young_Leaders_Bootcamp" className="readMoreBtn">Read More</a> */}
            </div>
          </div>
        </div>



        <div className="container">

          <div className="program-outer" data-aos="fade-up">
            <div className="program-innerLeft">
              <video className="sliderBgImage" width="600" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline poster="assets/images/edlr_thumbnail_slider.png">
                <source src="https://musite.b-cdn.net/videos/teachers_Main_page.mp4" type="video/mp4" />
              </video>
            </div>
            <div className="program-innerRight">
              <h3 className="fs-34 fw-600 pb-20 textOrg ">Educators’ Leadership Retreat (EdLR)  </h3>
              <p className="fs-16 fw-400 textWhite pb-20">The EdLR (Educators’ Leadership Retreat) Program at MU20 School of Opportunity is dedicated to transforming educational experiences through innovative leadership. This program combines cutting-edge pedagogical strategies with leadership development, empowering educators and students alike to thrive in diverse learning environments. Participants engage in immersive workshops, collaborative projects, and mentorship, equipping them with the tools to drive meaningful change in education and beyond.</p>
              <h4 className="fs-18 fw-400 textWhite"><span className="textOrg">Venue :</span>House of Lalpur
              </h4>
              <ul className="progarmDetailList">
                <li>
                  <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                    src="assets/images/pillarPage/Location.svg" alt="Location" />
                    Location:<span className="fs-18 fw-400">Lalpur, Punjab
                    </span></h6>
                </li>
                <li>
                  <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                    src="assets/images/pillarPage/Certification.svg" alt="Certification" />
                    Certification:<span className="fs-18 fw-400">Yes</span></h6>
                </li>
                <li>
                  <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                    src="assets/images/pillarPage/Duration.svg" alt="Duration" />
                    Duration:<span className="fs-18 fw-400">5 days </span></h6>
                </li>
                <li>
                  <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                    src="assets/images/pillarPage/Reidential.svg" alt="Reidential" />
                    Cohort size:<span className="fs-18 fw-400">upto 60 Teachers</span></h6>
                </li>
              </ul>
              {/* <a className="registerBtn" href='#'>
                           Explore <img className="widthAuto"
                                src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
                            </a>                      
                                  <button className="registerBtn joinbtn"  onClick={togglePopup} >
                            Join now 
                            </button> */}

              {showPopup && (
                <Popup open={showPopup} closeOnDocumentClick onClose={togglePopup} modal nested>
                  <PopupForm togglePopup={togglePopup} /> {/* Render the PopupForm component */}
                </Popup>
              )}

              {/* <a href="https://bit.ly/OxfordMUNRegistration" className="registerBtn">Join now<img className="widthAuto"
                                src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
              {/* <a href="#" className="readMoreBtn">Read More</a> */}
            </div>
          </div>
        </div>




      </section>

      <section className="bgBlack testimonialSec testimonialHomeSec">
        <div className="container-full" data-aos="fade-up">
          <div className="topHeading">

            <h2 className="fs-80 fw-600 textWhite" data-aos="fade-up">Teacher Testimonials</h2>
          </div>

          <Swiper
            breakpoints={{
              1440: {
                spaceBetween: 40,
                slidesPerView: 4,
                centeredSlides: false,
              },
              768: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
              200: {
                spaceBetween: 20,
                slidesPerView: 1,
                autoplay: false,

              },
            }}
            modules={[Autoplay, A11y]}
            spaceBetween={50}
            slidesPerView={5}
            loop={true}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            }}
            centeredSlides={true}
          >
            <SwiperSlide>

              <div className="testimonialImg">
                <video className="testimonialVideo video" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline controls>
                  <source src="https://musite.b-cdn.net/videos/1.mp4" type="video/mp4" />

                </video>

                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>

            </SwiperSlide>


            <SwiperSlide>

              <div className="testimonialImg">
                <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://musite.b-cdn.net/videos/2.mp4" type="video/mp4" />

                </video>

                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>

            </SwiperSlide>



            <SwiperSlide>
              <div className="testimonialImg">
                <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://musite.b-cdn.net/videos/3.mp4" type="video/mp4" />

                </video>




                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>

              <div className="testimonialImg">
                <video className="testimonialVideo video" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline controls>
                  <source src="https://musite.b-cdn.net/videos/4.mp4" type="video/mp4" />

                </video>

                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>

            </SwiperSlide>


            <SwiperSlide>

              <div className="testimonialImg">
                <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://musite.b-cdn.net/videos/5.mp4" type="video/mp4" />

                </video>

                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>

            </SwiperSlide>




          </Swiper>
        </div>
      </section>






      <section className="bgBlack testimonialSec testimonialHomeSec">
        <div className="container-full" data-aos="fade-up">
          <div className="topHeading"data-aos="fade-up">
            <h2 className="fs-80 fw-600 textWhite">Student Testimonials</h2>
          </div>

          <Swiper
            breakpoints={{
              1440: {
                spaceBetween: 40,
                slidesPerView: 4,
                centeredSlides: false,
              },
              768: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
              200: {
                spaceBetween: 20,
                slidesPerView: 1,
                autoplay: false,

              },
            }}
            modules={[Autoplay, A11y]}
            spaceBetween={50}
            slidesPerView={5}
            loop={true}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            }}
            centeredSlides={true}
          >
            <SwiperSlide>

              <div className="testimonialImg">
                <video className="testimonialVideo video" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline controls>
                  <source src="https://musite.b-cdn.net/LABwebtest1.mp4" type="video/mp4" />

                </video>

                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>

            </SwiperSlide>


            <SwiperSlide>

              <div className="testimonialImg">
                <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://musite.b-cdn.net/LABwebtest2.mp4" type="video/mp4" />

                </video>

                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>

            </SwiperSlide>



            <SwiperSlide>
              <div className="testimonialImg">
                <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://musite.b-cdn.net/LABwebtest3.mp4" type="video/mp4" />

                </video>



                {/* <img src="assets/images/testimonial3.png" alt='home-img' /> */}
                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>

              <div className="testimonialImg">
                <video className="testimonialVideo video" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline controls>
                  <source src="https://musite.b-cdn.net/YLPwebtest1.mp4" type="video/mp4" />

                </video>

                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>

            </SwiperSlide>

            <source src="https://musite.b-cdn.net/YLPwebtest2.mp4" type="video/mp4" />
            {/* <SwiperSlide>

                <div className="testimonialImg">
                <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://musite.b-cdn.net/videos/summit_web_test.mp4" type="video/mp4" />
                
                </video>

                  <div className="textWrapper">
                    <img src="/assets/images/videoSec.svg" alt='home-img' />
                    <h6 className="titleWatch">Watch Story</h6>
                  </div>
                </div>

        </SwiperSlide> */}



            <SwiperSlide>
              <div className="testimonialImg">
                <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://musite.b-cdn.net/YLPwebtest2.mp4" type="video/mp4" />

                </video>

                {/* <img src="assets/images/testimonial3.png" alt='home-img' /> */}
                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="testimonialImg">
                <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://musite.b-cdn.net/YLPWebtest3.mp4" type="video/mp4" />

                </video>

                {/* <img src="assets/images/testimonial3.png" alt='home-img' /> */}
                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>


          </Swiper>
        </div>
      </section>





















      <section className="p-80-20 bglightBlack networkSchoolSec">
        <div className="container" data-aos="fade-up">
          <div className="topHeading">
            <h2 className="fs-80 fw-600 textWhite" data-aos="fade-up">Participating Schools</h2>

          </div>
          <div className="networkSchoolSlider-outer">
            <Swiper
              modules={[Navigation, Autoplay, A11y, Keyboard]}  // Include necessary modules
              spaceBetween={30}
              slidesPerView={7}
              cssMode={false}
              navigation={false}
              loop={true}
              autoplay={{
                delay: 1500,  // Autoplay delay (1.5 seconds)
                disableOnInteraction: true,  // Stop autoplay when user interacts with the slides
                pauseOnMouseEnter: true  // Pause autoplay on mouse enter
              }}
              keyboard={{
                enabled: true,
                onlyInViewport: true  // Enable keyboard navigation only when Swiper is in view
              }}

              breakpoints={{
                // when window width is >= 200px
                200: {
                  slidesPerView: 2,
                  spaceBetween: 0,
                  autoplay: false,

                },
                380: {
                  slidesPerView: 3,
                  spaceBetween: 5,
                  slidesOffsetBefore: -35,
                },


                // when window width is >= 767px (tablet portrait)
                768: {
                  slidesPerView: 5,
                  spaceBetween: 0,
                },
                // when window width is >= 1024px (tablet landscape)
                1024: {
                  slidesPerView: 7,
                  spaceBetween: 0,
                },
                // when window width is >= 1440px (desktop)
                1440: {
                  slidesPerView: 7,
                  spaceBetween: 0,
                },
              }}
            >


              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/always.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/ashoka.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/bharati.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/bihani.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/billabong.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/birla.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/bk_birla.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/crossroads.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/dalimess.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/delhi.png" alt="networkSch" />
                </div>
              </SwiperSlide>



              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/delhiworld.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/dolphin.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/doon.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/doon_girl.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/duty.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/emrald.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/goenka.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/golden.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/him.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/ies.png" alt="networkSch" />
                </div>
              </SwiperSlide>


              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/inventure.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/jayshree.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/jbcn.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/jindal.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/jodhamal.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/jyoti.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/kasiga.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/kunska.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/macro.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/mann.png" alt="networkSch" />
                </div>
              </SwiperSlide>



              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/mcd.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/no.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/pacific.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/pathways.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/pinegrove.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/queen.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/rajkumar.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/rajkumar_college.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/rajmas.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/rajmata.png" alt="networkSch" />
                </div>
              </SwiperSlide>


              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/rockwoods.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/sagar_school.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/sanskar.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/satpuda.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/satyasai.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/school.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/scindia.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/shishukunj.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/singapoor.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/strength.png" alt="networkSch" />
                </div>
              </SwiperSlide>



              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/timor.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/truth.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/uwc.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/valistus.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/vantage.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/vidhya.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/virtus.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/vivek.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/wisdom.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/witty.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/world.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/zabar.png" alt="networkSch" />
                </div>
              </SwiperSlide>




            </Swiper>
          </div>
        </div>
      </section>

      <section className=" bglightBlack  p-80-20 opportunitiesSec">
        <div className="container opportunities-outer">
          <div className="opportunities-inner">
            <div className="opportunities-wrapper purpleGradient">
              <img src="assets/images/bootcamp_grid/1.png" alt="opportunitiesImg" />
              <div className="contentWrapper"data-aos="fade-up">
                <h6 className="fs-32 fw-600 textWhite pb-10" >1,500+ Students on Campus</h6>
                <p className="fs-16 fw-400 textWhite pb-20" >Over 1,500 students have experienced the transformative environment of our residential bootcamps.</p>
                {/* <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
              </div>
            </div>
            <div className="opportunities-wrapper yellowGradient">
              <img src="assets/images/cohort.jpg" alt="opportunitiesImg" />
              <div className="contentWrapper" data-aos="fade-up">
                <h6 className="fs-32 fw-600 textWhite  pb-10">70+ Cohorts Formed</h6>
                <p className="fs-16 fw-400 textWhite pb-20"> Successfully organized more than 70 cohorts, fostering close-knit learning communities.

                </p>
                {/* <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
              </div>
            </div>
          </div>
          <div className="opportunities-inner">
            <div className="opportunities-wrapper orgGradient">
              <img src="assets/images/50+school.jpg" alt="opportunitiesImg" />
              <div className="contentWrapper" data-aos="fade-up">
                <h6 className="fs-32 fw-600 textWhite pb-10">50+ Schools Participated
                </h6>
                <p className="fs-16 fw-400 textWhite pb-20">Participation from over 50 schools, bringing diverse perspectives and enriching the learning experience.
                </p>
                {/* <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
              </div>
            </div>
            <div className="opportunities-wrapper blueGradient">
              <img src="assets/images/100+project.jpg" alt="opportunitiesImg" />
              <div className="contentWrapper" data-aos="fade-up">
                <h6 className="fs-32 fw-600 textWhite  pb-10">100+ Projects Submitted
                </h6>
                <p className="fs-16 fw-400 textWhite pb-20">Students have submitted over 100 projects, demonstrating their creativity and innovation.
                </p>
                {/* <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
              </div>
            </div>
          </div>
          <div className="opportunities-inner">
            <div className="opportunities-wrapper redGradient">
              <img src="assets/images/bootcamp_grid/3.png" alt="opportunitiesImg" />
              <div className="contentWrapper" data-aos="fade-up">
                <h6 className="fs-32 fw-600 textWhite pb-10">15+ Events and Activities
                </h6>
                <p className="fs-16 fw-400 textWhite pb-20">Hosted more than 15 events and activities, offering a holistic and engaging bootcamp experience.

                </p>
                {/* <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
              </div>
            </div>
            <div className="opportunities-wrapper greenGradient">
              <img src="assets/images/bootcamp_grid/6.png" alt="opportunitiesImg" />
              <div className="contentWrapper" data-aos="fade-up">
                <h6 className="fs-32 fw-600 textWhite  pb-10">250+ Hours of Workshops
                </h6>
                <p className="fs-16 fw-400 textWhite pb-20">Conducted more than 250 hours of intensive workshops covering various topics
                </p>
                {/* <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="callToAction bgBlack callToActionPillar ">
        <div className="container  " data-aos="fade-up">
          <div className="  callToAction-inner">
            <div className="row">
              <div className="column callToActionLeft">
                <h5>Join us for a <br />Great experience</h5>
                {/* <p>Register your school today to the Oxford MUN India 2024.</p> */}
              </div>
              <div className="column callToActionRight">
                <p>Sign up for our immersive Bootcamps and dive into hands-on projects and expert-led sessions. Visit the link below to secure your spot and embark on a transformative learning journey.</p>
                {/* <a href="https://bit.ly/OxfordMUNRegistration" className="registerBtn">Join now<img className="widthAuto"
                                    src="assets/images/pillarPage/ArrowOrg.svg" alt="iconBtn" /></a> */}
                <button className="registerBtn" onClick={scrollToSection} data-aos="fade-up">
                  Explore Now
                  {/* <img className="widthAuto" src="assets/images/pillarPage/ArrowOrg.svg" alt="iconBtn" /> */}
                </button>

                {showPopup && (
                  <Popup open={showPopup} closeOnDocumentClick onClose={togglePopup} modal nested>
                    <PopupForm togglePopup={togglePopup} /> {/* Render the PopupForm component */}
                  </Popup>
                )}

                {/* <a href="#" className="readMoreBtn">Read More</a> */}
                {/* <a href="#" className="registerBtn"> Register Now </a>  */}
              </div>
            </div>
          </div>

        </div>
      </section>












      {/* <section className="programsSec bgBlack  p-80-20   pt-0">
                <div className="container">
                   
                    <div className="program-outer">
                        <div className="program-innerLeft">
                        <video className="sliderBgImage" width="600" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                            <source src="assets/videos/video1.mov" type="video/mp4" />
                            <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                            </video>
                       
                    </div>
                        <div className="program-innerRight">
                                <video className="sliderBgImage" width="600" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                                <source src="assets/videos/video1.mov" type="video/mp4" />
                                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                                </video>
                        </div>
                       
                    </div>
                    
                </div>

            </section> */}






      {/* <section className="programsSec bgBlack  p-80-20   pt-0">


                <div className="container">
                <div className="program-outer">
                    
                <div className="program-innerLeft" style={{ position: 'relative' }}>
                  <video
                   onClick={handleIconClickLeft}
                    className="sliderBgImage past_movie_vid"
                    width="600"
                    muted
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    playsInline
                  >
                    <source src="https://musite.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                    <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                  </video>
                  <div className="video-caption" style={{ backgroundColor: "white" }}>
                    <p>Leadership and Adventure Bootcamp | The Doon School, Dehradun Cohort | Aftermovie</p>
                    <a href="#" className="past_movie" onClick={handleIconClickLeft}>
                      <img className="arrow" src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
                    </a>
                  </div>
                  {showIframeLeft && (
                    <div className="iframe-container  iframe-container_left responsive-iframe-container">
                      <iframe
                        src="https://www.youtube.com/embed/3ylVNxFkU6A?si=EZXARQzAdqqrnQWx&autoplay=1&autoplay=1"
                        width="650"
                        height="400"
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        title="video"
                      ></iframe>
                      <button onClick={handleIframeCloseLeft} className="close-button">Close</button>
                    </div>
                  )}
                </div>



                  <div className="program-innerRight" style={{ position: 'relative' }}>
                      <video
                      onClick={handleIconClickRight}
                        className="sliderBgImage past_movie_vid"
                        width="600"
                        muted
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        playsInline
                      >
                        <source src="https://musite.b-cdn.net/bootcampsmainpage021920X900(1).mp4" type="video/mp4" />
                      
                      </video>

                      <div className="video-caption" style={{ backgroundColor: "white" }}>
                        <p>Leadership and Adventure Bootcamp | The Doon School, Dehradun Cohort | Aftermovie</p>
                        <a href="#" className="past_movie" onClick={handleIconClickRight}>
                          <img className="arrow" src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
                        </a>
                      </div>
                      {showIframeRight && (
                        <div className="iframe-container_right iframe-container responsive-iframe-container">
                          <iframe
                            src="https://www.youtube.com/embed/3ylVNxFkU6A?si=EZXARQzAdqqrnQWx&autoplay=1&autoplay=1"
                            width="650"
                            height="400"
                            frameBorder="0"
                            allow="autoplay; encrypted-media"
                            allowFullScreen
                            title="video"
                          ></iframe>
                          <button onClick={handleIframeCloseRight} className="close-button">Close</button>
                        </div>
                        
                      )}

                      
                    </div>
                    </div>
                </div>
                

                
                
             </section> */}


      {/* <section className="programsSec bgBlack  p-80-20   pt-0">


            <div className="container past_program_container">
            <div className="program-outer">

         <div className="program-innerRight" style={{ position: 'relative' }}>
            <video
            onClick={handleIconClickRight}
              className="sliderBgImage past_movie_vid"
              width="600"
              muted
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              playsInline
            >
              <source src="https://musite.b-cdn.net/videos/teachers_Main_page.mp4" type="video/mp4" />
              <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
            </video>

            <div className="video-caption" style={{ backgroundColor: "white" }}>
              <p>Leadership and Adventure Bootcamp | The Doon School, Dehradun Cohort | Aftermovie</p>
              <a href="#" className="past_movie" onClick={handleIconClickRight}>
                <img className="arrow" src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
              </a>
            </div>
            {showIframeRight && (
              <div className="iframe-container_right iframe-container responsive-iframe-container">
                <iframe
                  src="https://www.youtube.com/embed/3ylVNxFkU6A?si=EZXARQzAdqqrnQWx&autoplay=1&autoplay=1"
                  width="650"
                  height="400"
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  title="video"
                ></iframe>
                <button onClick={handleIframeCloseRight} className="close-button">Close</button>
        </div>
        
      )}

      
    </div>
    </div>
</div>




</section> */}




      <section className="p-80-20 bgBlack   pastProgVideoSec">
        <div className="container-full" data-aos="fade-up">
          <div className="topHeading">
            <h2 className="fs-80 fw-600 textWhite" data-aos="fade-up">Bootcamps Aftermovies</h2>
          </div>
        </div>

        <Swiper
          // breakpoints={{
          //   1440: {
          //     spaceBetween: 30,
          //     slidesPerView: 4,
          //   },
          //   768: {
          //     spaceBetween: 30,
          //     slidesPerView: 2,
          //   },
          //   200: {
          //     spaceBetween: 15,
          //     slidesPerView: 1,
          //   }
          // }}




          modules={[Pagination, A11y, Autoplay]}
          speed={1000}
          loop={true}
          freeMode={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
          }}
          centeredSlides={true}
          slideToClickedSlide={true}
          breakpoints={{
            // when window width is >= 200px
            200: {
              slidesPerView: 'auto',
              spaceBetween: 0,
              autoplay: false,

            },
            380: {
              slidesPerView: '1',
              spaceBetween: 5,
              slidesOffsetBefore: -35,
            },


            // when window width is >= 767px (tablet portrait)
            768: {
              slidesPerView: "1",
              spaceBetween: 0,
            },
            // when window width is >= 1024px (tablet landscape)
            1024: {
              slidesPerView: "3",
              spaceBetween: 0,
            },
            // when window width is >= 1440px (desktop)
            1440: {
              slidesPerView: "3",
              spaceBetween: 0,
            },
          }}
        >



          <SwiperSlide>
            <div className="pastProgVideos">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Doon LAB Aftermovie</h4>
              <video
                data-iframe-id="1"
                className="progVideos video"
                muted
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleVideoClick}
                playsInline
              >
                <source src="https://musite.b-cdn.net/videos/doon.mp4" type="video/mp4" />
              </video>

              {shownIframe === '1' && (
                <iframe
                  className="video-iframe"
                  data-iframe-id="1"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/3ylVNxFkU6A?si=Yx4Nfqw2QSyggVgT" // Corrected URL
                  title="YouTube video player 3"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}


              <div className="progVedioContent">
                <p className="fs-25 fw-400">
                  Leadership and Adventure Bootcamp | The Doon School, Dehradun Cohort | Aftermovie
                </p>
                <button className="readMoreIconBtn" data-iframe-id="3" onClick={handleVideoClick}>
                  <img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
                </button>

              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">EHIS LAB Aftermovie</h4>
              <video
                data-iframe-id="2"
                className="progVideos video"
                muted
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleVideoClick}
                playsInline
              >
                <source src="https://musite.b-cdn.net/videos/emerald.mp4" type="video/mp4" />
              </video>

              {shownIframe === '2' && (
                <iframe
                  className="video-iframe"
                  data-iframe-id="2"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/MmFXW0yUxNw?si=QfL37LlbVKpit25L" // Corrected URL
                  title="YouTube video player 2"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}


              <div className="progVedioContent">
                <p className="fs-25 fw-400">
                  MU20 School of Opportunity | Leadership & Adventure Bootcamp | Emerald Heights International School
                </p>
                <button className="readMoreIconBtn" data-iframe-id="4" onClick={handleVideoClick}>
                  <img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
                </button>

              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Mayo College LAB Aftermovie</h4>
              <video
                data-iframe-id="3"
                className="progVideos video"
                muted
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleVideoClick}
                playsInline
              >
                <source src="https://musite.b-cdn.net/mayo_girls.mp4" type="video/mp4" />
              </video>

              {shownIframe === '3' && (
                <iframe
                  className="video-iframe"
                  data-iframe-id="3"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/NWeAlnmTfq8?si=pg3FeW2fji0FZmYb" // Added autoplay=1
                  title="YouTube video player 3"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}

              <div className="progVedioContent">
                <p className="fs-25 fw-400">
                  MU20 School of Opportunity | Leadership & Adventure Bootcamp | Mayo College Girls' School
                </p>
                <button className="readMoreIconBtn" data-iframe-id="5" onClick={handleVideoClick}>
                  <img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
                </button>
                {/* <a href="#" className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
            <img
              className="widthAuto readMoreIconBtn"
              src="assets/images/homeImage/arrowWhite.svg"
              alt="iconBtn"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleReadMoreClick}
            />
          </a> */}
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">MGD LAB Aftermovie</h4>
              <video
                data-iframe-id="4"
                className="progVideos video"
                muted
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleVideoClick}
                playsInline
              >
                <source src="https://musite.b-cdn.net/mgd_chants.mp4" type="video/mp4" />
              </video>

              {shownIframe === '4' && (
                <iframe
                  className="video-iframe"
                  data-iframe-id="4"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/cjuBSu2Qtk0?si=_I9R33hpbJy7qsSg" // Added autoplay=1
                  title="YouTube video player 6"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}

              <div className="progVedioContent">
                <p className="fs-25 fw-400">
                  MU20 School of Opportunity | Leadership & Adventure Bootcamp | Maharani Gayatri Devi Girls' School
                </p>
                <button className="readMoreIconBtn" data-iframe-id="6" onClick={handleVideoClick}>
                  <img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
                </button>

              </div>
            </div>
          </SwiperSlide>




          {/* <SwiperSlide>
      <div className="pastProgVideos">
        <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Doon LAB Aftermovie</h4>
        <video
         data-iframe-id="5"
          className="progVideos video"
          muted
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick}
          playsInline
        >
          <source src="https://musite.b-cdn.net/videos/doon.mp4" type="video/mp4" />
        </video>

        {shownIframe === '5' && (
  <iframe
    className="video-iframe"
    data-iframe-id="5"
    width="560"
    height="315"
    src="https://www.youtube.com/embed/3ylVNxFkU6A?si=Yx4Nfqw2QSyggVgT&autoplay=1" 
    title="YouTube video player 5"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
)}


        <div className="progVedioContent">
          <p className="fs-25 fw-400">
          Leadership and Adventure Bootcamp | The Doon School, Dehradun Cohort | Aftermovie
          </p>
          <button className="readMoreIconBtn" data-iframe-id="3" onClick={handleVideoClick}>
          <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
          </button>
         
        </div>
      </div>
          </SwiperSlide> */}

          {/* <SwiperSlide>
      <div className="pastProgVideos">
        <h4 className="fs-25 fw-600 textUpper textWhite pb-20">EHIS LAB Aftermovie</h4>
        <video
         data-iframe-id="6"
          className="progVideos video"
          muted
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick}
          playsInline
        >
          <source src="https://musite.b-cdn.net/videos/emerald.mp4" type="video/mp4" />
        </video>

        {shownIframe === '6' && (
  <iframe
    className="video-iframe"
    data-iframe-id="6"
    width="560"
    height="315"
    src="https://www.youtube.com/embed/MmFXW0yUxNw?si=QfL37LlbVKpit25L&autoplay=1"
    title="YouTube video player 6"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
)}


        <div className="progVedioContent">
          <p className="fs-25 fw-400">
          MU20 School of Opportunity | Leadership & Adventure Bootcamp | Emerald Heights International School
          </p>
          <button className="readMoreIconBtn" data-iframe-id="4" onClick={handleVideoClick}>
          <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
          </button>
          
        </div>
      </div>
          </SwiperSlide> */}

          {/* <SwiperSlide>
      <div className="pastProgVideos">
        <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Mayo College LAB Aftermovie</h4>
        <video
         data-iframe-id="7"
          className="progVideos video"
          muted
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick}
          playsInline
        >
          <source src="https://musite.b-cdn.net/mayo_girls.mp4" type="video/mp4" />
        </video>

        {shownIframe === '7' && (
          <iframe
            className="video-iframe"
            data-iframe-id="7"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/NWeAlnmTfq8?si=pg3FeW2fji0FZmYb&autoplay=1" 
            title="YouTube video player 7"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}

        <div className="progVedioContent">
          <p className="fs-25 fw-400">
          MU20 School of Opportunity | Leadership & Adventure Bootcamp | Mayo College Girls' School
          </p>
          <button className="readMoreIconBtn" data-iframe-id="5" onClick={handleVideoClick}>
          <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
          </button>
         
        </div>
      </div>
          </SwiperSlide> */}

          {/* <SwiperSlide>
      <div className="pastProgVideos">
        <h4 className="fs-25 fw-600 textUpper textWhite pb-20">MGD LAB Aftermovie</h4>
        <video
         data-iframe-id="8"
          className="progVideos video"
          muted
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick}
          playsInline
        >
          <source src="https://musite.b-cdn.net/mgd_chants.mp4" type="video/mp4" />
        </video>

        {shownIframe === '8' && (
          <iframe
            className="video-iframe"
            data-iframe-id="8"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/cjuBSu2Qtk0?si=_I9R33hpbJy7qsSg&autoplay=1" 
            title="YouTube video player 8"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}

        <div className="progVedioContent">
          <p className="fs-25 fw-400">
          MU20 School of Opportunity | Leadership & Adventure Bootcamp | Maharani Gayatri Devi Girls' School
          </p>
          <button className="readMoreIconBtn" data-iframe-id="6" onClick={handleVideoClick}>
          <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
          </button>
         
        </div>
      </div>
          </SwiperSlide> */}



        </Swiper>

      </section>




      {/* <div className="container">
       
        <div className="program-outer">
            <div className="program-innerLeft">
            <video className="sliderBgImage" width="600" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
              <source src="assets/videos/video1.mov" type="video/mp4" />
              <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
            </video>
           

        </div>
            <div className="program-innerRight ">
              <video className="sliderBgImage" width="600" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                <source src="assets/videos/video1.mov" type="video/mp4" />
                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
              </video>
            </div>
        </div>
        
    </div> */}













      {/* <section className="advenBootcampSec p-80-20 bgBlack pt-0">
                <div className="container">
                    <div className="advenBootcamp-outer">
                        <div className="advenBootcamp-innerLeft">
                            <img src="assets/images/pillarPage/progarm.png" alt="pillarProgram" />
                        </div>
                        <div className="advenBootcamp-innerRight">
                            <h3 className="fs-34 fw-600 pb-20 textOrg ">Leadership and Adventure Bootcamp</h3>
                            <p className="fs-16 fw-400 textWhite pb-20">By connecting students from various backgrounds and schools, our
                                flagship programmes engage students and actively involve them in the learning and development
                                process.</p>

                            <ul className="advenBootcampList">
                                <li>
                                    <p className="fs-16 fw-400 textWhite">Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                                </li>
                                <li>
                                    <p className="fs-16 fw-400 textWhite">Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                                </li>
                                <li>
                                    <p className="fs-16 fw-400 textWhite">Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section> */}

      <Footer />

    </>
  )
}

export default Bootcamp_pillar