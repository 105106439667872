import React, { useEffect, useRef, useState } from "react";
import "./Home.css";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { Helmet } from "react-helmet";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// import PureModal from 'react-pure-modal';
// import 'react-pure-modal/dist/react-pure-modal.min.css';

// ----------------Swiper slider import------------------
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectFade,
  Keyboard,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css/effect-fade";
// import Swiper from 'swiper/bundle';
// import 'swiper/css/bundle';

// ----------------Gallery slider import------------------
import ImageGallery from "react-image-gallery";
import { ModalProvider } from "styled-react-modal";
import { Button, Modal } from "antd";
import RegisterModal from "../Popup/RegisterModal";

import AOS from "aos";
import "aos/dist/aos.css"; // Import the AOS styles

function HomeNew() {
  //Video on Hover Start

  const [shownIframe, setShownIframe] = useState(null);

  // Function to handle button clicks
  const handleVideoClick = (event) => {
    const iframeId = event.target.getAttribute("data-iframe-id");
    setShownIframe(iframeId);

    // Reset and set src to play the video
    const iframes = document.querySelectorAll(".video-iframe");
    iframes.forEach((iframe) => {
      if (iframe.getAttribute("data-iframe-id") === iframeId) {
        const src = iframe.src;
        iframe.src = ""; // Reset the src
        iframe.src = src; // Set it again to start playback
      }
    });
  };

  const [showIframe, setShowIframe] = useState(false);

  const [playingVideos, setPlayingVideos] = useState(new Set());
  const [shownIframes, setShownIframes] = useState({});

  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  useEffect(() => {
    document.title = "Home - MU20 School Of Opportunity";
  }, []);
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of the animation (in milliseconds)
    });

    if (isMobile) {
      const videos = document.querySelectorAll(".video");
      const observerOptions = {
        root: null, // Use the viewport as the container
        rootMargin: "0px",
        threshold: 0.5, // Adjust this as needed
      };

      const handleIntersection = (entries) => {
        entries.forEach((entry) => {
          const video = entry.target;
          if (entry.isIntersecting) {
            if (video.paused) {
              // Attempt to play the video and handle cases where play() might not return a Promise
              const playPromise = video.play();
              if (playPromise !== undefined) {
                playPromise.catch((error) =>
                  console.error("Play interrupted:", error)
                );
              }
              setPlayingVideos((prev) => new Set(prev.add(video)));
            }
          } else {
            if (!video.paused) {
              // Attempt to pause the video and handle cases where pause() might not return a Promise
              const pausePromise = video.pause();
              if (pausePromise !== undefined) {
                pausePromise.catch((error) =>
                  console.error("Pause interrupted:", error)
                );
              }
              setPlayingVideos((prev) => {
                const updated = new Set(prev);
                updated.delete(video);
                return updated;
              });
            }
          }
        });
      };

      const observer = new IntersectionObserver(
        handleIntersection,
        observerOptions
      );
      videos.forEach((video) => {
        observer.observe(video);
      });

      return () => {
        videos.forEach((video) => {
          observer.unobserve(video);
        });
      };
    }
  }, [isMobile, playingVideos]);

  const handleMouseEnter = (e) => {
    if (!isMobile) {
      const video = e.target;
      video.muted = true; // Keep the video muted to avoid autoplay restrictions
      if (video.paused) {
        // Attempt to play the video and handle cases where play() might not return a Promise
        const playPromise = video.play();
        if (playPromise !== undefined) {
          playPromise.catch((error) =>
            console.error("Play interrupted:", error)
          );
        }
      }
    }
  };

  const handleMouseLeave = (e) => {
    if (!isMobile) {
      const video = e.target;
      if (!video.paused) {
        const pausePromise = video.pause();
        if (pausePromise !== undefined) {
          pausePromise.catch((error) =>
            console.error("Pause interrupted:", error)
          );
        }
      }
    }
  };

  const handleMouseEnterTestimonials = (e) => {
    if (!isMobile) {
      const video = e.target;
      video.muted = false; // Keep the video muted to avoid autoplay restrictions
      if (video.paused) {
        // Attempt to play the video and handle cases where play() might not return a Promise
        const playPromise = video.play();
        if (playPromise !== undefined) {
          playPromise.catch((error) =>
            console.error("Play interrupted:", error)
          );
        }
      }
    }
  };

  const handleMouseLeaveTesimonials = (e) => {
    if (!isMobile) {
      const video = e.target;
      if (!video.paused) {
        const pausePromise = video.pause();
        if (pausePromise !== undefined) {
          pausePromise.catch((error) =>
            console.error("Pause interrupted:", error)
          );
        }
      }
    }
  };

  const handleUserInteraction = () => {
    document.userInteraction = true;
  };

  document.addEventListener("click", handleUserInteraction);
  document.addEventListener("touchstart", handleUserInteraction);

  const handleReadMoreClick = () => {
    setShowIframe(true);
  };

  const [isMuted, setIsMuted] = useState(true);
  const [isActive, setActive] = useState(false);

  const ToggleClass = () => {
    setActive(!isActive);
  };

  return (
    <>
      <Helmet>
        <title>Home - MU20 School of Opportunity</title>
        <meta name="title" content="" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
      <Header />

      <section className="oxfordBanner">
        <div>
          {/* <video className="sliderBgImage" autoPlay loop playsInline width="100%" muted={isMuted ? true : false}  playsInline>
          
            <source src="https://upcdn.io/kW15cAe/raw/Main%20Banner.mp4" type="video/mp4" />f
          </video> */}

          <video
            className="sliderBgImage"
            autoPlay
            loop
            playsInline
            width="100%"
            muted={isMuted}
            poster="assets/images/home_thumbnail.png"
          >
            <source
              src="https://musite.b-cdn.net/videos/home_banner.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="banner-content">
          {/* <h1>OxfordMUN India</h1> */}
          <div className={isActive ? "mute" : "unmute"}>
            {/* <a onClick={() => { setIsMuted(!isMuted); ToggleClass(); }} className="muteBtn widthAuto" id="btnUnMute"><img className="unmuteIcon" src="/assets/images/unmute.svg      " alt="unMuteIcon" />
              <img className="muteIcon"      src="/assets/images/mute.svg" alt="MuteIcon" /></a> */}
          </div>
        </div>
      </section>

      <section className="p-80-20 bgBlack ourPillarsSec">
        <div className="container-full">
          <div className="topHeading">
            <h6
              className="fs-22 fw-600 textOrg pb-10 textUpper"
              data-aos="fade-up"
            >
              The Cornerstones
            </h6>
            <h2 className="fs-80 fw-600 textWhite" data-aos="fade-up">
              Our Pillars
            </h2>
          </div>
          <div className="ourPillars-outer" data-aos="fade-up">
            <div className="pillars">
              <a href="/summits" rel="noopener noreferrer">
                <video
                  className="bgPillarsImg video"
                  width="400"
                  preload="auto"
                  muted
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={handleVideoClick}
                  playsInline
                >
                  <source
                    src="https://musite.b-cdn.net/summitpillae.mp4"
                    type="video/mp4"
                  />
                </video>
              </a>

              <div className="titleTop">
                <h4 className="fs-34 fw-700 bgDarkRed pb-10 pt-10  textWhite">
                  Summits
                </h4>
                {/* <h3 className="fs-34 fw-700 textWhite">Summits</h3> */}
              </div>
              <div className="content">
                <p className="fs-22 fw-500 textWhite">
                  MU20 School of Opportunity Summits bring together students
                  from around the world to engage in diverse challenges,
                  fostering leadership, innovation, and global networking.
                </p>
                <a href="/summits" className="readMoreIconBtn">
                  <img
                    className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg"
                    alt="iconBtn"
                  />
                </a>
              </div>
            </div>

            <div className="pillars">
              <a href="/bootcamp_pillar" rel="noopener noreferrer">
                <video
                  className="bgPillarsImg video"
                  width="400"
                  muted
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={handleVideoClick}
                  playsInline
                >
                  <source
                    src="https://musite.b-cdn.net/bootcamps.mp4"
                    type="video/mp4"
                  />
                </video>
              </a>
              <div className="titleTop">
                <h4 className="fs-34 fw-700 bgOrg pb-10 pt-10  textWhite">
                  Bootcamps
                </h4>
                {/* <h3 className="fs-34 fw-700 textWhite">Bootcamp</h3> */}
              </div>
              <div className="content">
                <p className="fs-22 fw-500 textWhite">
                  MU20 School of Opportunity Bootcamps offer immersive, hands-on
                  experiences that combine adventure activities with leadership
                  training, promoting holistic personal growth.
                </p>
                <a href="/bootcamp_pillar" className="readMoreIconBtn">
                  <img
                    className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg"
                    alt="iconBtn"
                  />
                </a>
              </div>
            </div>

            <div className="pillars">
              <a href="/online_challenges" rel="noopener noreferrer">
                <video
                  className="bgPillarsImg video"
                  width="400"
                  preload="auto"
                  muted
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={handleVideoClick}
                  playsInline
                  poster="assets/images/onlinechallenge_thumbnail.png"
                >
                  <source
                    src="https://musite.b-cdn.net/videos/online_challenge_pillar_(1).mp4"
                    type="video/mp4"
                  />
                </video>
              </a>
              <div className="titleTop">
                <h4 className="fs-34 fw-700 pb-10 pt-10  textWhite bgYellow">
                  Online Challenges
                </h4>
                {/* <h3 className="fs-34 fw-700 textWhite">Online Challenges</h3> */}
              </div>
              <div className="content">
                <p className="fs-22 fw-500 textWhite">
                  MU20 School of Opportunity Online Challenges allow students to
                  compete in various creative and intellectual tasks from
                  anywhere in the world, enhancing their skills and global
                  engagement.
                </p>
                <a href="/online_challenges" className="readMoreIconBtn">
                  <img
                    className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg"
                    alt="iconBtn"
                  />
                </a>
              </div>
            </div>

            <div className="pillars">
              <a href="/Residential_program" rel="noopener noreferrer">
                {/* <video className="bgPillarsImg video" width="400" preload="auto" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline >
                      <source src="https://musite.b-cdn.net/videos/residential_Pilalr.mp4" type="video/mp4" />
                     
                    </video> */}

                <video
                  className="bgPillarsImg video"
                  width="400"
                  preload="auto"
                  muted
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={handleVideoClick}
                  playsInline
                  poster="assets/images/Residential_tumbnail.png"
                >
                  <source
                    src="https://musite.b-cdn.net/videos/residential_Pilalr.mp4"
                    type="video/mp4"
                  />
                </video>
              </a>
              <div className="titleTop">
                <h4 className="fs-34 fw-700 pb-10 pt-10  textWhite     bgSkyBlue">
                  Residential Programs
                </h4>
                {/* <h3 className="fs-34 fw-700 textWhite">Residential Programs</h3> */}
              </div>

              <div className="content">
                <p className="fs-22 fw-500 textWhite">
                  MU20 School of Opportunity Residential Programs provide an
                  intensive learning environment where students live on-site,
                  participate in structured activities, and build lifelong
                  skills and connections.
                </p>
                <a href="/Residential_program" className="readMoreIconBtn">
                  <img
                    className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg"
                    alt="iconBtn"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="p-80-20 bgBlack feacProgramSec">
        <div className="container-full" data-aos="fade-up">
          <div className="topHeading">
            <h6
              className="fs-22 fw-600 textOrg pb-10 textUpper"
              data-aos="fade-up"
            >
              Explore
            </h6>
            <h2 className="fs-80 fw-600 textWhite" data-aos="fade-up">
              Featured Programs
            </h2>
          </div>
          <Swiper
            breakpoints={{
              1440: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
              768: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
              200: {
                spaceBetween: 20,
                slidesPerView: 1,
                autoplay: false,
              },
            }}
            // install Swiper modules
            modules={[Navigation, Autoplay, A11y]}
            spaceBetween={30}
            speed={5000}
            slidesPerView={3}
            centerInsufficientSlides={true}
            navigation={false}
            loop={true}
            // autoplay={{
            //   delay: 2000,
            // }}
            autoplay={{
              delay: 1,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            freeMode={false}
            centeredSlides={true}
          >
            <SwiperSlide>
              <div className="sliderOne">
                <div className="programContent">
                  <div className="programContent-inner">
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                      MU20 Opportunity Summit
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                      Engage in diverse challenges and workshops that foster
                      leadership, innovation, and global networking.
                    </p>
                  </div>
                  <a href="/Summits" className="readMoreIconBtn">
                    <img
                      className="widthAuto"
                      src="assets/images/homeImage/arrowWhite.svg"
                      alt="iconBtn"
                    />
                  </a>
                </div>

                <a
                  href="/MU20OpportunitySummit24"
                  className="content_featured"
                  rel="noopener noreferrer"
                >
                  <video
                    className="sliderBgImage video"
                    width="400"
                    muted={isMuted}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleVideoClick}
                    playsInline
                  >
                    <source
                      src="https://musite.b-cdn.net/mu20summitmainpage.mp4"
                      type="video/mp4"
                    />
                  </video>
                </a>
                {/* <div className="programContent">
                  <div className="programContent-inner">
                   
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">MU20 Opportunity Summit
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                    Engage in diverse challenges and workshops that foster leadership, innovation, and global networking.
                    </p>
                  </div>
                  <a href="/Summits" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div> */}
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="sliderOne">
                <div className="programContent">
                  <div className="programContent-inner">
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                      Young Leader's Bootcamp (YLB)
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                      Develop leadership skills and personal growth through
                      immersive and comprehensive learning experiences.
                    </p>
                  </div>
                  <a href="/Young_Leaders_Bootcamp" className="readMoreIconBtn">
                    <img
                      className="widthAuto"
                      src="assets/images/homeImage/arrowWhite.svg"
                      alt="iconBtn"
                    />
                  </a>
                </div>

                <a
                  href="/Young_Leaders_Bootcamp "
                  className="content_featured"
                  rel="noopener noreferrer"
                >
                  <video
                    className="sliderBgImage video"
                    width="400"
                    muted={isMuted}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleVideoClick}
                    playsInline
                  >
                    <source
                      src="https://musite.b-cdn.net/bootcampsmainpage021920X900(1).mp4"
                      type="video/mp4"
                    />
                  </video>
                </a>
                {/* <div className="programContent">
                  <div className="programContent-inner">
                   
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                    Young Leader's Bootcamp (YLB)

                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                    Develop leadership skills and personal growth through immersive and comprehensive  learning experiences.
                    </p>
                  </div>
                  <a href="/Young_Leaders_Bootcamp" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div> */}
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="sliderOne">
                <div className="programContent">
                  <div className="programContent-inner">
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                      Leadership and Adventure Bootcamp (LAB)
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                      Push your limits with thrilling activities and intensive
                      leadership workshops in an immersive setting.
                    </p>
                  </div>
                  <a
                    href="/LeadershipandAdventureBootcamp"
                    className="readMoreIconBtn"
                  >
                    <img
                      className="widthAuto"
                      src="assets/images/homeImage/arrowWhite.svg"
                      alt="iconBtn"
                    />
                  </a>
                </div>

                <a
                  href="/LeadershipandAdventureBootcamp "
                  className="content_featured"
                  rel="noopener noreferrer"
                >
                  <video
                    className="sliderBgImage video"
                    width="400"
                    muted={isMuted}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleVideoClick}
                    playsInline
                  >
                    <source
                      src="https://musite.b-cdn.net/labmainpage.mp4"
                      type="video/mp4"
                    />
                  </video>
                </a>
                {/* <div className="programContent">
                  <div className="programContent-inner">
                   
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                    Leadership and Adventure Bootcamp (LAB)

                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                    Push your limits with thrilling activities and intensive leadership workshops in an immersive setting.
                    </p>
                  </div>
                  <a href="/LeadershipandAdventureBootcamp" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div> */}
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="sliderOne">
                <div className="programContent">
                  <div className="programContent-inner">
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                      MU20 Opportunity Summit
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                      Engage in diverse challenges and workshops that foster
                      leadership, innovation, and global networking.
                    </p>
                  </div>
                  <a href="/Summits" className="readMoreIconBtn">
                    <img
                      className="widthAuto"
                      src="assets/images/homeImage/arrowWhite.svg"
                      alt="iconBtn"
                    />
                  </a>
                </div>

                <a
                  href="/MU20OpportunitySummit24"
                  className="content_featured"
                  rel="noopener noreferrer"
                >
                  <video
                    className="sliderBgImage video"
                    width="400"
                    preload="auto"
                    muted
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    playsInline
                  >
                    <source
                      src="https://musite.b-cdn.net/mu20summitmainpage.mp4"
                      type="video/mp4"
                    />
                  </video>
                </a>
                {/* <div className="programContent">
                  <div className="programContent-inner">
                   
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">MU20 Opportunity Summit
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                    Engage in diverse challenges and workshops that foster leadership, innovation, and global networking.
                    </p>
                  </div>
                  <a href="/Summits" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div> */}
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="sliderOne">
                <div className="programContent">
                  <div className="programContent-inner">
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                      Young Leader's Bootcamp (YLB)
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                      Develop leadership skills and personal growth through
                      immersive, hands-on training experiences.
                    </p>
                  </div>
                  <a href="Young_Leaders_Bootcamp" className="readMoreIconBtn">
                    <img
                      className="widthAuto"
                      src="assets/images/homeImage/arrowWhite.svg"
                      alt="iconBtn"
                    />
                  </a>
                </div>

                <a
                  href="/Young_Leaders_Bootcamp"
                  className="content_featured"
                  rel="noopener noreferrer"
                >
                  <video
                    className="sliderBgImage video"
                    width="400"
                    preload="auto"
                    muted
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    playsInline
                  >
                    <source
                      src="https://musite.b-cdn.net/bootcampsmainpage021920X900(1).mp4"
                      type="video/mp4"
                    />
                  </video>
                </a>
                {/* <div className="programContent">
                  <div className="programContent-inner">
                   
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                    Young Leader's Bootcamp (YLB)
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                    Develop leadership skills and personal growth through immersive, hands-on training experiences.
                    </p>
                  </div>
                  <a href="Young_Leaders_Bootcamp" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div> */}
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="sliderOne">
                <div className="programContent">
                  <div className="programContent-inner">
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                      Leadership and Adventure Bootcamp (LAB)
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                      Push your limits with thrilling activities and intensive
                      leadership workshops in an immersive setting.
                    </p>
                  </div>
                  <a
                    href="/LeadershipandAdventureBootcamp"
                    className="readMoreIconBtn"
                  >
                    <img
                      className="widthAuto"
                      src="assets/images/homeImage/arrowWhite.svg"
                      alt="iconBtn"
                    />
                  </a>
                </div>

                <a
                  href="/LeadershipandAdventureBootcamp"
                  className="content_featured"
                  rel="noopener noreferrer"
                >
                  <video
                    className="sliderBgImage video"
                    width="400"
                    preload="auto"
                    muted
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    playsInline
                  >
                    <source
                      src="https://musite.b-cdn.net/labmainpage.mp4"
                      type="video/mp4"
                    />
                  </video>
                </a>
                {/* <div className="programContent">
                  <div className="programContent-inner">
                  
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                      Leadership and Adventure Bootcamp (LAB)
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                    Push your limits with thrilling activities and intensive leadership workshops in an immersive setting.
                    </p>
                  </div>
                  <a href="/LeadershipandAdventureBootcamp" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div> */}
              </div>
            </SwiperSlide>
          </Swiper>

          <Swiper
            className="feacProgram-outerCenter"
            breakpoints={{
              1440: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
              768: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
              200: {
                spaceBetween: 20,
                slidesPerView: 1,
                autoplay: false,
              },
            }}
            // install Swiper modules
            modules={[Navigation, Autoplay, A11y]}
            spaceBetween={30}
            slidesPerView={3}
            speed={5000}
            centerInsufficientSlides={true}
            navigation={false}
            slidesOffsetBefore={-350}
            loop={true}
            autoplay={{
              delay: 1,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
              reverseDirection: true,
            }}
            freeMode={false}
            centeredSlides={true}
          >
            <SwiperSlide>
              <div className="sliderOne">
                <a href="/Bootcamp_pillar" rel="noopener noreferrer">
                  <div className="programContent">
                    <div className="programContent-inner">
                      <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                        Educators’ Leadership Retreat (EdLR)
                      </h5>
                      <p className="fs-14 fw-400 textWhite ">
                        Empower educators with advanced training in leadership,
                        innovative pedagogy, and global educational practices.
                      </p>
                    </div>
                    <a href="#" className="readMoreIconBtn">
                      <img
                        className="widthAuto"
                        src="assets/images/homeImage/arrowWhite.svg"
                        alt="iconBtn"
                      />
                    </a>
                  </div>

                  <video
                    className="sliderBgImage video"
                    width="400"
                    muted={isMuted}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleVideoClick}
                    playsInline
                    poster="assets/images/edlr_thumbnail_slider.png"
                  >
                    <source
                      src="https://musite.b-cdn.net/videos/teachers_Main_page.mp4"
                      type="video/mp4"
                    />
                  </video>
                </a>
                {/* <div className="programContent">
                  <div className="programContent-inner">
                  
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Educators’ Leadership Retreat (EdLR)
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">Empower educators with advanced training in leadership, innovative pedagogy, and global educational practices.
                    </p>
                  </div>
                  <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div> */}
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="sliderOne">
                <a href="/oxfordmun" rel="noopener noreferrer">
                  <div className="programContent">
                    <div className="programContent-inner">
                      <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                        OxfordMUN India 2025
                      </h5>
                      <p className="fs-14 fw-400 textWhite ">
                        Participate in high-level diplomatic simulations,
                        fostering debate, diplomacy, and leadership skills.
                      </p>
                    </div>
                    <a href="/" className="readMoreIconBtn">
                      <img
                        className="widthAuto"
                        src="assets/images/homeImage/arrowWhite.svg"
                        alt="iconBtn"
                      />
                    </a>
                  </div>

                  <video
                    className="sliderBgImage video"
                    width="400"
                    muted
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    poster="assets/images/mun_thumbnail.png"
                  >
                    <source
                      src="https://musite.b-cdn.net/videos/OxfordMUN%20India%202025%20-%20Launch%20Trailer%20FInal%20Cut.mp4"
                      type="video/mp4"
                    />
                  </video>
                </a>
                {/* <div className="programContent">
                  <div className="programContent-inner">
                   
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                    OxfordMUN
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                    Participate in high-level diplomatic simulations, fostering debate, diplomacy, and leadership skills.
                    </p>
                  </div>
                  <a href="/" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
                <a href="/hsis" rel="noopener noreferrer">
                  <div className="programContent">
                    <div className="programContent-inner">
                      <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                        Harvard Sustainability Impact Summit 2025
                      </h5>
                      <p className="fs-14 fw-400 textWhite ">
                        A platform for high-schoolers to address global
                        sustainability challenges through policy innovation,
                        strategic advocacy, and expert mentorship.
                      </p>
                    </div>
                    <a href="/" className="readMoreIconBtn">
                      <img
                        className="widthAuto"
                        src="assets/images/homeImage/arrowWhite.svg"
                        alt="iconBtn"
                      />
                    </a>
                  </div>

                  <video
                    className="sliderBgImage video"
                    width="400"
                    muted
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    poster="\assets\images\hsis\coverImage.jpg"
                  >
                    <source
                      src="https://musite.b-cdn.net/videos/HSIS%202025%20Launch%20Trailer%20(Final%20cut).mp4"
                      type="video/mp4"
                    />
                  </video>
                </a>
                {/* <div className="programContent">
                  <div className="programContent-inner">
                   
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                    OxfordMUN
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                    Participate in high-level diplomatic simulations, fostering debate, diplomacy, and leadership skills.
                    </p>
                  </div>
                  <a href="/" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div> */}
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="sliderOne">
                <a href="/CrafttheFutureChallenge24" rel="noopener noreferrer">
                  <div className="programContent">
                    <div className="programContent-inner">
                      <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                        Craft the Future Challenge
                      </h5>
                      <p className="fs-14 fw-400 textWhite ">
                        Design innovative AI-enhanced kitchens, showcasing your
                        creativity and problem-solving abilities.
                      </p>
                    </div>
                    <a
                      href="/CrafttheFutureChallenge24"
                      className="readMoreIconBtn"
                    >
                      <img
                        className="widthAuto"
                        src="assets/images/homeImage/arrowWhite.svg"
                        alt="iconBtn"
                      />
                    </a>
                  </div>

                  <video
                    className="sliderBgImage video"
                    width="400"
                    muted
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    playsInline
                  >
                    <source
                      src="https://musite.b-cdn.net/ctfmainpage.mp4"
                      type="video/mp4"
                    />
                  </video>
                </a>
                {/* <div className="programContent">
                  <div className="programContent-inner">
                    
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                    Craft the Future Challenge

                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                    Design innovative AI-enhanced kitchens, showcasing your creativity and problem-solving abilities.
                    </p>
                  </div>
                  <a href="/CrafttheFutureChallenge24" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div> */}
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="sliderOne">
                <a href="/Bootcamp_pillar" rel="noopener noreferrer">
                  <div className="programContent">
                    <div className="programContent-inner">
                      <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                        Educators’ Leadership Retreat (EdLR)
                      </h5>
                      <p className="fs-14 fw-400 textWhite ">
                        Empower educators with advanced training in leadership,
                        innovative pedagogy, and global educational practices.
                      </p>
                    </div>
                    <a href="#" className="readMoreIconBtn">
                      <img
                        className="widthAuto"
                        src="assets/images/homeImage/arrowWhite.svg"
                        alt="iconBtn"
                      />
                    </a>
                  </div>

                  <video
                    className="sliderBgImage video"
                    width="400"
                    muted={isMuted}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleVideoClick}
                    playsInline
                    poster="assets/images/edlr_thumbnail_slider.png"
                  >
                    <source
                      src="https://musite.b-cdn.net/videos/teachers_Main_page.mp4"
                      type="video/mp4"
                    />
                  </video>
                </a>
                {/* <div className="programContent">
                  <div className="programContent-inner">
                  
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Educators’ Leadership Retreat (EdLR)
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">Empower educators with advanced training in leadership, innovative pedagogy, and global educational practices.
                    </p>
                  </div>
                  <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div> */}
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="sliderOne">
                <a href="/oxfordmun" rel="noopener noreferrer">
                  <div className="programContent">
                    <div className="programContent-inner">
                      <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                        OxfordMUN India 2025
                      </h5>
                      <p className="fs-14 fw-400 textWhite ">
                        Participate in high-level diplomatic simulations,
                        fostering debate, diplomacy, and leadership skills.
                      </p>
                    </div>
                    <a href="/" className="readMoreIconBtn">
                      <img
                        className="widthAuto"
                        src="assets/images/homeImage/arrowWhite.svg"
                        alt="iconBtn"
                      />
                    </a>
                  </div>

                  <video
                    className="sliderBgImage video"
                    width="400"
                    muted
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    poster="assets/images/mun_thumbnail.png"
                  >
                    <source
                      src="https://musite.b-cdn.net/videos/OxfordMUN%20India%202025%20-%20Launch%20Trailer%20FInal%20Cut.mp4"
                      type="video/mp4"
                    />
                  </video>
                </a>
                {/* <div className="programContent">
                  <div className="programContent-inner">
                   
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                    OxfordMUN
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                    Participate in high-level diplomatic simulations, fostering debate, diplomacy, and leadership skills.
                    </p>
                  </div>
                  <a href="/" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
                <a href="/hsis" rel="noopener noreferrer">
                  <div className="programContent">
                    <div className="programContent-inner">
                      <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                        Harvard Sustainability Impact Summit 2025
                      </h5>
                      <p className="fs-14 fw-400 textWhite ">
                        A platform for high-schoolers to address global
                        sustainability challenges through policy innovation,
                        strategic advocacy, and expert mentorship.
                      </p>
                    </div>
                    <a href="/" className="readMoreIconBtn">
                      <img
                        className="widthAuto"
                        src="assets/images/homeImage/arrowWhite.svg"
                        alt="iconBtn"
                      />
                    </a>
                  </div>

                  <video
                    className="sliderBgImage video"
                    width="400"
                    muted
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    poster="\assets\images\hsis\coverImage.jpg"
                  >
                    <source
                      src="https://musite.b-cdn.net/videos/HSIS%202025%20Launch%20Trailer%20(Final%20cut).mp4"
                      type="video/mp4"
                    />
                  </video>
                </a>
                {/* <div className="programContent">
                  <div className="programContent-inner">
                   
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                    OxfordMUN
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                    Participate in high-level diplomatic simulations, fostering debate, diplomacy, and leadership skills.
                    </p>
                  </div>
                  <a href="/" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div> */}
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="sliderOne">
                <a href="/CrafttheFutureChallenge24" rel="noopener noreferrer">
                  <div className="programContent">
                    <div className="programContent-inner">
                      <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                        Craft the Future Challenge
                      </h5>
                      <p className="fs-14 fw-400 textWhite ">
                        Design innovative AI-enhanced kitchens, showcasing your
                        creativity and problem-solving abilities.
                      </p>
                    </div>
                    <a
                      href="CrafttheFutureChallenge24"
                      className="readMoreIconBtn"
                    >
                      <img
                        className="widthAuto"
                        src="assets/images/homeImage/arrowWhite.svg"
                        alt="iconBtn"
                      />
                    </a>
                  </div>

                  <video
                    className="sliderBgImage video"
                    width="400"
                    muted
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    playsInline
                  >
                    <source
                      src="https://musite.b-cdn.net/ctfmainpage.mp4"
                      type="video/mp4"
                    />
                  </video>
                </a>
              </div>
            </SwiperSlide>
          </Swiper>

          <Swiper
            breakpoints={{
              1440: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
              768: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
              200: {
                spaceBetween: 20,
                slidesPerView: 1,
                autoplay: false,
              },
            }}
            // install Swiper modules
            modules={[Navigation, Autoplay, A11y]}
            spaceBetween={30}
            slidesPerView={3}
            speed={5000}
            centerInsufficientSlides={true}
            navigation={false}
            loop={true}
            // autoplay={{
            //   delay: 2000,
            // }}
            autoplay={{
              delay: 1,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            freeMode={false}
            centeredSlides={true}
          >
            <SwiperSlide>
              <div className="sliderOne">
                <a href="/Residential_program" rel="noopener noreferrer">
                  <video
                    className="sliderBgImage video"
                    width="400"
                    muted
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    playsInline
                    poster="assets/images/residential_slider_thumbnail.png"
                  >
                    <source
                      src="https://musite.b-cdn.net/videos/pathwaysm_Main_Page.mp4"
                      type="video/mp4"
                    />
                  </video>
                </a>
                <div className="programContent">
                  <div className="programContent-inner">
                    {/* <h6 className="textUpper lineHeight1">Find Your Voice</h6> */}
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                      Residential Programs
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                      Immerse yourself in a structured learning environment,
                      enhancing both academic and personal development.
                    </p>
                  </div>
                  <a href="/Residential_program" className="readMoreIconBtn">
                    <img
                      className="widthAuto"
                      src="assets/images/homeImage/arrowWhite.svg"
                      alt="iconBtn"
                    />
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
                <a href="/OxfordMUNPreChallenge" rel="noopener noreferrer">
                  <video
                    className="sliderBgImage video"
                    width="400"
                    muted
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    playsInline
                    poster="assets/images/mun_thumbnail.png"
                  >
                    <source
                      src="https://musite.b-cdn.net/bannervideoCom.mp4"
                      type="video/mp4"
                    />
                  </video>
                </a>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                      Oxford Pre-Challenge
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                      Participate in an exciting challenge to sharpen your
                      debating and diplomatic skills, with the winner getting
                      their entire registration fee refunded.
                    </p>
                  </div>
                  <a href="OxfordMUNPreChallenge" className="readMoreIconBtn">
                    <img
                      className="widthAuto"
                      src="assets/images/homeImage/arrowWhite.svg"
                      alt="iconBtn"
                    />
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
                <a href="/Online_Challenges" rel="noopener noreferrer">
                  <video
                    className="sliderBgImage video"
                    width="400"
                    muted
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    playsInline
                  >
                    <source
                      src="https://musite.b-cdn.net/fellowshipmainpage.mp4"
                      type="video/mp4"
                    />
                  </video>
                </a>
                <div className="programContent">
                  <div className="programContent-inner">
                    {/* <h6 className="textUpper lineHeight1">Find Your Voice</h6> */}
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                      MU20 Fellowship
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                      Experience a transformative journey that blends leadership
                      training with real-world challenges and mentorship.
                    </p>
                  </div>
                  <a href="/" className="readMoreIconBtn">
                    <img
                      className="widthAuto"
                      src="assets/images/homeImage/arrowWhite.svg"
                      alt="iconBtn"
                    />
                  </a>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="sliderOne">
                <a href="/Residential_program" rel="noopener noreferrer">
                  <video
                    className="sliderBgImage video"
                    width="400"
                    muted
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    playsInline
                    poster="assets/images/residential_slider_thumbnail.png"
                  >
                    <source
                      src="https://musite.b-cdn.net/videos/pathwaysm_Main_Page.mp4"
                      type="video/mp4"
                    />
                  </video>
                </a>
                <div className="programContent">
                  <div className="programContent-inner">
                    {/* <h6 className="textUpper lineHeight1">Find Your Voice</h6> */}
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                      Residential Programs
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                      Immerse yourself in a structured learning environment,
                      enhancing both academic and personal development.
                    </p>
                  </div>
                  <a href="/Residential_program" className="readMoreIconBtn">
                    <img
                      className="widthAuto"
                      src="assets/images/homeImage/arrowWhite.svg"
                      alt="iconBtn"
                    />
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
                <a href="/OxfordMUNPreChallenge" rel="noopener noreferrer">
                  <video
                    className="sliderBgImage video"
                    width="400"
                    muted
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    playsInline
                    poster="assets/images/mun_thumbnail.png"
                  >
                    <source
                      src="https://musite.b-cdn.net/bannervideoCom.mp4"
                      type="video/mp4"
                    />
                  </video>
                </a>
                <div className="programContent">
                  <div className="programContent-inner">
                    {/* <h6 className="textUpper lineHeight1">Find Your Voice</h6> */}
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                      Oxford Pre-Challenge
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                      Participate in an exciting challenge to sharpen your
                      debating and diplomatic skills, with the winner getting
                      their entire registration fee refunded.
                    </p>
                  </div>
                  <a href="OxfordMUNPreChallenge" className="readMoreIconBtn">
                    <img
                      className="widthAuto"
                      src="assets/images/homeImage/arrowWhite.svg"
                      alt="iconBtn"
                    />
                  </a>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="sliderOne">
                <a href="/Online_Challenges" rel="noopener noreferrer">
                  <video
                    className="sliderBgImage video"
                    width="400"
                    muted
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    playsInline
                  >
                    <source
                      src="https://musite.b-cdn.net/fellowshipmainpage.mp4"
                      type="video/mp4"
                    />
                  </video>
                </a>
                <div className="programContent">
                  <div className="programContent-inner">
                    {/* <h6 className="textUpper lineHeight1">Find Your Voice</h6> */}
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                      MU20 Fellowship
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                      Experience a transformative journey that blends leadership
                      training with real-world challenges and mentorship.
                    </p>
                  </div>
                  <a href="#" className="readMoreIconBtn">
                    <img
                      className="widthAuto"
                      src="assets/images/homeImage/arrowWhite.svg"
                      alt="iconBtn"
                    />
                  </a>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      <section className="p-80-20 bglightBlack networkSchoolSec">
        <div className="container" data-aos="fade-up">
          <div className="topHeading">
            <h6
              className="fs-22 fw-600 textOrg pb-10 textUpper"
              data-aos="fade-up"
            >
              Our Partner
            </h6>
            <h2 className="fs-80 fw-600 pb-20 textWhite" data-aos="fade-up">
              Network Schools
            </h2>
            <p className="subContent" data-aos="fade-up">
              In recognition of the challenges in creating abundant
              opportunities at scale, our vision is to curate these
              transformative experiences for a select network of schools that
              share our ideology for creating exceptional opportunities for high
              school students.
            </p>
          </div>
          <div className="networkSchoolSlider-outer">
            <Swiper
              modules={[Navigation, Autoplay, A11y, Keyboard]} // Necessary modules
              spaceBetween={10} // Slight space between slides for better appearance
              slidesPerView={7}
              cssMode={false}
              centerInsufficientSlides={true}
              navigation={false}
              loop={true}
              autoplay={{
                delay: 1500, // Autoplay delay
                disableOnInteraction: true, // Pause autoplay on user interaction
                pauseOnMouseEnter: true, // Pause autoplay on hover
              }}
              centeredSlides={true}
              keyboard={{
                enabled: true,
                onlyInViewport: true, // Enable keyboard navigation only when Swiper is in view
              }}
              breakpoints={{
                200: {
                  slidesPerView: 2,
                  spaceBetween: 5, // Added space for smaller screens
                  autoplay: false,
                },
                380: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 5,
                  spaceBetween: 15,
                },
                1024: {
                  slidesPerView: 7,
                  spaceBetween: 20,
                },
                1440: {
                  slidesPerView: 7,
                  spaceBetween: 25, // Increased space for larger screens
                },
              }}
            >
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/always.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/ashoka.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/bharati.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/bihani.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/billabong.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/birla.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/bk_birla.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/crossroads.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/dalimess.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/delhi.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/dolphin.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/doon.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/delhiworld.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/doon_girl.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/duty.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/emrald.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/goenka.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/golden.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/him.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/ies.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/inventure.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/jayshree.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/jbcn.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/jindal.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/jodhamal.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/jyoti.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/kasiga.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/kunska.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/macro.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/mann.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/mcd.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/no.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/pacific.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/pathways.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/pinegrove.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/queen.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/rajkumar.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/rajkumar_college.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/rajmas.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/rajmata.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/rockwoods.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/sagar_school.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/sanskar.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/satpuda.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/satyasai.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/school.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/scindia.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/shishukunj.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/singapoor.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/strength.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/timor.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/truth.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/uwc.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/valistus.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/vantage.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/vidhya.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/virtus.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/vivek.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/wisdom.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/world.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img
                    src="assets/images/School_logos/zabar.png"
                    alt="networkSch"
                  />
                </div>
              </SwiperSlide>

              {/* <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/witty.png" alt="networkSch" />
                </div>
              </SwiperSlide> */}
            </Swiper>
          </div>
        </div>
      </section>

      <section className="p-80-20 bgBlack   pastProgVideoSec">
        <div className="container-full" data-aos="fade-up">
          <div className="topHeading">
            <h2 className="fs-80 fw-600 textWhite" data-aos="fade-up">
              Past Program Videos
            </h2>
          </div>
        </div>

        <Swiper
          modules={[Pagination, A11y, Autoplay]}
          speed={1000}
          loop={true}
          freeMode={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
          }}
          centeredSlides={true}
          slideToClickedSlide={true}
          breakpoints={{
            // when window width is >= 200px
            200: {
              slidesPerView: "auto",
              spaceBetween: 0,
              autoplay: false,
            },
            380: {
              slidesPerView: "1",
              spaceBetween: 5,
              slidesOffsetBefore: -35,
            },

            // when window width is >= 767px (tablet portrait)
            768: {
              slidesPerView: "1",
              spaceBetween: 0,
            },
            // when window width is >= 1024px (tablet landscape)
            1024: {
              slidesPerView: "3",
              spaceBetween: 0,
            },
            // when window width is >= 1440px (desktop)
            1440: {
              slidesPerView: "3",
              spaceBetween: 0,
            },
          }}
        >
          <SwiperSlide>
            <div className="pastProgVideos">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">
                MU20 Summit Aftermovie
              </h4>
              <video
                data-iframe-id="1"
                className="progVideos video"
                muted
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleVideoClick}
                playsInline
              >
                <source
                  src="https://musite.b-cdn.net/summitpillarpage.mp4"
                  type="video/mp4"
                />
              </video>
              {shownIframe === "1" && (
                <iframe
                  className="video-iframe"
                  data-iframe-id="1"
                  width="550"
                  height="315"
                  src="https://www.youtube.com/embed/yL1OyZ3-PYY?si=KYrxMvXOWBRxIcljs" // Corrected URL
                  title="YouTube video player 1"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}

              <div className="progVedioContent">
                <p className="fs-25 fw-400">
                  MU20 High School Summit | Asia's Biggest High School
                  Leadership Conference | Aftermovie
                </p>
                <button
                  className="readMoreIconBtn"
                  data-iframe-id="1"
                  onClick={handleVideoClick}
                >
                  <img
                    className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg"
                    alt="iconBtn"
                  />
                </button>
                {/* <a href="#" className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
            <img
              className="widthAuto readMoreIconBtn"
              src="assets/images/homeImage/arrowWhite.svg"
              alt="iconBtn"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleReadMoreClick}
            />
          </a> */}
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">
                Pathways Aftermovie
              </h4>
              <video
                data-iframe-id="2"
                className="progVideos video"
                muted
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleVideoClick}
                playsInline
                poster="assets/images/residential_slider_thumbnail.png"
              >
                <source
                  src="https://musite.b-cdn.net/videos/pathwaysm_Main_Page.mp4"
                  type="video/mp4"
                />
              </video>

              {shownIframe === "2" && (
                <iframe
                  className="video-iframe"
                  data-iframe-id="2"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/rnEspqnT68k?si=6MdzW69ArLUdCPzl" // Corrected URL
                  title="YouTube video player 2"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}

              <div className="progVedioContent">
                <p className="fs-25 fw-400">
                  MU20 Pathways Residential Programme 2024 | Pathways World
                  School | Aftermovie
                </p>
                <button
                  className="readMoreIconBtn"
                  data-iframe-id="2"
                  onClick={handleVideoClick}
                >
                  <img
                    className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg"
                    alt="iconBtn"
                  />
                </button>
                {/* <a href="#" className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
            <img
              className="widthAuto readMoreIconBtn"
              src="assets/images/homeImage/arrowWhite.svg"
              alt="iconBtn"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleReadMoreClick}
            />
          </a> */}
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">
                MGD LAB Aftermovie
              </h4>
              <video
                data-iframe-id="3"
                className="progVideos video"
                muted
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleVideoClick}
                playsInline
              >
                <source
                  src="https://musite.b-cdn.net/mgd_chants.mp4"
                  type="video/mp4"
                />
              </video>

              {shownIframe === "3" && (
                <iframe
                  className="video-iframe"
                  data-iframe-id="3"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/cjuBSu2Qtk0?si=nCdmzfaE9ASPHpdG" // Corrected URL
                  title="YouTube video player 3"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}

              <div className="progVedioContent">
                <p className="fs-25 fw-400">
                  MU20 School of Opportunity | Leadership & Adventure Bootcamp |
                  Maharani Gayatri Devi Girls' School
                </p>
                <button
                  className="readMoreIconBtn"
                  data-iframe-id="3"
                  onClick={handleVideoClick}
                >
                  <img
                    className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg"
                    alt="iconBtn"
                  />
                </button>
                {/* <a href="#" className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
            <img
              className="widthAuto readMoreIconBtn"
              src="assets/images/homeImage/arrowWhite.svg"
              alt="iconBtn"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleReadMoreClick}
            />
          </a> */}
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">
                Doon LAB Aftermovie
              </h4>
              <video
                data-iframe-id="4"
                className="progVideos video"
                muted
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleVideoClick}
                playsInline
              >
                <source
                  src="https://musite.b-cdn.net/videos/doon.mp4"
                  type="video/mp4"
                />
              </video>

              {shownIframe === "4" && (
                <iframe
                  className="video-iframe"
                  data-iframe-id="4"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/3ylVNxFkU6A?si=K_UhHRUW4TNZjUJG" // Corrected URL
                  title="YouTube video player 4"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}

              <div className="progVedioContent">
                <p className="fs-25 fw-400">
                  Leadership and Adventure Bootcamp | The Doon School, Dehradun
                  Cohort | Aftermovie
                </p>
                <button
                  className="readMoreIconBtn"
                  data-iframe-id="4"
                  onClick={handleVideoClick}
                >
                  <img
                    className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg"
                    alt="iconBtn"
                  />
                </button>
                {/* <a href="#" className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
            <img
              className="widthAuto readMoreIconBtn"
              src="assets/images/homeImage/arrowWhite.svg"
              alt="iconBtn"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleReadMoreClick}
            />
          </a> */}
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">
                EHIS LAB Aftermovie
              </h4>
              <video
                data-iframe-id="5"
                className="progVideos video"
                muted
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleVideoClick}
                playsInline
              >
                <source
                  src="https://musite.b-cdn.net/videos/emerald.mp4"
                  type="video/mp4"
                />
              </video>

              {shownIframe === "5" && (
                <iframe
                  className="video-iframe"
                  data-iframe-id="5"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/MmFXW0yUxNw?si=vz7D1B3ThEQeUb2d" // Added autoplay=1
                  title="YouTube video player 5"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}

              <div className="progVedioContent">
                <p className="fs-25 fw-400">
                  MU20 School of Opportunity | Leadership & Adventure Bootcamp |
                  Emerald Heights International School
                </p>
                <button
                  className="readMoreIconBtn"
                  data-iframe-id="5"
                  onClick={handleVideoClick}
                >
                  <img
                    className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg"
                    alt="iconBtn"
                  />
                </button>
                {/* <a href="#" className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
            <img
              className="widthAuto readMoreIconBtn"
              src="assets/images/homeImage/arrowWhite.svg"
              alt="iconBtn"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleReadMoreClick}
            />
          </a> */}
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">
                Mayo College LAB Aftermovie
              </h4>
              <video
                data-iframe-id="6"
                className="progVideos video"
                muted
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleVideoClick}
                playsInline
              >
                <source
                  src="https://musite.b-cdn.net/mayo_girls.mp4"
                  type="video/mp4"
                />
              </video>

              {shownIframe === "6" && (
                <iframe
                  className="video-iframe"
                  data-iframe-id="6"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/NWeAlnmTfq8?si=nxq9bchKRPpL68IH" // Added autoplay=1
                  title="YouTube video player 6"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}

              <div className="progVedioContent">
                <p className="fs-25 fw-400">
                  MU20 School of Opportunity | Leadership & Adventure Bootcamp |
                  Mayo College Girls' School
                </p>
                <button
                  className="readMoreIconBtn"
                  data-iframe-id="6"
                  onClick={handleVideoClick}
                >
                  <img
                    className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg"
                    alt="iconBtn"
                  />
                </button>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">
                MU20 Summit Aftermovie
              </h4>
              <video
                data-iframe-id="7"
                className="progVideos video"
                muted
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleVideoClick}
                playsInline
              >
                <source
                  src="https://musite.b-cdn.net/summitpillarpage.mp4"
                  type="video/mp4"
                />
              </video>
              {shownIframe === "7" && (
                <iframe
                  className="video-iframe"
                  data-iframe-id="7"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/yL1OyZ3-PYY?si=KYrxMvXOWBRxIcljs" // Corrected URL
                  title="YouTube video player 1"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}

              <div className="progVedioContent">
                <p className="fs-25 fw-400">
                  MU20 High School Summit | Asia's Biggest High School
                  Leadership Conference | Aftermovie
                </p>
                <button
                  className="readMoreIconBtn"
                  data-iframe-id="1"
                  onClick={handleVideoClick}
                >
                  <img
                    className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg"
                    alt="iconBtn"
                  />
                </button>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">
                Pathways Aftermovie
              </h4>
              <video
                data-iframe-id="8"
                className="progVideos video"
                muted
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleVideoClick}
                playsInline
                poster="assets/images/residential_slider_thumbnail.png"
              >
                <source
                  src="https://musite.b-cdn.net/videos/pathwaysm_Main_Page.mp4"
                  type="video/mp4"
                />
              </video>

              {shownIframe === "8" && (
                <iframe
                  className="video-iframe"
                  data-iframe-id="8"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/rnEspqnT68k?si=6MdzW69ArLUdCPzl" // Corrected URL
                  title="YouTube video player 2"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}

              <div className="progVedioContent">
                <p className="fs-25 fw-400">
                  MU20 Pathways Residential Programme 2024 | Pathways World
                  School | Aftermovie
                </p>
                <button
                  className="readMoreIconBtn"
                  data-iframe-id="2"
                  onClick={handleVideoClick}
                >
                  <img
                    className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg"
                    alt="iconBtn"
                  />
                </button>
                {/* <a href="#" className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
            <img
              className="widthAuto readMoreIconBtn"
              src="assets/images/homeImage/arrowWhite.svg"
              alt="iconBtn"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleReadMoreClick}
            />
          </a> */}
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">
                MGD LAB Aftermovie
              </h4>
              <video
                data-iframe-id="9"
                className="progVideos video"
                muted
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleVideoClick}
                playsInline
              >
                <source
                  src="https://musite.b-cdn.net/mgd_chants.mp4"
                  type="video/mp4"
                />
              </video>

              {shownIframe === "9" && (
                <iframe
                  className="video-iframe"
                  data-iframe-id="9"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/cjuBSu2Qtk0?si=nCdmzfaE9ASPHpdG" // Corrected URL
                  title="YouTube video player 3"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}

              <div className="progVedioContent">
                <p className="fs-25 fw-400">
                  MU20 School of Opportunity | Leadership & Adventure Bootcamp |
                  Maharani Gayatri Devi Girls' School
                </p>
                <button
                  className="readMoreIconBtn"
                  data-iframe-id="3"
                  onClick={handleVideoClick}
                >
                  <img
                    className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg"
                    alt="iconBtn"
                  />
                </button>
                {/* <a href="#" className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
            <img
              className="widthAuto readMoreIconBtn"
              src="assets/images/homeImage/arrowWhite.svg"
              alt="iconBtn"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleReadMoreClick}
            />
          </a> */}
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">
                Doon LAB Aftermovie
              </h4>
              <video
                data-iframe-id="10"
                className="progVideos video"
                muted
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleVideoClick}
                playsInline
              >
                <source
                  src="https://musite.b-cdn.net/videos/doon.mp4"
                  type="video/mp4"
                />
              </video>

              {shownIframe === "10" && (
                <iframe
                  className="video-iframe"
                  data-iframe-id="10"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/3ylVNxFkU6A?si=K_UhHRUW4TNZjUJG" // Corrected URL
                  title="YouTube video player 4"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}

              <div className="progVedioContent">
                <p className="fs-25 fw-400">
                  Leadership and Adventure Bootcamp | The Doon School, Dehradun
                  Cohort | Aftermovie
                </p>
                <button
                  className="readMoreIconBtn"
                  data-iframe-id="4"
                  onClick={handleVideoClick}
                >
                  <img
                    className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg"
                    alt="iconBtn"
                  />
                </button>
                {/* <a href="#" className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
            <img
              className="widthAuto readMoreIconBtn"
              src="assets/images/homeImage/arrowWhite.svg"
              alt="iconBtn"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleReadMoreClick}
            />
          </a> */}
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">
                EHIS LAB Aftermovie
              </h4>
              <video
                data-iframe-id="11"
                className="progVideos video"
                muted
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleVideoClick}
                playsInline
              >
                <source
                  src="https://musite.b-cdn.net/videos/emerald.mp4"
                  type="video/mp4"
                />
              </video>

              {shownIframe === "11" && (
                <iframe
                  className="video-iframe"
                  data-iframe-id="11"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/MmFXW0yUxNw?si=vz7D1B3ThEQeUb2d" // Added autoplay=1
                  title="YouTube video player 5"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}

              <div className="progVedioContent">
                <p className="fs-25 fw-400">
                  MU20 School of Opportunity | Leadership & Adventure Bootcamp |
                  Emerald Heights International School
                </p>
                <button
                  className="readMoreIconBtn"
                  data-iframe-id="5"
                  onClick={handleVideoClick}
                >
                  <img
                    className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg"
                    alt="iconBtn"
                  />
                </button>
                {/* <a href="#" className="readMoreIconBtn" data-iframe-id="1" onClick={handleVideoClick}>
            <img
              className="widthAuto readMoreIconBtn"
              src="assets/images/homeImage/arrowWhite.svg"
              alt="iconBtn"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleReadMoreClick}
            />
          </a> */}
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">
                Mayo College LAB Aftermovie
              </h4>
              <video
                data-iframe-id="12"
                className="progVideos video"
                muted
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleVideoClick}
                playsInline
              >
                <source
                  src="https://musite.b-cdn.net/mayo_girls.mp4"
                  type="video/mp4"
                />
              </video>

              {shownIframe === "12" && (
                <iframe
                  className="video-iframe"
                  data-iframe-id="12"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/NWeAlnmTfq8?si=nxq9bchKRPpL68IH" // Added autoplay=1
                  title="YouTube video player 6"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}

              <div className="progVedioContent">
                <p className="fs-25 fw-400">
                  MU20 School of Opportunity | Leadership & Adventure Bootcamp |
                  Mayo College Girls' School
                </p>
                <button
                  className="readMoreIconBtn"
                  data-iframe-id="6"
                  onClick={handleVideoClick}
                >
                  <img
                    className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg"
                    alt="iconBtn"
                  />
                </button>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>

      <section className="p-80-20 bglightBlack skillSec">
        <div className="topHeading">
          <h6
            className="fs-22 fw-600 textOrg pb-10 textUpper"
            data-aos="fade-up"
          >
            Why choose US
          </h6>
          <h2 className="fs-80 fw-600 textWhite pb-20" data-aos="fade-up">
            Skills You Learn
          </h2>
          {/* <p className="subContent textWhite">Building the worlds best marketing Your trusted partner for strategy,
            design, and dev</p> */}
        </div>
        <div className="skills-outer">
          <div className="skills" data-aos="fade-up">
            <img src="assets/images/pillarimage/mastery.jpg" alt="skillImg" />
            <h3 className="fs-25 fw-600 pb-20" data-aos="fade-up">
              Mastery Through Hands-On Learning facilitating{" "}
              <b> Technical Skills </b>
            </h3>
            <ul className="skillList" data-aos="fade-up">
              <li className="fs-16 fw-400">
                {" "}
                Immersive workshops using the latest technologies.
              </li>
              <li className="fs-16 fw-400">
                {" "}
                Real-world applications for problem-solving.
              </li>
              <li className="fs-16 fw-400">
                {" "}
                Practical experience with cutting-edge tools.
              </li>
            </ul>
          </div>

          <div className="skills" data-aos="fade-up">
            <img src="assets/images/pillarimage/inovate.jpg" alt="skillImg" />
            <h3 className="fs-25 fw-600 pb-20" data-aos="fade-up">
              Innovate, Strategize, and Solve Complex Problems through{" "}
              <b>Conceptual Skills</b>
            </h3>
            <ul className="skillList" data-aos="fade-up">
              <li className="fs-16 fw-400">
                Encouraging strategic thinking and innovation.
              </li>
              <li className="fs-16 fw-400">
                {" "}
                Interdisciplinary approach to connect concepts.
              </li>
              <li className="fs-16 fw-400">
                Real-world challenges to foster critical thinking.
              </li>
            </ul>
          </div>

          <div className="skills" data-aos="fade-up">
            <img
              src="assets/images/skills_to_learn/skill_you_learn2.jpg"
              alt="skillImg"
            />

            <h3 className="fs-25 fw-600 pb-20" data-aos="fade-up">
              Building Effective Leaders and Teams through <b>Human Skills</b>{" "}
            </h3>
            <ul className="skillList" data-aos="fade-up">
              <li className="fs-16 fw-400">
                {" "}
                Collaborative projects with global peers.
              </li>
              <li className="fs-16 fw-400">
                Leadership exercises to enhance communication.
              </li>
              <li className="fs-16 fw-400">
                Developing empathy and interpersonal skills.
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="p-80-20 bgBlack intreactionSec">
        <Swiper
          modules={[Autoplay, Navigation, A11y, EffectFade]}
          spaceBetween={0}
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,

            pauseOnMouseEnter: false,
          }}
          centeredSlides={true}
          effect="fade"
          navigation={true}
        >
          <SwiperSlide>
            <div className="intreactionVideo">
              {/* <video className='video' muted playsInline>
              <source src="https://musite.b-cdn.net/labmainpage.mp4" type="video/mp4" />
            </video> */}

              <img
                src="assets/images/pillarimage/student_trained.jpeg"
                alt=""
              />
              <div className="intreactionInner">
                <h2 className="fs-80 fw-700 textWhite" data-aos="fade-up">
                  1,50,000{" "}
                </h2>
                <p className="fs-34 fw-700 textWhite" data-aos="fade-up">
                  students trained
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="intreactionVideo">
              {/* <video className='video' muted playsInline>
              <source src="https://musite.b-cdn.net/labmainpage.mp4" type="video/mp4" />
            </video> */}
              <img src="assets/images/pillarimage/150+_schools.jpg" alt="" />
              <div className="intreactionInner">
                <h2 className="fs-80 fw-700 textWhite" data-aos="fade-up">
                  150+
                </h2>
                <p className="fs-34 fw-700 textWhite" data-aos="fade-up">
                  {" "}
                  schools in our network
                </p>
              </div>
            </div>
          </SwiperSlide>

          {/* <SwiperSlide>
          <div className="intreactionVideo">
           

<img src="assets/images/30+img.jpg" alt="" />
            <div className="intreactionInner">
              <h2 className="fs-80 fw-700 textWhite">30+ </h2>
              <p className="fs-34 fw-700 textWhite">industry partners</p>
            </div>
          </div>
        </SwiperSlide> */}
          <SwiperSlide>
            <div className="intreactionVideo">
              {/* <video className='video' muted playsInline>
              <source src="https://musite.b-cdn.net/labmainpage.mp4" type="video/mp4" />
            </video> */}

              <img src="assets/images/pillarimage/asia_biggest.jpg" alt="" />
              <div className="intreactionInner">
                <h2 className="fs-80 fw-700 textWhite" data-aos="fade-up">
                  Asia's Biggest{" "}
                </h2>
                <p className="fs-34 fw-700 textWhite" data-aos="fade-up">
                  High School Leadership Conference{" "}
                </p>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="intreactionVideo">
              {/* <video className='video' muted playsInline>
              <source src="https://musite.b-cdn.net/labmainpage.mp4" type="video/mp4" />
            </video> */}
              <div className="intreactionInner">
                <h2 className="fs-80 fw-700 textWhite" data-aos="fade-up">
                  15+{" "}
                </h2>
                <p className="fs-34 fw-700 textWhite" data-aos="fade-up">
                  {" "}
                  programs annually
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="intreactionVideo">
              {/* <video className='video' muted playsInline>
              <source src="https://musite.b-cdn.net/labmainpage.mp4" type="video/mp4" />
            </video> */}

              <img src="assets/images/rupee.jpg" alt="" />
              <div className="intreactionInner">
                <h2 className="fs-80 fw-700 textWhite" data-aos="fade-up">
                  10 lakh rupees+
                </h2>
                <p className="fs-34 fw-700 textWhite" data-aos="fade-up">
                  {" "}
                  given away in scholarships
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>

      {/* <section className="p-80-20  bgBlack intreactionSec">
        <img src="assets/images/reviewImg.png" alt="home-img" />
        <div className="intreactionInner">
          <h2 className="fs-80 fw-700 textWhite">250K+ </h2>
          <p className="fs-34 fw-700 textWhite">Student Interactions</p>
        </div>
      </section> */}

      <section className="bgBlack testimonialSec testimonialHomeSec">
        <div className="container-full">
          <div className="topHeading">
            {/* <h6 className="fs-22 fw-600 textOrg pb-10 textUpper">clients</h6> */}
            <h2 className="fs-80 fw-600 textWhite" data-aos="fade-up">
              Testimonials
            </h2>
          </div>

          <Swiper
            breakpoints={{
              1440: {
                spaceBetween: 40,
                slidesPerView: 4,
                centeredSlides: false,
              },
              768: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
              200: {
                spaceBetween: 20,
                slidesPerView: 1,
                autoplay: false,
              },
            }}
            modules={[Autoplay, A11y]}
            spaceBetween={50}
            slidesPerView={5}
            loop={true}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            centeredSlides={true}
          >
            <SwiperSlide>
              <div className="testimonialImg">
                <video
                  className="testimonialVideo video"
                  preload="auto"
                  muted
                  onMouseEnter={handleMouseEnterTestimonials}
                  onMouseLeave={handleMouseLeaveTesimonials}
                  playsInline
                  controls
                >
                  <source
                    src="https://musite.b-cdn.net/LABwebtest1.mp4"
                    type="video/mp4"
                  />
                </video>

                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt="home-img" />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="testimonialImg">
                <video
                  className="testimonialVideo video"
                  controls
                  preload="auto"
                  muted
                  onMouseEnter={handleMouseEnterTestimonials}
                  onMouseLeave={handleMouseLeaveTesimonials}
                  playsInline
                >
                  <source
                    src="https://musite.b-cdn.net/LABwebtest3.mp4"
                    type="video/mp4"
                  />
                </video>

                {/* <img src="assets/images/testimonial3.png" alt='home-img' /> */}
                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt="home-img" />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="testimonialImg">
                <video
                  className="testimonialVideo video"
                  controls
                  preload="auto"
                  muted
                  onMouseEnter={handleMouseEnterTestimonials}
                  onMouseLeave={handleMouseLeaveTesimonials}
                  playsInline
                >
                  <source
                    src="https://musite.b-cdn.net/LABwebtest2.mp4"
                    type="video/mp4"
                  />
                </video>

                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt="home-img" />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="testimonialImg">
                <video
                  className="testimonialVideo video"
                  preload="auto"
                  muted
                  onMouseEnter={handleMouseEnterTestimonials}
                  onMouseLeave={handleMouseLeaveTesimonials}
                  playsInline
                  controls
                >
                  <source
                    src="https://musite.b-cdn.net/YLPwebtest1.mp4"
                    type="video/mp4"
                  />
                </video>

                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt="home-img" />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>

            {/* <SwiperSlide>

<div className="testimonialImg">
<video className="testimonialVideo video" controls preload="auto" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
  <source src="https://musite.b-cdn.net/LABwebtest2.mp4" type="video/mp4" />

</video>

  <div className="textWrapper">
    <img src="/assets/images/videoSec.svg" alt='home-img' />
    <h6 className="titleWatch">Watch Story</h6>
  </div>
</div>

</SwiperSlide> */}

            <source
              src="https://musite.b-cdn.net/YLPwebtest2.mp4"
              type="video/mp4"
            />
            {/* <SwiperSlide>

                <div className="testimonialImg">
                <video className="testimonialVideo video" controls preload="auto" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://musite.b-cdn.net/videos/summit_web_test.mp4" type="video/mp4" />
                
                </video>

                  <div className="textWrapper">
                    <img src="/assets/images/videoSec.svg" alt='home-img' />
                    <h6 className="titleWatch">Watch Story</h6>
                  </div>
                </div>

        </SwiperSlide> */}

            <SwiperSlide>
              <div className="testimonialImg">
                <video
                  className="testimonialVideo video"
                  controls
                  preload="auto"
                  muted
                  onMouseEnter={handleMouseEnterTestimonials}
                  onMouseLeave={handleMouseLeaveTesimonials}
                  playsInline
                >
                  <source
                    src="https://musite.b-cdn.net/YLPwebtest2.mp4"
                    type="video/mp4"
                  />
                </video>

                {/* <img src="assets/images/testimonial3.png" alt='home-img' /> */}
                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt="home-img" />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="testimonialImg">
                <video
                  className="testimonialVideo video"
                  controls
                  preload="auto"
                  muted
                  onMouseEnter={handleMouseEnterTestimonials}
                  onMouseLeave={handleMouseLeaveTesimonials}
                  playsInline
                >
                  <source
                    src="https://musite.b-cdn.net/YLPWebtest3.mp4"
                    type="video/mp4"
                  />
                </video>

                {/* <img src="assets/images/testimonial3.png" alt='home-img' /> */}
                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt="home-img" />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="testimonialImg">
                <video
                  className="testimonialVideo video"
                  controls
                  preload="auto"
                  muted
                  onMouseEnter={handleMouseEnterTestimonials}
                  onMouseLeave={handleMouseLeaveTesimonials}
                  playsInline
                >
                  <source
                    src="https://musite.b-cdn.net/videos/summit_web_test.mp4"
                    type="video/mp4"
                  />
                </video>

                {/* <img src="assets/images/testimonial3.png" alt='home-img' /> */}
                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt="home-img" />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      <section className="bgBlack partnerSec partnerHomeSec ">
        <div className=" container ">
          <div className="topHeading">
            {/* <h6 className="fs-22 fw-600 textOrg pb-10 textUpper">community</h6> */}
            <h2 className="fs-80 fw-600 textWhite" data-aos="fade-up">
              Partners
            </h2>
          </div>

          <div className="partners_logo_home" data-aos="fade-up">
            <Swiper
              modules={[Navigation, Autoplay, A11y, Keyboard]}
              spaceBetween={30}
              slidesPerView={7}
              cssMode={false}
              navigation={false}
              loop={true}
              autoplay={{
                delay: 1500,
                disableOnInteraction: true,
                pauseOnMouseEnter: true,
              }}
              keyboard={{
                enabled: true,
                onlyInViewport: true,
              }}
              breakpoints={{
                200: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                  autoplay: false,
                },
                380: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                450: {
                  slidesPerView: 5,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
                1440: {
                  slidesPerView: 7,
                  spaceBetween: 10,
                },
              }}
            >
              <SwiperSlide>
                <img
                  src="assets/images/partners_logos/afs.png"
                  alt="networkSch"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/images/partners_logos/boardng_school.png"
                  alt="networkSch"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/images/partners_logos/map.png"
                  alt="networkSch"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/images/partners_logos/harvard.png"
                  alt="networkSch"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/images/partners_logos/oxford.png"
                  alt="networkSch"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/images/partners_logos/joshtalk.png"
                  alt="networkSch"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/images/partners_logos/actor_prepare.png"
                  alt="networkSch"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/images/partners_logos/allen.png"
                  alt="networkSch"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/images/partners_logos/flame.png"
                  alt="networkSch"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/images/partners_logos/nmims.png"
                  alt="networkSch"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/images/partners_logos/symbiosis.png"
                  alt="networkSch"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/images/partners_logos/hol.png"
                  alt="networkSch"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/images/partners_logos/afs.png"
                  alt="networkSch"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/images/partners_logos/boardng_school.png"
                  alt="networkSch"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/images/partners_logos/map.png"
                  alt="networkSch"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/images/partners_logos/harvard.png"
                  alt="networkSch"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/images/partners_logos/oxford.png"
                  alt="networkSch"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/images/partners_logos/joshtalk.png"
                  alt="networkSch"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/images/partners_logos/actor_prepare.png"
                  alt="networkSch"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/images/partners_logos/allen.png"
                  alt="networkSch"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/images/partners_logos/flame.png"
                  alt="networkSch"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/images/partners_logos/nmims.png"
                  alt="networkSch"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/images/partners_logos/symbiosis.png"
                  alt="networkSch"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/images/partners_logos/hol.png"
                  alt="networkSch"
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default HomeNew;
