import "./App.css";
import HomeNew from "./components/Home/HomeNew";
import { Routes, Route } from "react-router-dom";
import OxfordMun from "./components/Home/OxfordMun";
import Community from "./components/Home/Community";
import Flagship from "./components/Home/Flagship";
import Residential from "./components/Home/Residential";
import Bootcamp from "./components/Home/Bootcamp";
import NetworkSchool from "./components/Home/NetworkSchool";
import Pillar from "./components/Home/Pillar";
import Summits from "./components/Home/Summits";
import Bootcamp_pillar from "./components/Home/Bootcamp_pillar";
import OnlineChallenges from "./components/Home/OnlineChallenges";
import Residential_program from "./components/Home/Residential_program";
import MU20OpportunitySummit24 from "./components/Home/MU20OpportunitySummit24";
import Leadership_and_Adventure_Bootcamp from "./components/Home/LeadershipandAdventureBootcamp";
import Young_Leaders_Bootcamp from "./components/Home/YoungLeadersBootcamp";
import CrafttheFutureChallenge24 from "./components/Home/CrafttheFutureChallenge24";
import OxfordMUNPreChallenge from "./components/Home/OxfordMUN-Pre-Challenge";
import PopupForm from "./components/Home/PopupForm";
import VideoPage from "./components/Home/Video_test";
import FormData from "./components/Home/FormData";
import Hsis from "./components/Home/Hsis";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomeNew />} />
        <Route path="/oxfordmun" element={<OxfordMun />} />
        <Route path="/hsis" element={<Hsis />} />
        <Route path="/community" element={<Community />} />
        <Route path="/flagship" element={<Flagship />} />
        <Route path="/residential" element={<Residential />} />
        <Route path="/bootcamp" element={<Bootcamp />} />
        <Route path="/networkSchool" element={<NetworkSchool />} />
        <Route path="/pillar" element={<Pillar />} />
        <Route path="/summits" element={<Summits />} />
        <Route path="/bootcamp_pillar" element={<Bootcamp_pillar />} />
        <Route path="/online_challenges" element={<OnlineChallenges />} />
        <Route path="/Residential_program" element={<Residential_program />} />
        <Route
          path="/MU20OpportunitySummit24"
          element={<MU20OpportunitySummit24 />}
        />
        <Route
          path="/LeadershipandAdventureBootcamp"
          element={<Leadership_and_Adventure_Bootcamp />}
        />
        <Route
          path="/Young_Leaders_Bootcamp"
          element={<Young_Leaders_Bootcamp />}
        />
        <Route
          path="/CrafttheFutureChallenge24"
          element={<CrafttheFutureChallenge24 />}
        />
        <Route
          path="/OxfordMUNPreChallenge"
          element={<OxfordMUNPreChallenge />}
        />
        <Route path="/PopupForm" element={<PopupForm />} />
        <Route path="/videoPage" element={<VideoPage />} />
        <Route path="/formdata" element={<FormData />} />
      </Routes>
    </div>
  );
}

export default App;
