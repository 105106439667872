import React, { useState, useEffect } from 'react';
import './Home.css';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from 'axios'
import { Helmet } from 'react-helmet';


import PopupComponent from 'reactjs-popup'; // Rename the import to PopupComponent
import PopupForm from './PopupForm'; // Adjust the path as per your project structure


import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';


import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the AOS styles



// ----------------Swiper slider import------------------
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/css/effect-fade";

function OxfordMUNPreChallenge() {



    const [showPopup, setShowPopup] = useState(false);

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };




    const [isMuted, setIsMuted] = useState(true);
    const [isActive, setActive] = useState("false");
    const [payload, setPayload] = useState({
        person_name: "",
        email: "",
        enquiry: "",
        school_name: "",
        school_location: "",
        contact_number: "",
        message: ""
    })

    const handleChange = (e) => {
        let { value, name } = e.target
        let data = payload
        data = { ...data, [name]: value }
        setPayload(data)
    }






    useEffect(() => {
        AOS.init({
          duration: 1000, // Animation duration in milliseconds
        });
      }, []);


    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post('https://server.mu20.co/user', payload);
            console.log(response.data);

        }
        catch (error) {
            console.log('An error occurred:', error);

        }
    };





    const handleSelect = (e) => {
        let { value } = e.target
        let data = payload
        data = { ...data, 'enquiry': value }
        setPayload(data)

    }


    const ToggleClass = () => {
        setActive(!isActive);
    };
    return (
        <>

            <Helmet>
                <title>OxfordMUN Pre-Challenge - MU20 School Of Opportunity</title>
                <meta name="title" content="" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
            </Helmet>

            <Header />
            {/* <!-- banner section --> */}

            <div className="blue_theme">
                <section className="oxfordBanner">
                    <div>
                        <video className="sliderBgImage" autoPlay loop playsInline width="100%" muted={isMuted ? true : false}>
                            {/* <source src="assets/videos/OxfordVideo-Com.mp4" type="video/mp4" /> */}
                            <source src="https://musite.b-cdn.net/videos/oxfordVideo-Com.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <div className="banner-content">
                        <h1 data-aos="fade-up">OxfordMUN-Pre-Challenge </h1>
                        <div className={isActive ? "mute" : "unmute"}>
                            <a onClick={() => { setIsMuted(!isMuted); ToggleClass(); }} className="muteBtn widthAuto" id="btnUnMute"><img className="unmuteIcon" src="/assets/images/mute.svg" alt="unMuteIcon" />
                                <img className="muteIcon" src="/assets/images/unmute.svg" alt="MuteIcon" /></a>
                        </div>
                    </div>
                </section >
                {/* <!-- introduction --> */}
                < section className="intro-section communityIntro-section " >
                    <div className="container">
                        <div className="introduction">
                            <h2 className="secTitle textBlack" data-aos="fade-up">Introduction</h2>
                            <p data-aos="fade-up">The OxfordMUN Pre-Challenge, Powered by Allen Global, is a pioneering platform designed to discover and nurture the brightest young minds worldwide, focusing on diplomacy, public speaking, and policy-making. This challenge goes beyond a typical competition by offering students a unique opportunity to demonstrate their talents, deepen their understanding of international relations, and engage with complex global issues. Participants will be rigorously tested on their ability to think quickly, communicate effectively, and devise innovative solutions to real-world problems, preparing them for future leadership roles.</p>
                        </div>
                    </div>
                </section >
                {/* <!-- Why OxfordMUN India ? --> */}
                < section className="whyOxfordSec communityWhyOxfordSec" >
                    <div className="container">
                        <div className="oxford-mun">
                            <h2 className="secTitle " data-aos="fade-up">Why Join the OxfordMUN Pre-Challenge?</h2>
                        </div>
                        <div className="inner-box" data-aos="fade-up">
                            <div className="row">
                                <div className="column">
                                    <div className="oxford-box">
                                        <h3 className="textLOrg">Skill Development:</h3>
                                        <p className="textLOrg">Enhance your public speaking, critical thinking, policy analysis, and teamwork abilities. This challenge pushes you to improve your presentation skills and boosts your confidence in articulating ideas clearly and persuasively.</p>
                                    </div>
                                    <div className="oxford-box">
                                        <h3 className="textLOrg">Recognition:</h3>
                                        <p className="textLOrg">Gain recognition as a talented delegate and increase your chances of participating in the prestigious Oxford MUN India 2024. Your achievements in this challenge can significantly enhance your academic profile and open doors to future opportunities.</p>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="oxford-box">
                                        <h3 className="textLOrg">Networking:</h3>
                                        <p className="textLOrg">Connect with like-minded peers and professionals in the fields of international relations and diplomacy. Building these connections can provide you with mentorship opportunities and lifelong friendships that support your academic and career goals.
                                        </p>
                                    </div>
                                    <div className="oxford-box">
                                        <h3 className="textLOrg">Growth Opportunities:</h3>
                                        <p className="textLOrg">Challenge yourself to think creatively and develop innovative solutions to global issues. This experience fosters personal growth, resilience, and the ability to handle complex challenges, preparing you for future endeavors in leadership and diplomacy.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
                {/* <!-- Conference Details --> */}
                < section className="conference communityConference" >
                    <div className="container">
                        <div className="confere-detail">
                            <h2 className="secTitle textBlack" data-aos="fade-up">CHALLENGE DETAILS
                            </h2>

                            <div className="row" data-aos="fade-up">
                                <div className="column">
                                    <div className="confere-box">
                                        <h3 className="textBlack">Who</h3>
                                        <p>Students who have registered for OxfordMUN India 2024 </p>

                                    </div>
                                    <div className="confere-box m-0">
                                        <h3 className="textBlack">When and Where</h3>
                                        <p>The challenge will take place from
                                            <span className="bold "> 19th July-2nd August, 2024</span>
                                            through an
                                            <span className="bold"> online platform.</span>The submission of the
                                            <span className="bold"> third round</span>will be on  <span className="bold"> 9th August, 2024.</span>The <span className="bold"> finale </span>will be held at the venue, that is,  <span className="bold"> FLAME University,</span> on
                                            <span className="bold"> 10th August, 2024. </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="column confere-reg orgBg">
                                    <div className="confere-regInner">
                                        <h3>REGISTRATION: How to Get Started
                                        </h3>
                                        <ol className='Oxfordmunprechallenge_reg'>
                                            <p>
                                                <li><p><span>Register for the Challenge:</span> Sign up for the Oxford MUN Pre-Challenge and receive all necessary materials and guidelines.</p></li>
                                                <li><p><span>Prepare for Round 1:</span> Create a one-minute video showcasing 20 recent Indian news headlines.</p></li>
                                                <li><p><span>Stay Informed:</span> Keep up-to-date with current events and practice your public speaking and presentation skills.</p></li>
                                                <li><p><span>Form Teams for Round 2:</span> nalyze potential team members through the videos provided and form a team to craft a political manifesto.</p></li>
                                                <li><p><span>Develop Strategic Plans for Round 3:</span> Create a comprehensive five-year plan for an assigned ministry based on your Round 2 manifesto.</p></li>

                                            </p>
                                        </ol>
                                        <button className="registerBtn" onClick={togglePopup} >
                                            Enquire Now
                                        </button>

                                        {showPopup && (
                                            <Popup open={showPopup} closeOnDocumentClick onClose={togglePopup} modal nested>
                                                <PopupForm togglePopup={togglePopup} /> {/* Render the PopupForm component */}
                                            </Popup>
                                        )}
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >

              

                <section className="experienceSec p-80-20 ">
                    <div className="container-full" data-aos="fade-up">
                        <h2 className="secTitle textBlack" data-aos="fade-up">Things you experience</h2>

                        <Swiper
                            breakpoints={{
                                1440: {
                                    spaceBetween: 20,
                                    slidesPerView: 4, // Display 3 slides for larger screens


                                },
                                768: {
                                    spaceBetween: 20,
                                    slidesPerView: 'auto', // Display 2 slides on tablet


                                },
                                200: {
                                    spaceBetween: 20,
                                    slidesPerView: 'auto', // Show 1 slide for mobile

                                },
                            }}
                            modules={[Pagination, A11y, Autoplay]}
                            spaceBetween={20}

                            pagination={{ clickable: true }}
                            loop={true}
                            autoplay={{
                                delay: 1500,
                                disableOnInteraction: false,
                                pauseOnMouseEnter: true,
                            }}
                            centeredSlides={true} // Keep the active slide centered

                        >

                            <SwiperSlide>
                                <div className="experienceBox">
                                    <div className="experienceBox-inner pb-30 d-flex">
                                        <h5 className="fs-32 fw-600 textBlack">Enhanced Public Speaking</h5>
                                        <img className="widthAuto nonActive" src="/assets/images/oxford_mun_pre-challenge_icons/active_1.svg" alt="handOnWork" />
                                        <img className="widthAuto active" src="/assets/images/oxford_mun_pre-challenge_icons/non_active_1.svg" alt="handOnWork" />
                                    </div>
                                    <p className="fs-16 fw-400 textBlack">Develop the ability to express your thoughts clearly and confidently in front of an audience. Learn techniques to persuade and influence others through effective speech, and master the art of presenting information in a compelling and engaging manner.</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="experienceBox">
                                    <div className="experienceBox-inner pb-30 d-flex">
                                        <h5 className="fs-32 fw-600 textBlack">In-Depth Knowledge of Current Affairs</h5>
                                        <img className="widthAuto nonActive" src="/assets/images/oxford_mun_pre-challenge_icons/active_2.svg" alt="handOnWork" />
                                        <img className="widthAuto active" src="/assets/images/oxford_mun_pre-challenge_icons/non_active_2.svg" alt="handOnWork" />
                                    </div>
                                    <p className="fs-16 fw-400 textBlack">Stay informed about global events and understand their implications on international relations. Enhance your ability to critically analyze news and its impact on policy and diplomacy, broadening your perspective on global issues and cultural dynamics.</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="experienceBox">
                                    <div className="experienceBox-inner pb-30 d-flex">
                                        <h5 className="fs-32 fw-600 textBlack">Teamwork and Collaboration</h5>
                                        <img className="widthAuto nonActive" src="/assets/images/oxford_mun_pre-challenge_icons/active_3.svg" alt="handOnWork" />
                                        <img className="widthAuto active" src="/assets/images/oxford_mun_pre-challenge_icons/none_active_3.svg" alt="handOnWork" />
                                    </div>
                                    <p className="fs-16 fw-400 textBlack">Learn to work collaboratively with peers from diverse backgrounds, developing effective teamwork and leadership skills. Gain expertise in managing and resolving conflicts within a team setting, ensuring smooth and productive collaborations.</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="experienceBox">
                                    <div className="experienceBox-inner pb-30 d-flex">
                                        <h5 className="fs-32 fw-600 textBlack">Strategic Thinking</h5>
                                        <img className="widthAuto nonActive" src="/assets/images/oxford_mun_pre-challenge_icons/active_4.svg" alt="handOnWork" />
                                        <img className="widthAuto active" src="/assets/images/oxford_mun_pre-challenge_icons/non_active_4.svg" alt="handOnWork" />
                                    </div>
                                    <p className="fs-16 fw-400 textBlack">Cultivate the ability to think creatively and develop innovative solutions to real-world problems. Learn to create actionable and effective policies, enhancing your strategic planning skills for comprehensive, long-term initiatives.</p>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="experienceBox">
                                    <div className="experienceBox-inner pb-30 d-flex">
                                        <h5 className="fs-32 fw-600 textBlack">Leadership Qualities</h5>
                                        <img className="widthAuto nonActive" src="/assets/images/oxford_mun_pre-challenge_icons/active_5.svg" alt="handOnWork" />
                                        <img className="widthAuto active" src="/assets/images/oxford_mun_pre-challenge_icons/non_active_5.svg" alt="handOnWork" />
                                    </div>
                                    <p className="fs-16 fw-400 textBlack">Take the lead in projects and team activities, demonstrating proactive behavior and effective guidance. Improve your decision-making skills, making informed and impactful choices while motivating and inspiring your team to achieve common goals.</p>
                                </div>
                            </SwiperSlide>


                            <SwiperSlide>
                                <div className="experienceBox">
                                    <div className="experienceBox-inner pb-30 d-flex">
                                        <h5 className="fs-32 fw-600 textBlack">Enhanced Public Speaking</h5>
                                        <img className="widthAuto nonActive" src="/assets/images/oxford_mun_pre-challenge_icons/active_1.svg" alt="handOnWork" />
                                        <img className="widthAuto active" src="/assets/images/oxford_mun_pre-challenge_icons/non_active_1.svg" alt="handOnWork" />
                                    </div>
                                    <p className="fs-16 fw-400 textBlack">Develop the ability to express your thoughts clearly and confidently in front of an audience. Learn techniques to persuade and influence others through effective speech, and master the art of presenting information in a compelling and engaging manner.</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="experienceBox">
                                    <div className="experienceBox-inner pb-30 d-flex">
                                        <h5 className="fs-32 fw-600 textBlack">In-Depth Knowledge of Current Affairs</h5>
                                        <img className="widthAuto nonActive" src="/assets/images/oxford_mun_pre-challenge_icons/active_2.svg" alt="handOnWork" />
                                        <img className="widthAuto active" src="/assets/images/oxford_mun_pre-challenge_icons/non_active_2.svg" alt="handOnWork" />
                                    </div>
                                    <p className="fs-16 fw-400 textBlack">Stay informed about global events and understand their implications on international relations. Enhance your ability to critically analyze news and its impact on policy and diplomacy, broadening your perspective on global issues and cultural dynamics.</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="experienceBox">
                                    <div className="experienceBox-inner pb-30 d-flex">
                                        <h5 className="fs-32 fw-600 textBlack">Teamwork and Collaboration</h5>
                                        <img className="widthAuto nonActive" src="/assets/images/oxford_mun_pre-challenge_icons/active_3.svg" alt="handOnWork" />
                                        <img className="widthAuto active" src="/assets/images/oxford_mun_pre-challenge_icons/none_active_3.svg" alt="handOnWork" />
                                    </div>
                                    <p className="fs-16 fw-400 textBlack">Learn to work collaboratively with peers from diverse backgrounds, developing effective teamwork and leadership skills. Gain expertise in managing and resolving conflicts within a team setting, ensuring smooth and productive collaborations.</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="experienceBox">
                                    <div className="experienceBox-inner pb-30 d-flex">
                                        <h5 className="fs-32 fw-600 textBlack">Strategic Thinking</h5>
                                        <img className="widthAuto nonActive" src="/assets/images/oxford_mun_pre-challenge_icons/active_4.svg" alt="handOnWork" />
                                        <img className="widthAuto active" src="/assets/images/oxford_mun_pre-challenge_icons/non_active_4.svg" alt="handOnWork" />
                                    </div>
                                    <p className="fs-16 fw-400 textBlack">Cultivate the ability to think creatively and develop innovative solutions to real-world problems. Learn to create actionable and effective policies, enhancing your strategic planning skills for comprehensive, long-term initiatives.</p>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="experienceBox">
                                    <div className="experienceBox-inner pb-30 d-flex">
                                        <h5 className="fs-32 fw-600 textBlack">Leadership Qualities</h5>
                                        <img className="widthAuto nonActive" src="/assets/images/oxford_mun_pre-challenge_icons/active_5.svg" alt="handOnWork" />
                                        <img className="widthAuto active" src="/assets/images/oxford_mun_pre-challenge_icons/non_active_5.svg" alt="handOnWork" />
                                    </div>
                                    <p className="fs-16 fw-400 textBlack">Take the lead in projects and team activities, demonstrating proactive behavior and effective guidance. Improve your decision-making skills, making informed and impactful choices while motivating and inspiring your team to achieve common goals.</p>
                                </div>
                            </SwiperSlide>





                        </Swiper>
                    </div>
                </section>

                <section className="rewardSec p-80-20">
                    <div className="container rewards_container">
                        <h2 className="secTitle textBlack" data-aos="fade-up">Rewards</h2>
                        <div className="r" data-aos="fade-up">


                            <div className="rewardInner rewardInnerCenter">
                                <h5 className="textBlack fw-700 priceHeading">Prizes and Scholarships</h5>
                                <div className="innnerContent">
                                    <div className="innnerContentTop">
                                        <h6 className="fw-600">Full Scholarship </h6>
                                        <p className="fs-18 fw-400 ">The top five students at the end of the finale will receive a full scholarship, which will be a complete refund of their registration fees for Oxford MUN India 2024.</p>
                                    </div>
                                    <div className="innnerContentbotm">
                                        <h6 className="fw-600">MU20 Fellowship</h6>
                                        <p className="fs-14 fw-400">Students who qualify for the final round will advance to the interview stage for the esteemed MU20 Fellowship.</p>
                                    </div>
                                </div>
                                {/* <h5 className="textBlack fw-700 priceTitle">First Prize</h5> */}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="partnerSec communityPartnerSec  p-80-20">
                    <div className="container-full">
                        <h2 className="secTitle textBlack" data-aos="fade-up">Partners</h2>

                        <div className='mentors_logos' data-aos="fade-up">

                            <div className="partnerLogo">
                                <img src="/assets/images/allen_global.png" />
                            </div>

                            <div className="partnerLogo">
                                <img src="/assets/images/Oxford-mun_logo-black1.png" />
                            </div>

                            <div className="partnerLogo">
                                <img src="/assets/images/mu20_black_1.png" />
                            </div>
                        </div>



                      
                    </div>
                </section>

                <section className="callToAction pt-10 communityCallToAction">
                    <div className="container  " data-aos="fade-up">
                        <div className="  callToAction-inner">
                            <div className="row">
                                <div className="column callToActionLeft">
                                    <h5>Ready to make a <br />difference?</h5>
                                    <p>Register your school today to the OxfordMUN India 2024.</p>
                                </div>
                                <div className="column callToActionRight">
                                    <p>Be a part of the OxfordMUN Pre-Challenge and showcase your talents in diplomacy, public speaking, and policy-making. Join now to embark on an exciting journey of innovation, collaboration, and leadership. Take the first step towards making a meaningful impact on the global stage and shaping the future of international relations.</p>
                                    {/* <a href="https://bit.ly/CraftTheFutureChallengeBrochure" className="registerBtn" target="_blank">  Download Brochure </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section className="faqSec communityFaqSec ">

                <div className="container row faqInner">
                    <div className="faqInnerLeft">
                        <div className="faqInnerLeft-inner">
                            <img src="/assets/images/whitelogo@4x.png" alt="home-img" />
                            <p className="fs-18">Make a Difference with Us: Register Your School for OxfordMUN India 2024 Today!</p>
                            <p className="fs-18">Are you ready to embark on an extraordinary journey of discovery, diplomacy, and development? OxfordMUN India 2024 is inviting schools from across the globe to register and secure their spot in one of the most prestigious Model United Nations conferences. This is a unique opportunity for students to enhance their understanding of global issues, develop critical thinking skills, and engage in meaningful debates on world affairs.</p>
                          

                            <button className="registerBtn"  onClick={togglePopup} >
                            Register Now
                            </button>

                            {showPopup && (
                                <Popup open={showPopup} closeOnDocumentClick onClose={togglePopup} modal nested>
                                    <PopupForm togglePopup={togglePopup} /> 
                                </Popup>
                            )}

                            <p className="fs-18 p-0">Still Have a question?  No worries.</p>

                            <div className="contactDetail">
                                <a href="mailto:madhur.oza@muniversiti.org " ><img src="/assets/images/email.svg" alt="home-img" />madhur.oza@muniversiti.org </a>
                                <a href="tel:7987519367" ><img src="/assets/images/whatsapp.svg" alt="home-img" />7987519367</a>
                            </div>
                            <div className="socialLinks">

                                <a href=" https://www.instagram.com/mu20.world?igsh=MThkY3NkeGJ3bHNtaA==" target="_blank">
                                    <img src="/assets/images/insta.svg" alt="home-img" /></a>
                                <a href="https://www.linkedin.com/company/mu20/" target="_blank">
                                    <img src="/assets/images/linkdine.svg" alt="home-img" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="faqInnerRight">
                        <h2 className="secTitle textBlack">FAQs</h2>
                        <div class="faqBlock">
                            <Accordion allowZeroExpanded>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                                Where is the venue for OxfordMUN India?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p className="faqContent">FLAME University Pune</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                            What are the dates for OxfordMUN India?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>9th-11th August</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                            How can I register for OxfordMUN India?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Visit “Link” to secure your spot and embark on an unforgettable journey of Discovery, Diplomacy and Development.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                            What are the fees for participating in OxfordMUN India?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>- School Registration Fee: INR 5,000 (Includes one teacher representative without any additional charge)</p>
                                        <p> - Delegate Fee for MU20 Member Schools: INR 25,000 per delegate</p>
                                        <p> - Delegate Fee for Non-Member Schools: INR 30,000 per delegate</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                            Who can participate in OxfordMUN India?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Students from Grade 9th to 12th</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                            Will accommodation be provided for participants?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Yes, the participants and their teacher coordinators would be provided with accommodation at FLAMES University Pune.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                            How can I get to the venue from the airport/train station?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Convenient pickup and drop-off services from local stations and airports would be provided to participants.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                            Who can I contact for more information or assistance?</h3>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Madhur Oza <a href="tel:+91-7987519367"> +91-7987519367 </a>&nbsp;&nbsp;<a href="mailTo:madhur.oza@muniversiti.org">madhur.oza@muniversiti.org</a> </p>
                                        <p>Ashwin Samuel <a href="tel:+91-9424536621"> +91-9424536621 </a>&nbsp;&nbsp;<a href="mailTo:connect@mu20.co">connect@mu20.co</a>
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                            </Accordion>

                        </div>
                    </div>
                </div>
            </section> */}
            </div>

            <Footer />
        </>
    )
}

export default OxfordMUNPreChallenge


