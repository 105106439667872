import React, { useState, useEffect } from "react";

import "./Home.css";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import PopupForm from "./PopupForm"; // Adjust the path as per your project structure
import Popup from "reactjs-popup";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// import PureModal from 'react-pure-modal';
// import 'react-pure-modal/dist/react-pure-modal.min.css';

// ----------------Swiper slider import------------------
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectFade,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css/effect-fade";
// import Swiper from 'swiper/bundle';
// import 'swiper/css/bundle';

// ----------------Gallery slider import------------------
import ImageGallery from "react-image-gallery";
import { ModalProvider } from "styled-react-modal";
import { Button, Modal } from "antd";
import RegisterModal from "../Popup/RegisterModal";

import AOS from "aos";
import "aos/dist/aos.css"; // Import the AOS styles

//--------------------Faq Accodian------------------
// ----------------Gallery slider start------------------
const images = [
  {
    original: "/assets/images/venueSpotImg.png",
    thumbnail: "/assets/images/venueSpotImg.png",
  },
  {
    original: "/assets/images/Flames1.png",
    thumbnail: "/assets/images/Flames1.png",
  },
  {
    original: "/assets/images/Flames2.png",
    thumbnail: "/assets/images/Flames2.png",
  },
  {
    original: "/assets/images/Flames3.png",
    thumbnail: "/assets/images/Flames3.png",
  },
];
// ----------------Gallery slider end------------------

function OxfordMun() {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const [isMuted, setIsMuted] = useState(true);
  const [isActive, setActive] = useState("false");

  const ToggleClass = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    document.title = "OxfordMun India - MU20 School Of Opportunity"
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
    });
  }, []);
  // const [isModalOpen, setIsModalOpen] = useState(false);

  // const showModal = () => {
  //     setIsModalOpen(true);
  // };
  // const handleOk = () => {
  //     setIsModalOpen(false);
  // };
  // const handleCancel = () => {
  //     setIsModalOpen(false);
  // };
  return (
    <>
      <Header />
      {/* <!-- banner section --> */}
      <section className="oxfordBanner">
        {/* <div style={{ paddingBottom: "56.25%", maxWidth: "100%", position: "relative" }}><iframe src="https://player.vimeo.com/video/347119375?autoplay=1&loop=1&muted=1&title=0&portrait=0&byline=0&keyboard=0" width="800px" height="450px" style={{ position: " absolute", top: "0px", left: "0px", width: "100%", height: "100%" }} frameBorder="0"></iframe></div> */}
        <div>
          <video
            className="sliderBgImage"
            autoPlay
            loop
            playsInline
            width="100%"
            muted={isMuted ? true : false}
          >
            {/* <source src="assets/videos/OxfordVideo-Com.mp4" type="video/mp4" /> */}
            <source
              src="https://musite.b-cdn.net/videos/OxfordMUN%20India%202025%20-%20Launch%20Trailer%20FInal%20Cut.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="banner-content">
          <h1 data-aos="fade-up">OxfordMUN India 2025</h1>
          <div className={isActive ? "mute" : "unmute"}>
            <a
              onClick={() => {
                setIsMuted(!isMuted);
                ToggleClass();
              }}
              className="muteBtn widthAuto"
              id="btnUnMute"
            >
              <img
                className="unmuteIcon"
                src=" /assets/images/mute.svg"
                alt="unMuteIcon"
              />
              <img
                className="muteIcon"
                src="/assets/images/unmute.svg"
                alt="MuteIcon"
              />
            </a>
          </div>
        </div>
      </section>
      {/* <!-- introduction --> */}
      <section className="intro-section">
        <div className="container">
          <div className="introduction">
            <h2 className="secTitle" data-aos="fade-up">
              Introduction
            </h2>
            <p data-aos="fade-up">
              OxfordMUN, a global leader in diplomatic simulations, returns to
              India for its second edition at FLAME University, Pune, from 4th
              to 6th August 2025. Led by the MU20 School of Opportunity, this
              prestigious conference seamlessly blends Oxford’s tradition of
              academic excellence with India’s dynamic cultural diversity,
              offering delegates a transformative experience in diplomacy,
              debate, and leadership.
            </p>
          </div>
        </div>
      </section>
      {/* <!-- Why OxfordMUN India ? --> */}
      <section className="whyOxfordSec">
        <div className="container">
          <div className="oxford-mun">
            <h2 className="secTitle" data-aos="fade-up">
              Why OxfordMUN India ?
            </h2>
          </div>
          <div className="inner-box" data-aos="fade-up">
            <div className="row">
              <div className="column">
                <div className="oxford-box">
                  <h3>Legacy Meets Opportunity</h3>
                  <p>
                    The second edition of OxfordMUN India 2025 offers high
                    school students (Classes 9th to 12th) the chance to be part
                    of a globally recognized platform for debate, diplomacy, and
                    leadership. Building on the success of its inaugural
                    edition, this conference continues to bridge Oxford’s
                    esteemed MUN tradition with India’s emerging young leaders,
                    shaping the policymakers of tomorrow.
                  </p>
                </div>
                <div className="oxford-box">
                  <h3>Empowerment Through Engagement</h3>
                  <p>
                    With committee sizes limited to 70 delegates, OxfordMUN
                    India ensures meaningful interaction, fostering a conducive
                    environment for every participant to contribute effectively.
                  </p>
                </div>
              </div>
              <div className="column">
                <div className="oxford-box">
                  <h3>Comprehensive Learning Experience</h3>
                  <p>
                    OxfordMUN India 2025 goes beyond debate, offering a holistic
                    learning experience that sharpens skills in diplomacy,
                    critical thinking, and leadership. Delegates will engage in
                    expert-led training sessions, gain insights from
                    distinguished speakers in Power Talks, and immerse
                    themselves in cultural and social events like the Masquerade
                    Eve and Global Village Gala, making their journey both
                    intellectually enriching and unforgettable.
                  </p>
                </div>
                <div className="oxford-box">
                  <h3>Recognition for the best delegates</h3>
                  <p>
                    The Best Delegates will be awarded a 100% scholarship on the
                    registration fee to participate in OxfordMUN at Oxford,
                    offering them international exposure and the chance to
                    further their diplomatic engagement on a global stage.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Conference Details --> */}
      <section className="conference">
        <div className="container" data-aos="fade-up">
          <div className="confere-detail">
            <h2 className="secTitle" data-aos="fade-up">
              Conference Details
            </h2>

            <div className="row">
              <div className="column">
                <div className="confere-box">
                  <h3>Who</h3>
                  <p>Students studying in classes </p>
                  <span className="bgColorText">9th to 12th can register.</span>
                </div>
                <div className="confere-box m-0">
                  <h3>When and Where</h3>
                  <span className="bgColorText">
                    4th August 2025 - 5th August 2025 -
                  </span>

                  <p>
                    <span className="bgColorText"> 6th August 2025</span> at
                    FLAME University, Pune
                  </p>
                </div>
              </div>
              <div className="column confere-reg">
                <div className="confere-regInner">
                  <h3>Registration</h3>
                  <p>
                    To express your interest in joining, schools are requested
                    to register by <span>21st April 2025.</span> (Phase 1) and
                    final registration deadlines will be{" "}
                    <span>20th July 2025.</span> (Phase 2)
                  </p>

                  <a href="https://forms.gle/iGD6K3SCNNK8SGYb6" target="_blank">
                    <button
                      className="org_btn  registerBtn joinbtn"
                      // onClick={togglePopup}
                    >
                      Register Now
                    </button>
                  </a>

                  {/* {showPopup && (
                    <Popup
                      open={showPopup}
                      closeOnDocumentClick
                      onClose={togglePopup}
                      modal
                      nested
                    >
                      <PopupForm togglePopup={togglePopup} />
                    </Popup>
                  )} */}

                  {/* <a href="https://bit.ly/OxfordMUNRegistration" className="registerBtn" target="_blank"> </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="rewardSec p-80-20">
        <div className="container" data-aos="fade-up">
          <h2 className="secTitle textBlack" data-aos="fade-up">
            Compete and Win
          </h2>
          <div className="reward-outer reward-outer-hsis">
            <div className="rewardInner rewardInnerCenter">
              <h5 className="fw-700 priceHeading priceHeading-hsis priceHeading-oxford">
                Best Delegates
              </h5>
              <div className="innnerContent innnerContent-hsis innnerContent-oxford">
                <div className="innnerContentTop">
                  <p className="fs-18 fw-400 ">
                    The Best Delegate will be awarded a 100% scholarship on the
                    registration fee to participate in OxfordMUN at Oxford,
                    offering them international exposure and the chance to
                    further their diplomatic engagement on a global stage.
                  </p>
                </div>
              </div>
              <h5 className="fw-700 priceTitle priceTitle-oxford">
                100% Scholarship
              </h5>
            </div>

            <div className="rewardInner rewardInnerCenter">
              <h5 className="fw-700 priceHeading  priceHeading-hsis priceHeading-oxford">
                Best Performers
              </h5>
              <div className="innnerContent innnerContent-hsis innnerContent-oxford">
                <div className="innnerContentTop">
                  <p className="fs-18 fw-400 ">
                    Win and secure a 100% scholarship covering their OxfordMUN
                    India 2025 fee. Through this pre-conference innovation
                    challenge, unlock the chance to tackle real-world
                    sustainability challenges with other participants from the
                    conference.
                  </p>
                </div>
              </div>
              <h5 className="fw-700 priceTitle priceTitle-oxford">
                100% Scholarship
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section className="whyOxfordSec eventSec">
        <div className="container" data-aos="fade-up">
          <div className="oxford-mun">
            <h2 className="secTitle" data-aos="fade-up">
              Events
            </h2>
          </div>
          <Swiper
            breakpoints={{
              1440: {
                spaceBetween: 50,
                slidesPerView: 3,
              },
              768: {
                spaceBetween: 40,
                slidesPerView: 3,
                centeredSlides: false,
              },
              200: {
                spaceBetween: 20,
                slidesPerView: 1,
              },
            }}
            // install Swiper modules
            modules={[Navigation, Autoplay, A11y]}
            spaceBetween={60}
            slidesPerView={3}
            navigation
            loop={true}
            autoplay={false}
            centeredSlides={true}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log("slide change")}
          >
            <SwiperSlide>
              <div className="eventBox">
                <img
                  src="/assets/images/oxfordMun/PowerTalks.png"
                  alt="eventImg"
                />
                <div className="eventContentBox">
                  {/* <h6 className="eventDate">28<span>March</span></h6> */}
                  <h5 className="eventTitle">Power Talks</h5>
                  <p>
                    Engage with visionary leaders and experts in global affairs,
                    gaining invaluable insights into diplomacy, leadership, and
                    policymaking.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
                            <div className="eventBox">
                                <img src="/assets/images/ScholarsEventR-min.jpg" alt='eventImg' />
                                <div className="eventContentBox">
                                    <h6 className="eventDate">28<span>March</span></h6>
                                    <h5 className="eventTitle">OxfordMUN Innovation Challenges</h5>
                                    <p>Kickstart your MUN journey with unique challenges, enhancing team dynamics and critical
                                        thinking.</p>
                                </div>
                            </div>
                        </SwiperSlide> */}
            <SwiperSlide>
              <div className="eventBox">
                <img
                  src="/assets/images/oxfordMun/Masqueradeball.JPG"
                  alt="eventImg"
                />
                <div className="eventContentBox">
                  {/* <h6 className="eventDate">28<span>March</span></h6> */}
                  <h5 className="eventTitle">Masquerade Eve</h5>
                  <p>
                    An evening of intrigue and sophistication, where delegates
                    connect, celebrate, and unwind in an atmosphere of mystery
                    and elegance.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="eventBox">
                <img
                  src="/assets/images/oxfordMun/Globalgala.png"
                  alt="eventImg"
                />
                <div className="eventContentBox">
                  {/* <h6 className="eventDate">28<span>March</span></h6> */}
                  <h5 className="eventTitle">Global Village Gala</h5>
                  <p>
                    A celebration of culture, diversity, and global unity, where
                    delegates showcase traditions, share stories, and immerse
                    themselves in a vibrant evening of performances, colors, and
                    festivity.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="eventBox">
                <img
                  src="\assets\images\oxfordMun\PowerDrill.png"
                  alt="eventImg"
                />
                <div className="eventContentBox">
                  {/* <h6 className="eventDate">28<span>March</span></h6> */}
                  <h5 className="eventTitle">Power Drill</h5>
                  <p>
                    An electrifying test of teamwork, strategy, and resilience,
                    where committees compete in dynamic challenges that push
                    delegates beyond diplomacy, fostering strong connections and
                    collaboration in an energetic setting.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="eventBox">
                <img src="assets\images\oxfordMun\GalaDinner.JPG" alt="eventImg" />
                <div className="eventContentBox">
                  {/* <h6 className="eventDate">28<span>March</span></h6> */}
                  <h5 className="eventTitle">Gala Dinner</h5>
                  <p>
                    A networking event where delegates engage with peers,
                    mentors, and sustainability leaders in an inspiring
                    atmosphere.
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>

          {/* <div className="row">
                        <div className="eventBox">
                            <img src="/assets/images/event1.png" />
                            <div className="eventContentBox">
                                <h6 className="eventDate">28<span>March</span></h6>
                                <h5 className="eventTitle">Model United Nations</h5>
                                <p>Delve into pressing global issues through meticulously curated committee sessions.</p>
                            </div>
                        </div>
                        <div className="eventBox">
                            <img src="/assets/images/event2.png" />
                            <div className="eventContentBox">
                                <h6 className="eventDate">28<span>March</span></h6>
                                <h5 className="eventTitle">Oxford Scholars Challenge</h5>
                                <p>Kickstart your MUN journey with unique challenges, enhancing team dynamics and critical
                                    thinking.</p>
                            </div>
                        </div>
                        <div className="eventBox">
                            <img src="/assets/images/event3.png" />
                            <div className="eventContentBox">
                                <h6 className="eventDate">28<span>March</span></h6>
                                <h5 className="eventTitle">Training Sessions</h5>
                                <p>Led by experts from Oxford, these sessions are designed to prime delegates for success.</p>
                            </div>
                        </div>
                    </div> */}
        </div>
      </section>

      <section className="venueSpotSec">
        <div className="container">
          <h2 className="secTitle" data-aos="fade-up">
            Venue Spotlight
          </h2>
          <div className="venueSpot-inner" data-aos="fade-up">
            <ImageGallery
              items={images}
              autoPlay={true}
              showFullscreenButton={false}
              showPlayButton={false}
              infinite={true}
            />

            {/* <img src="/assets/images/venueSpotImg.png" /> */}
            <div className="venueSpotContent">
              <h2>
                <span className="textUpper">Flame</span> University
              </h2>
              <p>
                FLAME University in Pune, renowned for its academic excellence
                and holistic approach to education, proudly returns as the host
                for OxfordMUN India 2025. With its state-of-the-art facilities
                and dynamic campus environment, FLAME provides the perfect
                setting for a transformative MUN experience. Set against a
                backdrop of lush greenery, the university fosters an atmosphere
                of intellectual exploration, leadership, and global engagement,
                making every moment at OxfordMUN India 2025 an opportunity for
                growth and discovery.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section className=" partnerSec partnerHomeSec pt-0"> */}
      {/* <div className="container-full"> */}
      {/* <div className="topHeading"> */}
      {/* <h6 className="fs-22 fw-600 textOrg pb-10 textUpper">community</h6> */}
      {/* <h2 className="fs-80 fw-600 " data-aos="fade-up">
              Partners
            </h2> */}
      {/* </div> */}

      {/* <div className="oxfordmun_logoslider" data-aos="fade-up"> */}
      {/* <Swiper
              modules={[Navigation, Autoplay, A11y]}
              spaceBetween={20}
              slidesPerView={5}
              loop={true}
              autoplay={{
                delay: 1500, // Increased delay for better viewing experience
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              keyboard={{
                enabled: true,
                onlyInViewport: true,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                480: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 25,
                },
                1440: {
                  slidesPerView: 5,
                  spaceBetween: 30,
                },
              }}
            > */}
      {/* <SwiperSlide>
                <a
                  href="https://www.allenglobalstudies.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/images/mun_partner_logo/5.png"
                    alt="networkSch"
                  />
                </a>
              </SwiperSlide> */}
      {/* <SwiperSlide>
                <a
                  href="https://www.flame.edu.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/images/mun_partner_logo/3.png"
                    alt="networkSch"
                  />
                </a>
              </SwiperSlide> */}
      {/* <SwiperSlide>
                <a
                  href="https://india.afs.org/yes-program/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/images/mun_partner_logo/2.png"
                    alt="networkSch"
                  />
                </a>
              </SwiperSlide> */}
      {/* <SwiperSlide>
                <a
                  href="https://iayp.co.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/images/mun_partner_logo/6.png"
                    alt="networkSch"
                  />
                </a>
              </SwiperSlide> */}
      {/* <SwiperSlide>
                <a
                  href="https://bsai.co.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/images/mun_partner_logo/4.png"
                    alt="networkSch"
                  />
                </a>
              </SwiperSlide> */}
      {/* Repeat slides as needed */}
      {/* </Swiper> */}
      {/* </div> */}
      {/* </div> */}
      {/* </section> */}

      <section className="reviewSec">
        <div className="container-full" data-aos="fade-up">
          <Swiper
            // install Swiper modules
            modules={[Autoplay, Navigation, A11y, EffectFade]}
            spaceBetween={0}
            slidesPerView={1}
            loop={true}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            centeredSlides={true}
            effect="fade"
            navigation={true}
            //onSwiper={(swiper) => console.log(swiper)}
            //onSlideChange={() => console.log('slide change')}
          >
            <SwiperSlide>
              <div>
                <img
                  src="/assets/images/oxfordMun/Students.png"
                  alt="home-img"
                />
                <div className="reviewContent">
                  <h3 className="reviewTitle" data-aos="fade-up">
                    8500+ Students Engaged
                  </h3>
                  <p className="fs-14 pt-10" data-aos="fade-up">
                    {" "}
                    Empowering over 8,500 students to become the leaders of
                    tomorrow.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img
                  src=".\assets\images\oxfordMun\Nationalitites.png"
                  alt="home-img"
                />
                <div className="reviewContent">
                  <h3 className="reviewTitle" data-aos="fade-up">
                    40+ Nationalities Represented
                  </h3>
                  <p className="fs-14 pt-10" data-aos="fade-up">
                    {" "}
                    A melting pot of cultures with delegates from over 40
                    nationalities.
                  </p>
                  {/* <p> Nationalities Represented</p> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img
                  src="/assets/images/oxfordMun/ConferencesHeld.png"
                  alt="home-img"
                />
                <div className="reviewContent">
                  <h3 className="reviewTitle" data-aos="fade-up">
                    25 Conferences Held
                  </h3>
                  <p className="fs-14 pt-10" data-aos="fade-up">
                    Shaping global minds through 25 impactful conferences
                    worldwide.
                  </p>
                  {/* <p> Conferences Held</p> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img
                  src="/assets/images/oxfordMun/AcademicMentors.png"
                  alt="home-img"
                />
                <div className="reviewContent">
                  <h3 className="reviewTitle" data-aos="fade-up">
                    22+ Academic Mentors
                  </h3>
                  <p className="fs-14 pt-10" data-aos="fade-up">
                    Guided by over 22 experts leading in the realm of
                    international diplomacy.
                  </p>
                  {/* <p> Academic Mentors</p> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img
                  src="/assets/images/oxfordMun/OperatesinCountries.png"
                  alt="home-img"
                />
                <div className="reviewContent">
                  <h3 className="reviewTitle" data-aos="fade-up">
                    Operates in 7 Countries
                  </h3>
                  <p className="fs-14 pt-10" data-aos="fade-up">
                    Bringing global perspectives across 7 diverse nations.
                  </p>
                  {/* <p>Operates in 7 Countries</p> */}
                </div>
              </div>
            </SwiperSlide>
          </Swiper>

          {/* <img src="/assets/images/reviewImg.png" alt='home-img' />
                    <div className="reviewContent">
                        <h3 className="reviewTitle">Last year Summit</h3>
                        <p>OxfordMUN India is more than a conference; it's a movement towards nurturing informed</p>
                    </div> */}
        </div>
      </section>

      <section className="joinSec">
        <div className="container">
          <div className="row" data-aos="fade-up">
            <div className="column mun_logo logoImage">
              <img
                src="/assets/images/OxfordMUN-India-2025-Logo.png"
                alt="home-img"
              />
            </div>
            <div className="column content-wrapperCol">
              <div className="content-wrapper">
                <h5 className="contentTitle" data-aos="fade-up">
                  Join the movement{" "}
                </h5>
                <p data-aos="fade-up">
                  OxfordMUN India is more than a conference; it's a movement
                  towards nurturing informed, empathetic, and capable future
                  leaders. We invite you to be a part of this historic event,
                  whether by participating, following its progress, or
                  contributing to its objectives. Discover more about this
                  transformative experience and how you can be a part of shaping
                  a brighter future through the power of diplomacy and dialogue.
                </p>
                <a
                  href="https://bit.ly/OxfordMUNIndia2025Brochure"
                  className="registerBtn"
                  target="_blank"
                >
                  {" "}
                  Download Brochure{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="callToAction">
        <div className="container  " data-aos="fade-up">
          <div className="  callToAction-inner">
            <div className="row">
              <div className="column callToActionLeft">
                <h5 data-aos="fade-up">
                  Make a Difference
                  <br /> with Us
                </h5>
                <p>Register Your School for OxfordMUN India 2025 Today!</p>
              </div>
              <div className="column callToActionRight">
                <p data-aos="fade-up">
                  Are you ready to be part of a transformative journey in
                  diplomacy, leadership, and global dialogue? OxfordMUN India
                  2025 invites schools from across the world to register and
                  claim their place at one of the most prestigious Model United
                  Nations conferences. This is a unique opportunity for students
                  to tackle global challenges, refine their critical thinking,
                  and engage in thought-provoking debates that shape the leaders
                  of tomorrow.
                </p>
                <a href="https://forms.gle/iGD6K3SCNNK8SGYb6" target="_blank">
                  <button
                    className="org_btn  registerBtn joinbtn"
                    // onClick={togglePopup}
                  >
                    Register Now
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="faqSec">
        <div className="container row faqInner" data-aos="fade-up">
          <div className="faqInnerLeft">
            <div className="faqInnerLeft-inner">
              <img
                src="/assets/images/OxfordMUN-India-2025-Logo.png"
                alt="home-img"
              />
              <p className="fs-18">
                Make a Difference with Us: Register your school for OxfordMUN
                India 2025 Today!
              </p>
              <p className="fs-18">
                Are you ready to embark on an extraordinary journey of
                discovery, diplomacy, and development? OxfordMUN India 2025 is
                inviting schools from across the globe to register and secure
                their spot in one of the most prestigious Model United Nations
                conferences. This is a unique opportunity for students to
                enhance their understanding of global issues, develop critical
                thinking skills, and engage in meaningful debates on world
                affairs.
              </p>

              {/* <a onClick={togglePopup} className="registerBtn " target="_blank">
                {" "}
                Enquire Now{" "}
              </a> */}
              <a href="https://forms.gle/iGD6K3SCNNK8SGYb6" target="_blank">
                <button
                  className="registerBtn"
                  // onClick={togglePopup}
                >
                  Register Now
                </button>
              </a>
              <p className="fs-18 p-0">Still Have a question? No worries.</p>

              <div className="contactDetail">
                <a href="mailto:madhur.oza@mu20.co ">
                  <img src="/assets/images/email.svg" alt="home-img" />
                  madhur.oza@mu20.co{" "}
                </a>
                <a href="tel:7987519367">
                  <img src="/assets/images/whatsapp.svg" alt="home-img" />
                  7987519367
                </a>
              </div>
              <div className="socialLinks">
                <a
                  href=" https://www.instagram.com/mu20.world?igsh=MThkY3NkeGJ3bHNtaA=="
                  target="_blank"
                >
                  <img src="/assets/images/insta.svg" alt="home-img" />
                </a>
                <a
                  href="https://www.linkedin.com/company/mu20/"
                  target="_blank"
                >
                  <img src="/assets/images/linkdine.svg" alt="home-img" />
                </a>
              </div>
            </div>
          </div>
          <div className="faqInnerRight">
            <h2 className="secTitle">FAQs</h2>
            <div className="faqBlock">
              <Accordion allowZeroExpanded>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h3 className="faqTitle">
                        <img src="/assets/images/icon.svg" alt="faq-icon" />
                        What is the venue for OxfordMUN India 2025?
                      </h3>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="faqContent">FLAME University, Pune, India</p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h3 className="faqTitle">
                        <img src="/assets/images/icon.svg" alt="faq-icon" />
                        What are the dates for OxfordMUN India 2025?
                      </h3>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>August 4-6, 2025</p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h3 className="faqTitle">
                        <img src="/assets/images/icon.svg" alt="faq-icon" />
                        How can I register for OxfordMUN India 2025?
                      </h3>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Visit{" "}
                      <a href="https://forms.gle/iGD6K3SCNNK8SGYb6">Link</a> to
                      secure your spot and embark on an unforgettable journey of
                      Discovery, Diplomacy and Development.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h3 className="faqTitle">
                        <img src="/assets/images/icon.svg" alt="faq-icon" />
                        What is the participation fee for OxfordMUN India 2025?
                      </h3>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      - School Registration Fee: INR 5,000 (Includes one teacher
                      representative without any additional charge)
                    </p>
                    <p>
                      {" "}
                      - Delegate Fee for MU20 School of Opportunity, BSAI and
                      AFS Member Schools: [Phase 1] INR 30,000 (incl. of GST)
                      [Phase 2] INR 35,000 (incl. of GST)
                    </p>
                    <p>
                      {" "}
                      - Delegate Fee for Non-Member Schools: [Phase 1] INR
                      35,000 (incl. of GST) [Phase 2] INR 40,000 (incl. of GST)
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h3 className="faqTitle">
                        <img src="/assets/images/icon.svg" alt="faq-icon" />
                        Who can participate in OxfordMUN India 2025?
                      </h3>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Students from Grade 9th to 12th</p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h3 className="faqTitle">
                        <img src="/assets/images/icon.svg" alt="faq-icon" />
                        Will accommodation be provided to the participants?
                      </h3>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Yes, the participants and their teacher coordinators would
                      be provided with twin-sharing rooms at FLAME University,
                      Pune.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h3 className="faqTitle">
                        <img src="/assets/images/icon.svg" alt="faq-icon" />
                        How can I get to the venue from the airport/train
                        station?
                      </h3>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Convenient pickup and drop-off services from local
                      station and airport would be provided to participants.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h3 className="faqTitle">
                        <img src="/assets/images/icon.svg" alt="faq-icon" />
                        Who can I contact for more information or assistance?
                      </h3>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Akshat Moghe{" "}
                      <a href="tel:+91-9522350555"> +91-9522350555 </a>
                      &nbsp;&nbsp;
                      <a href="mailTo:connect@mu20.co">connect@mu20.co</a>{" "}
                    </p>
                    <p>
                      Madhur Oza{" "}
                      <a href="tel:+91-79875 19367"> +91-79875 19367 </a>
                      &nbsp;&nbsp;
                      <a href="mailTo:madhur.oza@mu20.co">madhur.oza@mu20.co</a>
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      {/* <Button type="primary" onClick={showModal}>
                 Open Modal
                </Button> */}
      {/* <RegisterModal /> */}
      {/* <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <p>Some contents...</p>
                <p>Some contents...</p>
                <p>Some contents...</p>
            </Modal> */}
      <Footer />
    </>
  );
}

export default OxfordMun;
