import React, { useEffect, useRef, useState } from 'react';
import './Home.css';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import { Helmet } from 'react-helmet';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import Popup from 'reactjs-popup';
import PopupForm from './PopupForm'; // Adjust the path as per your project structure
// import PureModal from 'react-pure-modal';
// import 'react-pure-modal/dist/react-pure-modal.min.css';

// ----------------Swiper slider import------------------
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/css/effect-fade";
// import Swiper from 'swiper/bundle';
// import 'swiper/css/bundle';

// ----------------Gallery slider import------------------
import ImageGallery from 'react-image-gallery';
import { ModalProvider } from 'styled-react-modal';
import { Button, Modal } from 'antd';
import RegisterModal from '../Popup/RegisterModal';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the AOS styles






//--------------------Faq Accodian------------------ 
// ----------------Gallery slider start------------------
const images = [
    {
        original: '/assets/images/updated_image/1.jpg',
        thumbnail: '/assets/images/updated_image/1.jpg',
    },
    {
        original: '/assets/images/updated_image/2.jpg',
        thumbnail: '/assets/images/updated_image/2.jpg',
    },
    {
        original: '/assets/images/updated_image/3.jpg',
        thumbnail: '/assets/images/updated_image/3.jpg',
    },
    {
        original: '/assets/images/updated_image/4.jpg',
        thumbnail: '/assets/images/updated_image/4.jpg',
    },
];
// ----------------Gallery slider end------------------ 

function Leadership_and_Adventure_Bootcamp() {



    const [showPopup, setShowPopup] = useState(false);

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };


    const debounce = (fn, delay) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => fn(...args), delay);
        };
    };

    const resizeObserverCallback = debounce((entries) => {
        entries.forEach(entry => {
            // Handle the resize event
        });
    }, 100);

    const resizeObserver = new ResizeObserver(resizeObserverCallback);








    // const handleMouseEnter = (event) => {
    //     event.target.play();
    //   };

    //   const handleMouseLeave = (event) => {
    //     event.target.pause();
    //   };

    const [isMuted, setIsMuted] = useState(true);
    const [isActive, setActive] = useState("false");

    const ToggleClass = () => {
        setActive(!isActive);
    };
    // const [isModalOpen, setIsModalOpen] = useState(false);

    // const showModal = () => {
    //     setIsModalOpen(true);
    // }; 
    // const handleOk = () => {
    //     setIsModalOpen(false);
    // }; 
    // const handleCancel = () => {
    //     setIsModalOpen(false);
    // }; 

    //Video on Hover Start


    const [showIframe, setShowIframe] = useState(false);
    const [playingVideos, setPlayingVideos] = useState(new Set());

    // Replace with actual logic to determine if the device is mobile or touch
    // Use window.matchMedia to determine if the viewport is mobile-sized
    const isMobile = window.matchMedia("(max-width: 768px)").matches;

    useEffect(() => {


      
            AOS.init({
              duration: 1000, // Animation duration in milliseconds
            });
         



        if (isMobile) {
            const videos = document.querySelectorAll('.video');
            const observerOptions = {
                root: null, // Use the viewport as the container
                rootMargin: '0px',
                threshold: 0.5, // Adjust this as needed
            };

            const handleIntersection = (entries) => {
                entries.forEach(entry => {
                    const video = entry.target;
                    if (entry.isIntersecting) {
                        if (video.paused) {
                            // Attempt to play the video and handle cases where play() might not return a Promise
                            const playPromise = video.play();
                            if (playPromise !== undefined) {
                                playPromise.catch(error => console.error('Play interrupted:', error));
                            }
                            setPlayingVideos(prev => new Set(prev.add(video)));
                        }
                    } else {
                        if (!video.paused) {
                            // Attempt to pause the video and handle cases where pause() might not return a Promise
                            const pausePromise = video.pause();
                            if (pausePromise !== undefined) {
                                pausePromise.catch(error => console.error('Pause interrupted:', error));
                            }
                            setPlayingVideos(prev => {
                                const updated = new Set(prev);
                                updated.delete(video);
                                return updated;
                            });
                        }
                    }
                });
            };

            const observer = new IntersectionObserver(handleIntersection, observerOptions);
            videos.forEach(video => {
                observer.observe(video);
            });

            return () => {
                videos.forEach(video => {
                    observer.unobserve(video);
                });
            };
        }
    }, [isMobile, playingVideos]);

    const handleMouseEnter = (e) => {
        if (!isMobile) {
            const video = e.target;
            video.muted = true; // Keep the video muted to avoid autoplay restrictions
            if (video.paused) {
                // Attempt to play the video and handle cases where play() might not return a Promise
                const playPromise = video.play();
                if (playPromise !== undefined) {
                    playPromise.catch(error => console.error('Play interrupted:', error));
                }
            }
        }
    };

    const handleMouseLeave = (e) => {
        if (!isMobile) {
            const video = e.target;
            if (!video.paused) {
                // Attempt to pause the video and handle cases where pause() might not return a Promise
                const pausePromise = video.pause();
                if (pausePromise !== undefined) {
                    pausePromise.catch(error => console.error('Pause interrupted:', error));
                }
            }
        }
    };




    //Video on Hover End
    return (
        <>
            <Helmet>
                <title>MU20 LAB - MU20 School Of Opportunity        </title>
                <meta name="title" content="" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
            </Helmet>
            <Header />
            {/* <!-- banner section --> */}
            <div className='red_theme'>
                <section className="oxfordBanner">
                    {/* <div style={{ paddingBottom: "56.25%", maxWidth: "100%", position: "relative" }}><iframe src="https://player.vimeo.com/video/347119375?autoplay=1&loop=1&muted=1&title=0&portrait=0&byline=0&keyboard=0" width="800px" height="450px" style={{ position: " absolute", top: "0px", left: "0px", width: "100%", height: "100%" }} frameBorder="0"></iframe></div> */}
                    <div>
                        <video className="sliderBgImage" autoPlay loop playsInline width="100%" muted={isMuted ? true : false}>
                            {/* <source src="assets/videos/OxfordVideo-Com.mp4" type="video/mp4" /> */}
                            <source src="https://musite.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <div className="banner-content">
                        <h1  data-aos="fade-up">Leadership and Adventure Bootcamp</h1>
                        <div className={isActive ? "mute" : "unmute"}>
                            <a onClick={() => { setIsMuted(!isMuted); ToggleClass(); }} className="muteBtn widthAuto" id="btnUnMute"><img className="unmuteIcon" src="/assets/images/mute.svg" alt="unMuteIcon" />
                                <img className="muteIcon" src=" /assets/images/unmute.svg" alt="MuteIcon" /></a>
                        </div>
                    </div>
                </section >
                {/* <!-- introduction --> */}
                < section className="intro-section" >
                    <div className="container">
                        <div className="introduction">
                            <h2 className="secTitle"  data-aos="fade-up">Introduction</h2>
                            <p  data-aos="fade-up">Welcome to the Leadership and Adventure Bootcamp (LAB), a transformative experience designed to cultivate leadership skills and foster personal growth. Set amidst the scenic landscapes of Lalpur, LAB combines rigorous leadership training with thrilling adventure activities to create a unique and enriching environment for high school students.
                            </p>
                        </div>
                    </div>
                </section >
                {/* <!-- Why OxfordMUN India ? --> */}
                < section className="whyOxfordSec" >
                    <div className="container">
                        <div className="oxford-mun">
                            <h2 className="secTitle"  data-aos="fade-up">Why Choose LAB?</h2>
                        </div>
                        <div className="inner-box"  data-aos="fade-up">
                            <div className="row">
                                <div className="column">
                                    <div className="oxford-box">
                                        <h3>Expert Facilitators</h3>
                                        <p>Our workshops and activities are led by experts with extensive experience in leadership training and adventure sports, ensuring a comprehensive learning experience. Their mentorship and guidance help students gain practical insights and develop a strong foundation in leadership.</p>
                                    </div>
                                    <div className="oxford-box">
                                        <h3>Holistic Development</h3>
                                        <p>
                                            LAB focuses on nurturing both mind and body through a balanced mix of academic and physical challenges, promoting overall personal growth. This approach helps students build resilience, adaptability, and a well-rounded skill set that prepares them for future challenges.

                                        </p>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="oxford-box">
                                        <h3>Innovative Learning Environment</h3>
                                        <p>Our program integrates innovative teaching methods and real-world scenarios, providing students with practical leadership experience. By engaging in hands-on activities and simulations, students develop critical thinking and problem-solving skills.
                                        </p>
                                    </div>
                                    <div className="oxford-box">
                                        <h3>Safety and Support </h3>
                                        <p>We prioritize the safety and well-being of our participants, providing a secure and supportive environment for all activities. Our dedicated staff ensures that every student feels comfortable and confident throughout the bootcamp.</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section >
                {/* <!-- Conference Details --> */}
                < section className="conference" >
                    <div className="container">
                        <div className="confere-detail">
                            <h2 className="secTitle"  data-aos="fade-up">Bootcamp Details</h2>

                            <div className="row"  data-aos="fade-up">
                                <div className="column">
                                    <div className="confere-box">
                                        <h3>Who</h3>
                                        {/* <p>Students studying in classes <span className="bold"> 9th to 12th </span>can register</p> */}
                                        <p>Students studying in classes 9th to 12th can register.
                                        </p>

                                    </div>
                                    <div className="confere-box m-0">
                                        <h3>When and Where</h3>
                                        <p>Leadership and Adventure Bootcamp is held at the House of Lalpur in Punjab. Schools have the flexibility to pre-book their participation slots throughout the academic year, with a new cohort hosted every week. </p>
                                        {/* <p><span className="bold"> House of Lalpur, Punjab</span></p> */}

                                    </div>
                                </div>
                                <div className="column confere-reg">
                                    <div className="confere-regInner">
                                        <h3>HOW TO REACH THE VENUE: </h3>
                                        <p>The House of Lalpur is located in the Rupnagar district of Punjab, close to the border with Himachal Pradesh, nestled in the Sutlej valley between the Himalayas and the Shivalik mountains. Here’s how you can reach us:
                                            <br /><br />
                                            <ul>
                                                <li className='pb-20'><span className="bgColorText">By Air :</span> The nearest airport is Chandigarh Airport, approximately 82 kilometers away, which takes about 1.5 hours by car.</li>
                                                <li className='pb-20'><span className="bgColorText">By Train :</span> The nearest railway station is Anandpur Sahib, around 19 kilometers from the venue, taking approximately 20 minutes to reach. The venue can also be reached via Chandigarh Railway Station, around 86 kilometers from the venue, taking approximately 2 hours by car.</li>
                                                <li><span className="bgColorText">By Road :</span> The House of Lalpur is easily accessible via the Chandigarh-Manali Highway, situated just 5 kilometers off the main road. It is approximately a 1.5-hour drive from Chandigarh and around 7 hours from New Delhi.</li>
                                            </ul>

                                            We recommend arranging transportation from Chandigarh for a convenient and comfortable journey to the House of Lalpur.
                                        </p>
                                        <button className="registerBtn" onClick={togglePopup} >
                                            Enquire Now
                                        </button>

                                        {showPopup && (
                                            <Popup open={showPopup} closeOnDocumentClick onClose={togglePopup} modal nested>
                                                <PopupForm togglePopup={togglePopup} /> {/* Render the PopupForm component */}
                                            </Popup>
                                        )}

                                        {/* <a href="https://bit.ly/OxfordMUNRegistration" className="registerBtn" target="_blank"> Register Now </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
                <section className="whyOxfordSec eventSec">
                    <div className="container"  data-aos="fade-up">
                        <div className="oxford-mun">
                            <h2 className="secTitle"  data-aos="fade-up">Events</h2>
                        </div>
                        <Swiper
                            breakpoints={{
                                1440: {
                                    spaceBetween: 50,
                                    slidesPerView: 3
                                },
                                768: {
                                    spaceBetween: 40,
                                    slidesPerView: 2,
                                    centeredSlides: false,
                                },
                                200: {
                                    spaceBetween: 20,
                                    slidesPerView: 1,
                                }
                            }}
                            // install Swiper modules
                            modules={[Navigation, Autoplay, A11y]}
                            spaceBetween={60}
                            slidesPerView={3}
                            navigation
                            loop={true}
                            autoplay={{
                                delay: 1500,
                                disableOnInteraction: false,
                                pauseOnMouseEnter: true,
                            }}
                            centeredSlides={true}
                            onSwiper={(swiper) => console.log(swiper)}
                            onSlideChange={() => console.log('slide change')}
                        >
                            <SwiperSlide>
                                <div className="eventBox">
                                    <img src="/assets/images/pillarimage/kila_quest.jpg" alt='eventImg' />
                                    <div className="eventContentBox">
                                        {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                        <h5 className="eventTitle">Kila Quest</h5>
                                        <p>Embark on a thrilling scavenger hunt where teams solve riddles based on past teachings. This challenge tests problem-solving skills and teamwork as participants search for hidden objects within a limited time.</p>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="eventBox">
                                    <img src="/assets/images/visionaries_on_wheels.jpg" alt='eventImg' />
                                    <div className="eventContentBox">
                                        {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                        <h5 className="eventTitle">Visionaries on Wheels</h5>
                                        <p>Experience an eco-friendly adventure by exploring village tracks on e-cycles. This journey promotes sustainability, building endurance, and connects students with nature, highlighting the importance of embracing green mobility and active living.
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            {/* <SwiperSlide>
                            <div className="eventBox">
                                <img src="/assets/images/ScholarsEventR-min.jpg" alt='eventImg' />
                                <div className="eventContentBox">
                                    <h6 className="eventDate">28<span>March</span></h6>
                                    <h5 className="eventTitle">OxfordMUN Innovation Challenges</h5>
                                    <p>Kickstart your MUN journey with unique challenges, enhancing team dynamics and critical
                                        thinking.</p>
                                </div>
                            </div>
                        </SwiperSlide> */}
                            <SwiperSlide>
                                <div className="eventBox">
                                    <img src="/assets/images/pillarimage/polityproject.jpg" alt='eventImg' />
                                    <div className="eventContentBox">
                                        {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                        <h5 className="eventTitle">The Polity Project</h5>
                                        <p>Create fictional countries with governance structures, negotiate treaties, and form alliances. This activity fosters diplomatic skills, critical thinking, and global political understanding, preparing participants for real-world international relations challenges.
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="eventBox">
                                    <img src="/assets/images/pillarimage/trek_to_triumph.jpg" alt='eventImg' />
                                    <div className="eventContentBox">
                                        {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                        <h5 className="eventTitle">Trek to Triumph </h5>
                                        <p>Navigate challenging terrains to boost physical endurance and resilience. This outdoor adventure fosters teamwork, encourages personal growth, and offers participants the chance to appreciate nature's beauty in an active, engaging way.
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="eventBox">
                                    <img src="/assets/images/pillarimage/aqua_adventure.jpg" alt='eventImg' />
                                    <div className="eventContentBox">
                                        {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                        <h5 className="eventTitle">Aqua Adventures</h5>
                                        <p>Explore kayaking and water sports on the serene Sutlej River. This activity promotes physical fitness and a love for nature, offering a safe and thrilling way to connect with the water.</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="eventBox">
                                    <img src="/assets/images/pillarimage/horiticulture_harmony.jpg" alt='eventImg' />
                                    <div className="eventContentBox">
                                        {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                        <h5 className="eventTitle">Horticulture Harmony</h5>
                                        <p>Immerse in organic farming practices at our horticulture farm. Learn sustainable agriculture methods while enjoying the tranquility of the rural setting, fostering an appreciation for eco-friendly farming and environmental stewardship.</p>
                                    </div>
                                </div>
                            </SwiperSlide>




                            <SwiperSlide>
                                <div className="eventBox">
                                    <img src="/assets/images/pillarimage/sip&spot.jpg" alt='eventImg' />
                                    <div className="eventContentBox">
                                        {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                        <h5 className="eventTitle">Sip and Spot </h5>
                                        <p>Enjoy the finest tea in the serene Sutlej River valley. Engage in birdwatching, spotting diverse exotic species in their natural habitat, while savoring the tranquil beauty around you.
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="eventBox">
                                    <img src="/assets/images/pillarimage/leadership_workshop.jpg" alt='eventImg' />
                                    <div className="eventContentBox">
                                        {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                        <h5 className="eventTitle">Leadership Workshop</h5>
                                        <p>Explore the core principles of effective leadership through interactive sessions and real-world scenarios. Learn how to inspire, motivate, and guide your team to success, while developing a personal leadership style that is both authentic and impactful.</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="eventBox">
                                    <img src="/assets/images/pillarimage/teamworkworkshops.jpg" alt='eventImg' />
                                    <div className="eventContentBox">
                                        {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                        <h5 className="eventTitle">Teamwork Workshop</h5>
                                        <p>Discover the power of collaboration and the importance of building strong, cohesive teams. Engage in team-building exercises that enhance communication, trust, and cooperation, essential for achieving common goals and driving group success.</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="eventBox">
                                    <img src="/assets/images/lab_decision_making.jpg" alt='eventImg' />
                                    <div className="eventContentBox">
                                        {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                        <h5 className="eventTitle">Decision Making Workshop</h5>
                                        <p>Enhance your decision-making skills with practical strategies and tools. Learn to analyze situations, weigh options, and make informed choices quickly and effectively, even under pressure, ensuring the best outcomes for your team.</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="eventBox">
                                    <img src="/assets/images/pillarimage/problemsolvingworkshop.jpg" alt='eventImg' />
                                    <div className="eventContentBox">
                                        {/* <h6 className="eventDate">28<span>March</span></h6> */}
                                        <h5 className="eventTitle">Problem Solving Workshop</h5>
                                        <p>Develop your problem-solving abilities through hands-on activities and critical thinking exercises. Gain techniques to identify issues, brainstorm solutions, and implement strategies that address challenges efficiently and creatively.</p>
                                    </div>
                                </div>
                            </SwiperSlide>


                        </Swiper>


                        {/* <div className="row">
                        <div className="eventBox">
                            <img src="/assets/images/event1.png" />
                            <div className="eventContentBox">
                                <h6 className="eventDate">28<span>March</span></h6>
                                <h5 className="eventTitle">Model United Nations</h5>
                                <p>Delve into pressing global issues through meticulously curated committee sessions.</p>
                            </div>
                        </div>
                        <div className="eventBox">
                            <img src="/assets/images/event2.png" />
                            <div className="eventContentBox">
                                <h6 className="eventDate">28<span>March</span></h6>
                                <h5 className="eventTitle">Oxford Scholars Challenge</h5>
                                <p>Kickstart your MUN journey with unique challenges, enhancing team dynamics and critical
                                    thinking.</p>
                            </div>
                        </div>
                        <div className="eventBox">
                            <img src="/assets/images/event3.png" />
                            <div className="eventContentBox">
                                <h6 className="eventDate">28<span>March</span></h6>
                                <h5 className="eventTitle">Training Sessions</h5>
                                <p>Led by experts from Oxford, these sessions are designed to prime delegates for success.</p>
                            </div>
                        </div>
                    </div> */}

                    </div>
                </section>
              
                <section className="venueSpotSec">
                    <div className="container" >
                        <h2 className="secTitle"  data-aos="fade-up">Venue Spotlight</h2>
                        <div className="venueSpot-inner"  data-aos="fade-up">

                            <ImageGallery items={images}
                                autoPlay={true}
                                showFullscreenButton={false}
                                showPlayButton={false}
                                infinite={true} />


                            {/* <img src="/assets/images/venueSpotImg.png" /> */}
                            <div className="venueSpotContent">
                                <h2><span className="textUpper">House of Lalpur</span> </h2>
                                <p>
                                    Discover the House of Lalpur, an idyllic 50-acre campus dedicated to leadership and adventure training. Nestled amidst the stunning landscapes of the Shivalik and Himalayan ranges, just 5 kilometers off the Manali Highway, the House of Lalpur provides an ideal setting for immersive learning and personal growth.
                                    <br /><br />
                                    The campus offers a serene environment away from the distractions of modern life, allowing students to fully engage with the program. With state-of-the-art facilities, including interactive knowledge sessions, adventure sports, and cultural immersion activities, the House of Lalpur stands as a testament to the commitment of the MU20 School of Leadership.
                                    <br /><br />
                                    Join us at the House of Lalpur and experience a transformative journey where nature and learning converge, empowering you to become a confident and capable leader.

                                </p>
                            </div>
                        </div>
                    </div>
                </section>






                <section className=" testimonialSec testimonialHomeSec">
                    <div className="container-full"  data-aos="fade-up">

                        <h2 className="secTitle"  data-aos="fade-up">Teacher Testimonials</h2>


                        <Swiper
                            breakpoints={{
                                1440: {
                                    spaceBetween: 40,
                                    slidesPerView: 4,
                                    centeredSlides: false,
                                },
                                768: {
                                    spaceBetween: 30,
                                    slidesPerView: 3,
                                },
                                200: {
                                    spaceBetween: 20,
                                    slidesPerView: 1,
                                    autoplay: false,

                                },
                            }}
                            modules={[Autoplay, A11y]}
                            spaceBetween={50}
                            slidesPerView={5}
                            loop={true}
                            autoplay={{
                                delay: 1500,
                                disableOnInteraction: false,
                                pauseOnMouseEnter: true
                            }}
                            centeredSlides={true}
                        >
                            <SwiperSlide>
                                <div className="testimonialImg">
                                    <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                                        <source src="https://musite.b-cdn.net/videos/1.mp4" type="video/mp4" />
                                    </video>

                                    <div className="textWrapper">
                                        <img src="/assets/images/videoSec.svg" alt='home-img' />

                                        <h6 className="titleWatch">Watch Story</h6>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>

                                <div className="testimonialImg">
                                    <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                                        <source src="https://musite.b-cdn.net/videos/2.mp4" type="video/mp4" />
                                    </video>



                                    <div className="textWrapper">
                                        <img src="/assets/images/videoSec.svg" alt='home-img' />
                                        <h6 className="titleWatch">Watch Story</h6>
                                    </div>
                                </div>

                            </SwiperSlide>



                            <SwiperSlide>
                                <div className="testimonialImg">
                                    <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                                        <source src="https://musite.b-cdn.net/videos/3.mp4" type="video/mp4" />
                                    </video>
                                    <div className="textWrapper">
                                        <img src="/assets/images/videoSec.svg" alt='home-img' />
                                        <h6 className="titleWatch">Watch Story</h6>
                                    </div>
                                </div>

                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="testimonialImg">
                                    <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                                        <source src="https://musite.b-cdn.net/videos/4.mp4" type="video/mp4" />
                                    </video>






                                    <div className="textWrapper">
                                        <img src="/assets/images/videoSec.svg" alt='home-img' />

                                        <h6 className="titleWatch">Watch Story</h6>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>

                                <div className="testimonialImg">
                                    <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                                        <source src="https://musite.b-cdn.net/videos/5.mp4" type="video/mp4" />
                                    </video>



                                    <div className="textWrapper">
                                        <img src="/assets/images/videoSec.svg" alt='home-img' />
                                        <h6 className="titleWatch">Watch Story</h6>
                                    </div>
                                </div>

                            </SwiperSlide>





                        </Swiper>
                    </div>
                </section>

                <section className="testimonialSec testimonialHomeSec">
                    <div className="container-full"  data-aos="fade-up">
                        <div className="topHeading" >
                            <h2 className="fs-80 fw-600"  data-aos="fade-up">Student Testimonials</h2>
                        </div>

                        <Swiper
                            breakpoints={{
                                1440: {
                                    spaceBetween: 40,
                                    slidesPerView: 4,
                                    centeredSlides: false,
                                },
                                768: {
                                    spaceBetween: 30,
                                    slidesPerView: 3,
                                },
                                200: {
                                    spaceBetween: 20,
                                    slidesPerView: 1,
                                    autoplay: false,

                                },
                            }}
                            modules={[Autoplay, A11y]}
                            spaceBetween={50}
                            slidesPerView={5}
                            loop={true}
                            slidesOffsetBefore={200}
                            //       autoplay={{
                            //      delay: 1500,
                            //      disableOnInteraction: false,  
                            //      pauseOnMouseEnter: true
                            //    }}
                            centeredSlides={true}
                        >
                            <SwiperSlide>
                                <div className="testimonialImg">
                                    <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                                        <source src="https://musite.b-cdn.net/LABwebtest1.mp4" type="video/mp4" />
                                    </video>
                                    {/* <img src="assets/images/testimonial1.png" alt='home-img' /> */}
                                    <div className="textWrapper">
                                        <img src="/assets/images/videoSec.svg" alt='home-img' />

                                        <h6 className="titleWatch">Watch Story</h6>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>

                                <div className="testimonialImg">
                                    <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                                        <source src="https://musite.b-cdn.net/LABwebtest2.mp4" type="video/mp4" />
                                    </video>



                                    <div className="textWrapper">
                                        <img src="/assets/images/videoSec.svg" alt='home-img' />
                                        <h6 className="titleWatch">Watch Story</h6>
                                    </div>
                                </div>

                            </SwiperSlide>



                            <SwiperSlide>
                                <div className="testimonialImg">
                                    <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                                        <source src="https://musite.b-cdn.net/LABwebtest3.mp4" type="video/mp4" />
                                    </video>



                                    {/* <img src="assets/images/testimonial3.png" alt='home-img' /> */}
                                    <div className="textWrapper">
                                        <img src="/assets/images/videoSec.svg" alt='home-img' />
                                        <h6 className="titleWatch">Watch Story</h6>
                                    </div>
                                </div>
                            </SwiperSlide>


                        </Swiper>
                    </div>
                </section>

                <section className="reviewSec">
                    <div className="container-full"  data-aos="fade-up">

                        <Swiper

                            // install Swiper modules
                            modules={[Autoplay, Navigation, A11y, EffectFade]}
                            spaceBetween={0}
                            slidesPerView={1}
                            loop={true}
                            autoplay={{
                                delay: 1500,
                                disableOnInteraction: true,
                                pauseOnMouseEnter: false
                            }}
                            centeredSlides={true}
                            effect="fade"
                            navigation={true}
                        //onSwiper={(swiper) => console.log(swiper)}
                        //onSlideChange={() => console.log('slide change')}
                        >
                            <SwiperSlide>
                                <div>
                                    <img src="/assets/images/Bootcamp/70+cohorts(2).jpg" alt='home-img' />
                                    <div className="reviewContent">
                                        <h3 className="reviewTitle"  data-aos="fade-up">1,500+ Students on Campus</h3>
                                        <p className='fs-14 pt-10'  data-aos="fade-up">Over 1,500 students have experienced the transformative environment of our residential bootcamps.</p>
                                        {/* <p>Students Engaged</p> */}
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div>
                                    <img src="/assets/images/pillarimage/250+hrs.jpg" alt='home-img' />
                                    <div className="reviewContent">
                                        <h3 className="reviewTitle"  data-aos="fade-up">70+ Cohorts Formed</h3>
                                        <p className='fs-14 pt-10'  data-aos="fade-up"> Successfully organized more than 70 cohorts, fostering close-knit learning communities.</p>
                                        {/* <p> Nationalities Represented</p> */}
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div>
                                    <img src="/assets/images/Bootcamp/1000+studentsoncampus(2).jpg" alt='home-img' />
                                    <div className="reviewContent">
                                        <h3 className="reviewTitle"  data-aos="fade-up">50+ Schools Participated</h3>
                                        <p className='fs-14 pt-10'  data-aos="fade-up">Participation from over 50 schools, bringing diverse perspectives and enriching the learning experience.</p>
                                        {/* <p> Conferences Held</p> */}
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div>
                                    <img src="/assets/images/pillarimage/100+pro.jpg" alt='home-img' />
                                    <div className="reviewContent">
                                        <h3 className="reviewTitle"  data-aos="fade-up">100+ Projects Submitted</h3>
                                        <p className='fs-14 pt-10'  data-aos="fade-up">Students have submitted over 100 projects, demonstrating their creativity and innovation.
                                        </p>
                                        {/* <p> Academic Mentors</p> */}
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div>
                                    <img src="/assets/images/pillarimage/15+activity.jpg" alt='home-img' />
                                    <div className="reviewContent">
                                        <h3 className="reviewTitle"  data-aos="fade-up">15+ Events and Activities</h3>
                                        <p className='fs-14 pt-10'  data-aos="fade-up">Hosted more than 15 events and activities, offering a holistic and engaging bootcamp experience.
                                        </p>
                                        {/* <p>Operates in 7 Countries</p> */}
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div>
                                    <img src="/assets/images/pillarimage/70+i.jpg" alt='home-img' />
                                    <div className="reviewContent">
                                        <h3 className="reviewTitle"  data-aos="fade-up">250+ Hours of Workshops</h3>
                                        <p className='fs-14 pt-10'  data-aos="fade-up">Conducted more than 300 hours of intensive workshops covering various topics.
                                        </p>
                                        {/* <p>Operates in 7 Countries</p> */}
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>




                        {/* <img src="/assets/images/reviewImg.png" alt='home-img' />
                    <div className="reviewContent">
                        <h3 className="reviewTitle">Last year Summit</h3>
                        <p>OxfordMUN India is more than a conference; it's a movement towards nurturing informed</p>
                    </div> */}
                    </div>
                </section>







                <section className="joinSec">
                    <div className="container">
                        <div className="row">
                            <div className="column logoImage">
                                <img src="/assets/images/pillarimage/students_on_campus_(2).jpg" alt='home-img' />
                            </div>
                            <div className="column content-wrapperCol" data-aos="fade-up">
                                <div className="content-wrapper" >
                                    <h5 className="contentTitle"  data-aos="fade-up">Join the movement </h5>
                                    <p>
                                        Become a part of a transformative journey at the Leadership and Adventure Bootcamp (LAB). Join a community of aspiring leaders who are committed to personal growth, resilience, and making a positive impact in the world. At LAB, you will engage in thrilling activities, immersive workshops, and real-world challenges that will push your boundaries and help you discover your true potential. Embark on this adventure with us and be empowered to lead with confidence, creativity, and compassion. Together, let's create a future where young leaders like you drive change and inspire others.
                                    </p>
                                    <a href="https://musite.b-cdn.net/videos/MU20%20Leadership%20and%20Adventure%20Bootcamp.pdf" className="registerBtn" target="_blank"> Download Brochure </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="callToAction">
                    <div className="container  " data-aos="fade-up">
                        <div className="  callToAction-inner">
                            <div className="row">
                                <div className="column callToActionLeft">
                                    <h5 data-aos="fade-up">Ready to make a <br /> difference? </h5>
                                    <p>Register Your School for Leadership and Bootcamp Today!</p>
                                </div>
                                <div className="column callToActionRight">
                                    <p>Register Your School Today for the Leadership and Adventure Bootcamp!
                                        Ready to embark on this exciting journey? Enquire now and get a chance to secure your spot at the Leadership and Adventure Bootcamp. Limited seats are available, so don’t miss out on this opportunity to grow and thrive. Are you ready to take the leap? Join the movement and start your leadership journey today.
                                    </p>

                                    <button className="registerBtn" onClick={togglePopup} >
                                        Enquire Now
                                    </button>

                                    {showPopup && (
                                        <Popup open={showPopup} closeOnDocumentClick onClose={togglePopup} modal nested>
                                            <PopupForm togglePopup={togglePopup} /> {/* Render the PopupForm component */}
                                        </Popup>
                                    )}

                                    {/* <a href="https://bit.ly/OxfordMUNRegistration" className="registerBtn" target="_blank"> Register Now </a> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <section className="faqSec">

                    <div className="container row faqInner" data-aos="fade-up">
                        <div className="faqInnerLeft">
                            <div className="faqInnerLeft-inner">
                                <img src="/assets/images/whitelogo@4x.png" alt="home-img" />
                                <p className="fs-18">Leave a Legacy with Us: Join the Leadership and Adventure Bootcamp Today!</p>
                                <p className="fs-18">Join the Leadership and Adventure Bootcamp (LAB) and embark on a journey where your leadership skills and adventurous spirit can leave a lasting legacy. It’s a powerful experience designed to challenge, inspire, and transform you. Whether you're scaling new heights, leading a team, or pushing your limits, LAB offers the perfect environment to grow and make an impact. Seize the opportunity to build lasting connections, develop your potential, and shape the future. Together, let’s create a legacy that will endure.</p>
                                {/* <a href="https://bit.ly/OxfordMUNRegistration" className="registerBtn " target="_blank"> Register Now </a> */}
                                <button className="registerBtn" onClick={togglePopup} >
                                    Enquire Now
                                </button>

                                {showPopup && (
                                    <Popup open={showPopup} closeOnDocumentClick onClose={togglePopup} modal nested>
                                        <PopupForm togglePopup={togglePopup} /> {/* Render the PopupForm component */}
                                    </Popup>
                                )}

                                <p className="fs-18 p-0">Still Have a question?  No worries.</p>

                                <div className="contactDetail">
                                    <a href="mailto:madhur.oza@mu20.co" ><img src="/assets/images/email.svg" alt="home-img" />madhur.oza@mu20.co </a>
                                    <a href="tel:7987519367" ><img src="/assets/images/whatsapp.svg" alt="home-img" />7987519367</a>
                                </div>
                                <div className="socialLinks">

                                    <a href=" https://www.instagram.com/mu20.world?igsh=MThkY3NkeGJ3bHNtaA==" target="_blank">
                                        <img src="/assets/images/insta.svg" alt="home-img" /></a>
                                    <a href="https://www.linkedin.com/company/mu20/" target="_blank">
                                        <img src="/assets/images/linkdine.svg" alt="home-img" /></a>
                                </div>
                            </div>
                        </div>
                        <div className="faqInnerRight">
                            <h2 className="secTitle">FAQs</h2>
                            <div className="faqBlock">
                                <Accordion allowZeroExpanded>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                                    What is the MU20 School Of Opportunity  Leadership and Adventure Bootcamp?</h3>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p className="faqContent"> It's an immersive program for high school students to develop leadership skills through experiential learning and adventure activities.</p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                                Where is the bootcamp held?</h3>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>The bootcamp takes place at the House of Lalpur, a 50-acre campus in Punjab's Sutlej valley.</p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                                Who can participate in the bootcamp?</h3>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>High school students aged 14-18 eager to enhance their leadership skills.</p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                                What activities are included?</h3>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>Activities include team-building, leadership workshops, kayaking, trekking, and more.</p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                                What leadership skills will students develop?</h3>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p> Students will develop skills in communication, decision-making, problem-solving, and self-awareness.</p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                                Who leads the bootcamp?</h3>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>The bootcamp is led by experienced professionals, including Dr. Sumer Singh.</p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                                How long is the bootcamp?</h3>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>The bootcamp is a four-day experience, with activities running from morning until evening.</p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton><h3 className="faqTitle" ><img src="/assets/images/icon.svg" alt='faq-icon' />
                                                What does the fee cover?</h3>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p> The fee includes lodging, meals, activities, sessions, and a program kit. </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                </Accordion>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* <Button type="primary" onClick={showModal}>
                 Open Modal
                </Button> */}
            {/* <RegisterModal /> */}
            {/* <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <p>Some contents...</p>
                <p>Some contents...</p>
                <p>Some contents...</p>
            </Modal> */}
            <Footer />
        </>
    )
}

export default Leadership_and_Adventure_Bootcamp;
