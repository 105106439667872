import React, { useState, useEffect } from "react";

import "./Home.css";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import PopupForm from "./PopupForm"; // Adjust the path as per your project structure
import Popup from "reactjs-popup";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// import PureModal from 'react-pure-modal';
// import 'react-pure-modal/dist/react-pure-modal.min.css';

// ----------------Swiper slider import------------------
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectFade,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css/effect-fade";
// import Swiper from 'swiper/bundle';
// import 'swiper/css/bundle';

// ----------------Gallery slider import------------------
import ImageGallery from "react-image-gallery";
import { ModalProvider } from "styled-react-modal";
import { Button, Modal } from "antd";
import RegisterModal from "../Popup/RegisterModal";

import AOS from "aos";
import "aos/dist/aos.css"; // Import the AOS styles

//--------------------Faq Accodian------------------
// ----------------Gallery slider start------------------
const images = [
  {
    original: "/assets/images/venueSpotImg.png",
    thumbnail: "/assets/images/venueSpotImg.png",
  },
  {
    original: "/assets/images/Flames1.png",
    thumbnail: "/assets/images/Flames1.png",
  },
  {
    original: "/assets/images/Flames2.png",
    thumbnail: "/assets/images/Flames2.png",
  },
  {
    original: "/assets/images/Flames3.png",
    thumbnail: "/assets/images/Flames3.png",
  },
];
// ----------------Gallery slider end------------------

function Hsis() {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const [isMuted, setIsMuted] = useState(true);
  const [isActive, setActive] = useState("false");

  const ToggleClass = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    document.title = "HSIS - MU20 School Of Opportunity";
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
    });
  }, []);
  // const [isModalOpen, setIsModalOpen] = useState(false);

  // const showModal = () => {
  //     setIsModalOpen(true);
  // };
  // const handleOk = () => {
  //     setIsModalOpen(false);
  // };
  // const handleCancel = () => {
  //     setIsModalOpen(false);
  // };
  return (
    <>
      <Header />
      {/* <!-- banner section --> */}
      <section className="oxfordBanner">
        {/* <div style={{ paddingBottom: "56.25%", maxWidth: "100%", position: "relative" }}><iframe src="https://player.vimeo.com/video/347119375?autoplay=1&loop=1&muted=1&title=0&portrait=0&byline=0&keyboard=0" width="800px" height="450px" style={{ position: " absolute", top: "0px", left: "0px", width: "100%", height: "100%" }} frameBorder="0"></iframe></div> */}
        <div>
          <video
            className="sliderBgImage"
            autoPlay
            loop
            playsInline
            width="100%"
            muted={isMuted ? true : false}
          >
            {/* <source src="assets/videos/OxfordVideo-Com.mp4" type="video/mp4" /> */}
            <source
              src="https://musite.b-cdn.net/videos/HSIS%202025%20Launch%20Trailer%20(Final%20cut).mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="banner-content">
          <h1 data-aos="fade-up">
            Harvard Sustainability Impact Summit (HSIS) 2025
          </h1>
          <div className={isActive ? "mute" : "unmute"}>
            <a
              onClick={() => {
                setIsMuted(!isMuted);
                ToggleClass();
              }}
              className="muteBtn widthAuto"
              id="btnUnMute"
            >
              <img
                className="unmuteIcon"
                src=" /assets/images/mute.svg"
                alt="unMuteIcon"
              />
              <img
                className="muteIcon"
                src="/assets/images/unmute.svg"
                alt="MuteIcon"
              />
            </a>
          </div>
        </div>
      </section>
      {/* <!-- introduction --> */}
      <section className="intro-section">
        <div className="container">
          <div className="introduction">
            <h2 className="secTitle hsis" data-aos="fade-up">
              Introduction
            </h2>
            <p data-aos="fade-up">
              The Harvard Sustainability Impact Summit (HSIS) 2025 is a
              transformative three-day summit at FLAME University, Pune, where
              high school students tackle global sustainability challenges
              through the lens of the United Nations Sustainable Development
              Goals (SDGs). Combining policy simulations, expert mentorship, and
              hands-on problem-solving, participants develop actionable
              solutions to real-world issues. Guided by mentors from Harvard
              PAIR, they refine their leadership, negotiation, and
              decision-making skills, preparing them to drive meaningful and
              lasting impact.
            </p>
          </div>
        </div>
      </section>
      {/* <!-- Why HSIS India ? --> */}
      <section className="whyOxfordSec eventSecHsis">
        <div className="container">
          <div className="oxford-mun">
            <h2 className="secTitle hsis" data-aos="fade-up">
              Why Attend HSIS 2025?
            </h2>
          </div>
          <div className="inner-box" data-aos="fade-up">
            <div className="row">
              <div className="column">
                <div className="oxford-box">
                  <h3 className="hsis">Bridging Knowledge with Action</h3>
                  <p>
                    HSIS 2025 offers high school students (Grades 9th to 12th)
                    the opportunity to participate in an immersive
                    sustainability and policy-making challenge. It equips
                    participants with the skills to address real-world issues,
                    ensuring they don’t just discuss solutions, they create
                    them.
                  </p>
                </div>
                <div className="oxford-box">
                  <h3 className="hsis">Immersive & Impact-Driven Learning</h3>
                  <p>
                    HSIS 2025 pushes beyond theory, immersing participants in
                    policy simulations, expert-led workshops, and real-time
                    problem-solving. Participants engage in debates, lobbying,
                    and negotiations, refining their ability to navigate
                    sustainability challenges with strategy and impact.{" "}
                  </p>
                </div>
              </div>
              <div className="column">
                <div className="oxford-box">
                  <h3 className="hsis">
                    Exclusive Harvard Mentorship & Global Exposure
                  </h3>
                  <p>
                    Gain direct guidance from mentors affiliated with Harvard
                    PAIR, sustainability leaders, and policymakers. Participants
                    will refine their ideas through structured mentorship,
                    present their solutions to an expert panel, develop a deeper
                    understanding of policy frameworks and global governance.
                  </p>
                </div>
                <div className="oxford-box">
                  <h3 className="hsis">
                    Recognition for Outstanding Changemakers
                  </h3>
                  <p>
                    Top-performing teams will receive a 100% scholarship
                    covering the registration fee for the Harvard conference at
                    Harvard, providing them with international exposure and a
                    platform to engage in high-level diplomatic discussions.
                    This opportunity allows them to refine their leadership
                    skills, collaborate with global experts, and contribute to
                    impactful sustainability initiatives on a broader scale.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Conference Details --> */}
      <section className="conference">
        <div className="container" data-aos="fade-up">
          <div className="confere-detail">
            <h2 className="secTitle hsis" data-aos="fade-up">
              Conference Details
            </h2>

            <div className="row">
              <div className="column">
                <div className="confere-box confere-box_hsis">
                  <h3>Who</h3>
                  <p>Students studying in classes </p>
                  <span className="bgColorText bgColorText_hsis">
                    9th to 12th can register.
                  </span>
                </div>
                <div className="confere-box confere-box_hsis m-0">
                  <h3>When and Where</h3>
                  <span className="bgColorText bgColorText_hsis">
                    4th August 2025 - 5th August 2025 -
                  </span>

                  <p>
                    <span className="bgColorText bgColorText_hsis">
                      {" "}
                      6th August 2025
                    </span>{" "}
                    at FLAME University, Pune
                  </p>
                </div>
              </div>
              <div className="column confere-reg confere-reg-hsis">
                <div className="confere-regInner">
                  <h3>Registration</h3>
                  <p>
                    To express your interest in joining, schools are requested
                    to register by <span>21st April 2025</span>(Phase 1) and the
                    final registration deadline will be{" "}
                    <span>20th July 2025</span> (Phase 2)
                  </p>

                  <a href="https://forms.gle/QVCBgyqZLYPhmyQ26" target="_blank">
                    <button
                      className="org_btn  registerBtn joinbtn"
                      // onClick={togglePopup}
                    >
                      Register Now
                    </button>
                  </a>

                  {/* {showPopup && (
                    <Popup
                      open={showPopup}
                      closeOnDocumentClick
                      onClose={togglePopup}
                      modal
                      nested
                    >
                      <PopupForm togglePopup={togglePopup} />
                    </Popup>
                  )} */}

                  {/* <a href="https://bit.ly/OxfordMUNRegistration" className="registerBtn" target="_blank"> </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="rewardSec p-80-20">
        <div className="container" data-aos="fade-up">
          <h2 className="secTitle textBlack" data-aos="fade-up">
            Compete and Win
          </h2>
          <div className="reward-outer reward-outer-hsis">
            <div className="rewardInner rewardInnerCenter">
              <h5 className="fw-700 priceHeading priceHeading-hsis">
                Best Teams
              </h5>
              <div className="innnerContent innnerContent-hsis">
                <div className="innnerContentTop">
                  <p className="fs-18 fw-400 ">
                    The best performing team of every committee will receive a
                    100% scholarship on the registration fee to attend the next
                    edition of the HPAIR Conference at Harvard, earning
                    recognition as emerging sustainability leaders. Showcase
                    your talent and secure a place among the next generation of
                    changemakers.
                  </p>
                </div>
              </div>
              <h5 className="fw-700 priceTitle priceTitle-hsis">
                100% Scholarship
              </h5>
            </div>

            <div className="rewardInner rewardInnerCenter">
              <h5 className="fw-700 priceHeading  priceHeading-hsis">
                Best Performers
              </h5>
              <div className="innnerContent innnerContent-hsis">
                <div className="innnerContentTop">
                  <p className="fs-18 fw-400 ">
                    The top five winners of the Changemaker Challenge will be
                    awarded a 100% scholarship covering their HSIS 2025
                    registration fee. This prestigious recognition highlights
                    them as emerging leaders in sustainability and
                    policy-making, empowering them to further their impact on
                    global sustainability challenges.
                  </p>
                </div>
              </div>
              <h5 className="fw-700 priceTitle priceTitle-hsis">
                100% Scholarship
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section className="whyOxfordSec eventSecHsis eventSec">
        <div className="container" data-aos="fade-up">
          <div className="oxford-mun">
            <h2 className="secTitle hsis" data-aos="fade-up">
              Events
            </h2>
          </div>
          <Swiper
            breakpoints={{
              1440: {
                spaceBetween: 50,
                slidesPerView: 3,
              },
              768: {
                spaceBetween: 40,
                slidesPerView: 3,
                centeredSlides: false,
              },
              200: {
                spaceBetween: 20,
                slidesPerView: 1,
              },
            }}
            // install Swiper modules
            modules={[Navigation, Autoplay, A11y]}
            spaceBetween={60}
            slidesPerView={3}
            navigation
            loop={true}
            autoplay={false}
            centeredSlides={true}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log("slide change")}
          >
            {/* image update */}
            <SwiperSlide>
              <div className="eventBox">
                <img
                  src="/assets/images/hsis/CreativeRounds.jpg"
                  alt="eventImg"
                />
                <div className="eventContentBox">
                  {/* <h6 className="eventDate">28<span>March</span></h6> */}
                  <h5 className="eventTitle">Creative Rounds</h5>
                  <p>
                    Unleash innovation through out-of-the-box problem-solving
                    challenges that transform ideas into interactive
                    performances, policy-making, campaigns, and visual
                    storytelling.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="eventBox">
                <img src="\assets\images\hsis\PowerTalk.jpg" alt="eventImg" />
                <div className="eventContentBox">
                  {/* <h6 className="eventDate">28<span>March</span></h6> */}
                  <h5 className="eventTitle">Power Talks</h5>
                  <p>
                    Insightful discussions led by mentors from Harvard PAIR and
                    industry experts, offering perspectives on sustainability,
                    leadership, and real-world impact.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
                            <div className="eventBox">
                                <img src="/assets/images/ScholarsEventR-min.jpg" alt='eventImg' />
                                <div className="eventContentBox">
                                    <h6 className="eventDate">28<span>March</span></h6>
                                    <h5 className="eventTitle">OxfordMUN Innovation Challenges</h5>
                                    <p>Kickstart your MUN journey with unique challenges, enhancing team dynamics and critical
                                        thinking.</p>
                                </div>
                            </div>
                        </SwiperSlide> */}
            <SwiperSlide>
              <div className="eventBox">
                <img src="/assets/images/hsis/Masquerade.JPG" alt="eventImg" />
                <div className="eventContentBox">
                  {/* <h6 className="eventDate">28<span>March</span></h6> */}
                  <h5 className="eventTitle">Masquerade Eve</h5>
                  <p>
                    A night of elegance and celebration, allowing participants
                    to connect beyond policy discussions.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="eventBox">
                <img src="assets\images\hsis\GlobalGala.JPG" alt="eventImg" />
                <div className="eventContentBox">
                  {/* <h6 className="eventDate">28<span>March</span></h6> */}
                  <h5 className="eventTitle">Global Village Gala</h5>
                  <p>
                    A cultural showcase where students share their heritage
                    through performances and interactive exhibits.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="eventBox">
                <img src="\assets\images\hsis\PowerDrill.png" alt="eventImg" />
                <div className="eventContentBox">
                  {/* <h6 className="eventDate">28<span>March</span></h6> */}
                  <h5 className="eventTitle">Power Drill</h5>
                  <p>
                    An electrifying test of teamwork, strategy, and resilience,
                    where committees compete in dynamic challenges that push
                    participants beyond diplomacy, fostering strong connections
                    and collaboration in an energetic setting.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="eventBox">
                <img src="/assets/images/hsis/GalaDinner.jpg" alt="eventImg" />
                <div className="eventContentBox">
                  {/* <h6 className="eventDate">28<span>March</span></h6> */}
                  <h5 className="eventTitle">Gala Dinner</h5>
                  <p>
                    A networking event where participants engage with peers,
                    mentors, and sustainability leaders in an inspiring
                    atmosphere.
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>

          {/* <div className="row">
                        <div className="eventBox">
                            <img src="/assets/images/event1.png" />
                            <div className="eventContentBox">
                                <h6 className="eventDate">28<span>March</span></h6>
                                <h5 className="eventTitle">Model United Nations</h5>
                                <p>Delve into pressing global issues through meticulously curated committee sessions.</p>
                            </div>
                        </div>
                        <div className="eventBox">
                            <img src="/assets/images/event2.png" />
                            <div className="eventContentBox">
                                <h6 className="eventDate">28<span>March</span></h6>
                                <h5 className="eventTitle">Oxford Scholars Challenge</h5>
                                <p>Kickstart your MUN journey with unique challenges, enhancing team dynamics and critical
                                    thinking.</p>
                            </div>
                        </div>
                        <div className="eventBox">
                            <img src="/assets/images/event3.png" />
                            <div className="eventContentBox">
                                <h6 className="eventDate">28<span>March</span></h6>
                                <h5 className="eventTitle">Training Sessions</h5>
                                <p>Led by experts from Oxford, these sessions are designed to prime delegates for success.</p>
                            </div>
                        </div>
                    </div> */}
        </div>
      </section>

      <section className="venueSpotSec">
        <div className="container">
          <h2 className="secTitle hsis" data-aos="fade-up">
            Venue Spotlight
          </h2>
          <div className="venueSpot-inner" data-aos="fade-up">
            <ImageGallery
              items={images}
              autoPlay={true}
              showFullscreenButton={false}
              showPlayButton={false}
              infinite={true}
            />

            {/* <img src="/assets/images/venueSpotImg.png" /> */}
            <div className="venueSpotContent venueSpotContentHsis  ">
              <h2>
                <span className="textUpper">Flame</span> University, Pune
              </h2>
              <p>
                FLAME University, renowned for its interdisciplinary education
                and commitment to leadership development, provides the perfect
                setting for policy innovation and sustainability-driven
                learning.<br></br>
                Set amidst lush greenery, the university’s state-of-the-art
                facilities, collaborative spaces, and sustainability-conscious
                campus make HSIS 2025 a truly immersive and impactful
                experience.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section className=" partnerSec partnerHomeSec pt-0"> */}
      {/* <div className="container-full"> */}
      {/* <div className="topHeading"> */}
      {/* <h6 className="fs-22 fw-600 textOrg pb-10 textUpper">community</h6> */}
      {/* <h2 className="fs-80 fw-600 " data-aos="fade-up">
              Partners
            </h2> */}
      {/* </div> */}

      {/* <div className="oxfordmun_logoslider" data-aos="fade-up"> */}
      {/* <Swiper
              modules={[Navigation, Autoplay, A11y]}
              spaceBetween={20}
              slidesPerView={5}
              loop={true}
              autoplay={{
                delay: 1500, // Increased delay for better viewing experience
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              keyboard={{
                enabled: true,
                onlyInViewport: true,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                480: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 25,
                },
                1440: {
                  slidesPerView: 5,
                  spaceBetween: 30,
                },
              }}
            > */}
      {/* <SwiperSlide>
                <a
                  href="https://www.allenglobalstudies.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/images/mun_partner_logo/5.png"
                    alt="networkSch"
                  />
                </a>
              </SwiperSlide> */}
      {/* <SwiperSlide>
                <a
                  href="https://www.flame.edu.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/images/mun_partner_logo/3.png"
                    alt="networkSch"
                  />
                </a>
              </SwiperSlide> */}
      {/* <SwiperSlide>
                <a
                  href="https://india.afs.org/yes-program/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/images/mun_partner_logo/2.png"
                    alt="networkSch"
                  />
                </a>
              </SwiperSlide> */}
      {/* <SwiperSlide>
                <a
                  href="https://iayp.co.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/images/mun_partner_logo/6.png"
                    alt="networkSch"
                  />
                </a>
              </SwiperSlide> */}
      {/* <SwiperSlide>
                <a
                  href="https://bsai.co.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/images/mun_partner_logo/4.png"
                    alt="networkSch"
                  />
                </a>
              </SwiperSlide> */}
      {/* Repeat slides as needed */}
      {/* </Swiper> */}
      {/* </div> */}
      {/* </div> */}
      {/* </section> */}

      <section className="reviewSec">
        <div className="container-full" data-aos="fade-up">
          <Swiper
            // install Swiper modules
            modules={[Autoplay, Navigation, A11y, EffectFade]}
            spaceBetween={0}
            slidesPerView={1}
            loop={true}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            centeredSlides={true}
            effect="fade"
            navigation={false}
            //onSwiper={(swiper) => console.log(swiper)}
            //onSlideChange={() => console.log('slide change')}
          >
            <SwiperSlide>
              <div>
                <img
                  src="/assets/images/hsis/StudentsEngaged.png"
                  alt="home-img"
                />
                {/* <div className="reviewContent"> */}
                {/* <h3 className="reviewTitle" data-aos="fade-up">
                    8500+ Students Engaged
                  </h3>
                  <p className="fs-14 pt-10" data-aos="fade-up">
                    {" "}
                    Empowering over 8,500 students to become the leaders of
                    tomorrow.
                  </p> */}
                {/* <p>Students Engaged</p> */}
                {/* </div> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img
                  src="/assets/images/hsis/NationalitiesRepresented.png"
                  alt="home-img"
                />
                {/* <div className="reviewContent"> */}
                {/* <h3 className="reviewTitle" data-aos="fade-up">
                    40+ Nationalities Represented
                  </h3>
                  <p className="fs-14 pt-10" data-aos="fade-up">
                    {" "}
                    A melting pot of cultures with delegates from over 40
                    nationalities.
                  </p> */}
                {/* <p> Nationalities Represented</p> */}
                {/* </div> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img
                  src="/assets/images/hsis/ConferencesHeld.png"
                  alt="home-img"
                />
                {/* <div className="reviewContent"> */}
                {/* <h3 className="reviewTitle" data-aos="fade-up">
                    25 Conferences Held
                  </h3>
                  <p className="fs-14 pt-10" data-aos="fade-up">
                    Shaping global minds through 25 impactful conferences
                    worldwide.
                  </p> */}
                {/* <p> Conferences Held</p> */}
                {/* </div> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img
                  src="/assets/images/hsis/AcademicMentors.png"
                  alt="home-img"
                />
                {/* <div className="reviewContent"> */}
                {/* <h3 className="reviewTitle" data-aos="fade-up">
                    22+ Academic Mentors
                  </h3>
                  <p className="fs-14 pt-10" data-aos="fade-up">
                    Guided by over 22 experts leading in the realm of
                    international diplomacy.
                  </p> */}
                {/* <p> Academic Mentors</p> */}
                {/* </div> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img
                  src="/assets/images/hsis/OperatesCountries.png"
                  alt="home-img"
                />
                {/* <div className="reviewContent"> */}
                {/* <h3 className="reviewTitle" data-aos="fade-up">
                    Operates in 7 Countries
                  </h3>
                  <p className="fs-14 pt-10" data-aos="fade-up">
                    Bringing global perspectives across 7 diverse nations.
                  </p> */}
                {/* <p>Operates in 7 Countries</p> */}
                {/* </div> */}
              </div>
            </SwiperSlide>
          </Swiper>

          {/* <img src="/assets/images/reviewImg.png" alt='home-img' />
                    <div className="reviewContent">
                        <h3 className="reviewTitle">Last year Summit</h3>
                        <p>OxfordMUN India is more than a conference; it's a movement towards nurturing informed</p>
                    </div> */}
        </div>
      </section>

      <section className="joinSec joinSecHsis">
        <div className="container">
          <div className="row" data-aos="fade-up">
            <div className="column mun_logo logoImage ">
              <img src="/assets/images/hsisLogoWhite.png" alt="home-img" />
            </div>
            <div className="column content-wrapperCol">
              <div className="content-wrapper">
                <h5 className="contentTitle" data-aos="fade-up">
                  Join the movement{" "}
                </h5>
                <p data-aos="fade-up">
                  The Harvard Sustainability Impact Summit (HSIS) 2025 is more
                  than just a summit, it’s a call to action for the next
                  generation of sustainability leaders. By participating, you
                  become part of a movement dedicated to innovative
                  policy-making, global collaboration, and real-world impact.
                  <br />
                  Whether you’re competing, engaging with experts, or following
                  the journey, HSIS 2025 offers a platform to contribute to
                  meaningful change. Join us in shaping the future of
                  sustainability through bold ideas, strategic action, and
                  collaborative leadership.
                </p>
                <a
                  href="https://bit.ly/HSIS2025Brochure"
                  className="registerBtn"
                  target="_blank"
                >
                  {" "}
                  Download Brochure{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="callToAction ">
        <div className="container  " data-aos="fade-up">
          <div className="  callToAction-inner eventSecHsis">
            <div className="row">
              <div className="column callToActionLeft">
                <h5 data-aos="fade-up">
                  Make a Difference
                  <br /> with Us
                </h5>
                <p>
                  Register Your School for Harvard Sustainability Impact Summit
                  (HSIS) 2025 Today!
                </p>
              </div>
              <div className="column callToActionRight">
                <p data-aos="fade-up">
                  Are you ready to take on the challenge of sustainability and
                  policy innovation? Harvard Sustainability Impact Summit (HSIS)
                  2025 invites schools and students from across the country to
                  be part of this transformative experience. This is an
                  opportunity to engage in high-level discussions, develop
                  real-world solutions, and collaborate with experts from
                  Harvard and sustainability leaders.
                  <br /> Join us in shaping the future, one policy, one idea,
                  and one action at a time. Secure your spot at HSIS 2025 and be
                  part of a movement that goes beyond conversations to create
                  real impact.
                </p>
                <a href="https://forms.gle/QVCBgyqZLYPhmyQ26" target="_blank">
                  <button
                    className="org_btn  registerBtn joinbtn"
                    // onClick={togglePopup}
                  >
                    Register Now
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="faqSec">
        <div className="container row faqInner" data-aos="fade-up">
          <div className="faqInnerLeft faqInnerLeftHsis">
            <div className="faqInnerLeft-inner">
              <img src="/assets/images/hsisLogoWhite.png" alt="home-img" />
              <p className="fs-18">
                Make a Difference with Us: Register your school for Harvard
                Sustainability Impact Summit (HSIS) 2025 Today!
              </p>
              <p className="fs-18">
                Are you ready to take on the challenge of sustainability and
                policy innovation? Harvard Sustainability Impact Summit (HSIS)
                2025 invites schools and students from across the country to be
                part of this transformative experience. This is an opportunity
                to engage in high-level discussions, develop real-world
                solutions, and collaborate with experts from Harvard and
                sustainability leaders.
                <br /> Join us in shaping the future, one policy, one idea, and
                one action at a time. Secure your spot at HSIS 2025 and be part
                of a movement that goes beyond conversations to create real
                impact.
              </p>

              {/* <a onClick={togglePopup} className="registerBtn " target="_blank">
                {" "}
                Enquire Now{" "}
              </a> */}
              <a href="https://forms.gle/QVCBgyqZLYPhmyQ26" target="_blank">
                <button
                  className="registerBtn"
                  // onClick={togglePopup}
                >
                  Register Now
                </button>
              </a>
              <p className="fs-18 p-0">Still Have a question? No worries.</p>

              <div className="contactDetail">
                <a href="mailto:madhur.oza@mu20.co ">
                  <img src="/assets/images/email.svg" alt="home-img" />
                  madhur.oza@mu20.co{" "}
                </a>
                <a href="tel:7987519367">
                  <img src="/assets/images/whatsapp.svg" alt="home-img" />
                  7987519367
                </a>
              </div>
              <div className="socialLinks">
                <a
                  href=" https://www.instagram.com/mu20.world?igsh=MThkY3NkeGJ3bHNtaA=="
                  target="_blank"
                >
                  <img src="/assets/images/insta.svg" alt="home-img" />
                </a>
                <a
                  href="https://www.linkedin.com/company/mu20/"
                  target="_blank"
                >
                  <img src="/assets/images/linkdine.svg" alt="home-img" />
                </a>
              </div>
            </div>
          </div>
          <div className="faqInnerRight">
            <h2 className="secTitle hsis">FAQs</h2>
            <div className="faqBlock">
              <Accordion allowZeroExpanded>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h3 className="faqTitle">
                        <img src="/assets/images/icon.svg" alt="faq-icon" />
                        What is the venue for HSIS 2025?
                      </h3>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="faqContent">FLAME University, Pune, India.</p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h3 className="faqTitle">
                        <img src="/assets/images/icon.svg" alt="faq-icon" />
                        What are the dates for HSIS 2025?
                      </h3>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>August 4-6, 2025.</p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h3 className="faqTitle">
                        <img src="/assets/images/icon.svg" alt="faq-icon" />
                        How can I register for HSIS 2025?
                      </h3>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Schools are required to register by 21st April 2025 (Phase
                      1) and final registration deadlines will be 20th July 2025
                      (phase 2).
                      <br />
                      Visit{" "}
                      <a href="https://forms.gle/QVCBgyqZLYPhmyQ26">Link</a> to
                      secure your spot and embark on an unforgettable journey.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h3 className="faqTitle">
                        <img src="/assets/images/icon.svg" alt="faq-icon" />
                        What is the participation fee for HSIS 2025?
                      </h3>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      - School Registration Fee: INR 5,000 (Includes one teacher
                      representative without any additional charge)
                    </p>
                    <p>
                      {" "}
                      - Delegate Fee for MU20 School of Opportunity, BSAI and
                      AFS Member Schools: [Phase 1] INR 30,000 (incl. of GST)
                      [Phase 2] INR 35,000 (incl. of GST)
                    </p>
                    <p>
                      {" "}
                      - Delegate Fee for Non-Member Schools: [Phase 1] INR
                      35,000 (incl. of GST) [Phase 2] INR 40,000 (incl. of GST)
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h3 className="faqTitle">
                        <img src="/assets/images/icon.svg" alt="faq-icon" />
                        Who can participate in HSIS 2025?
                      </h3>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>High school students (Grades 9th -12th).</p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h3 className="faqTitle">
                        <img src="/assets/images/icon.svg" alt="faq-icon" />
                        Will accommodation be provided to the participants?
                      </h3>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Yes, the participants and their teacher coordinators would
                      be provided with twin-sharing rooms at FLAME University,
                      Pune.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h3 className="faqTitle">
                        <img src="/assets/images/icon.svg" alt="faq-icon" />
                        How can I get to the venue from the airport/train
                        station?
                      </h3>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Convenient pickup and drop-off services from local station
                      and airport would be provided to participants.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h3 className="faqTitle">
                        <img src="/assets/images/icon.svg" alt="faq-icon" />
                        Who can I contact for more information or assistance?
                      </h3>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Akshat Moghe{" "}
                      <a href="tel:+91-9522350555"> +91-9522350555 </a>
                      &nbsp;&nbsp;
                      <a href="mailTo:connect@mu20.co">connect@mu20.co</a>{" "}
                    </p>
                    <p>
                      Madhur Oza{" "}
                      <a href="tel:+91-79875 19367"> +91-79875 19367 </a>
                      &nbsp;&nbsp;
                      <a href="mailTo:madhur.oza@mu20.co">madhur.oza@mu20.co</a>
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      {/* <Button type="primary" onClick={showModal}>
                 Open Modal
                </Button> */}
      {/* <RegisterModal /> */}
      {/* <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <p>Some contents...</p>
                <p>Some contents...</p>
                <p>Some contents...</p>
            </Modal> */}
      <Footer />
    </>
  );
}

export default Hsis;
